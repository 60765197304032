import React, { useState } from 'react';
import { WordList } from '../common/WordList';
import MissedWord from 'models/MissedWord';
import MissedWordDisplay from './MissedWordDisplay';
import Modal from '../common/Modal';

interface MissedWordListProps {
  missedWords: MissedWord[];
}

export const MissedWordList: React.FC<MissedWordListProps> = ({
  missedWords,
}) => {
  const [viewWord, setViewWord] = useState<string | null>(null);

  return (
    <>
      <WordList
        type="interactive"
        words={Array.from(
          new Set(missedWords.filter((w) => !w.known).map((w) => w.word)),
        )}
        onClickWord={(word) => setViewWord(word)}
      />
      {viewWord && (
        <Modal
          isOpen={viewWord ? true : false}
          onClose={() => setViewWord(null)}
        >
          <MissedWordDisplay
            word={viewWord}
            index={0}
            showPrompt={false}
            onExit={() => setViewWord(null)}
            allowPractice={true}
          />
        </Modal>
      )}
    </>
  );
};
