import React, { ReactNode, useState } from 'react';
import { useFormContext } from 'contexts/FormContext';

import 'view/style/common/components/horizontalCarousel.css';

interface HorizontalCarouselProps {
  pages: ReactNode[];
  pageSubmitCallbacks?: (((formData: any) => Promise<any>) | undefined)[];
  onSubmit?: (formData: any) => void;
}

const HorizontalCarousel: React.FC<HorizontalCarouselProps> = ({
  pages,
  pageSubmitCallbacks,
  onSubmit = () => {},
}) => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { formData } = useFormContext();

  const nextPage = () => {
    const callback = pageSubmitCallbacks?.[currentPage];

    if (callback) {
      callback(formData)
        .then(() => {
          // Only move to the next page if the callback is successful
          if (currentPage < pages.length - 1) {
            setCurrentPage(currentPage + 1);
          } else {
            onSubmit(formData);
          }
        })
        .catch((error) => {
          console.error('Error in page callback:', error);
          setErrorMessage(error.message);
          // Do not change the page
        });
    } else {
      // If no callback, just move to the next page
      if (currentPage < pages.length - 1) {
        setCurrentPage(currentPage + 1);
      } else {
        setCurrentPage(0);
        onSubmit(formData);
      }
    }
  };
  const prevPage = () => {
    setCurrentPage(currentPage === 0 ? pages.length - 1 : currentPage - 1);
  };

  return (
    <div className="horizontal-carousel">
      <div className="main-page" aria-live="polite" aria-atomic="true">
        {pages[currentPage]}
      </div>
      <div
        className="prev-page"
        onClick={prevPage}
        aria-label="Previous Page"
        role="button"
        tabIndex={0}
      >
        {currentPage === 0 ? pages[pages.length - 1] : pages[currentPage - 1]}
      </div>
      <div
        className="next-page"
        onClick={nextPage}
        aria-label="Next Page"
        role="button"
        tabIndex={0}
      >
        {currentPage === pages.length - 1 ? pages[0] : pages[currentPage + 1]}
      </div>

      {errorMessage && (
        <span className="label-normal danger">{errorMessage}</span>
      )}
    </div>
  );
};

export default HorizontalCarousel;
