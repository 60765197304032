import { useAlert } from 'contexts/AlertContext';
import React, { ReactNode } from 'react';
import FinePrint from './FinePrint';

type FormProps = {
  label?: string;
  style?: React.CSSProperties;
  onSubmit?: (event: any) => void;
  children: ReactNode;
};

const Form: React.FC<FormProps> = ({
  label,
  style = {},
  onSubmit,
  children,
}) => {
  const { alert } = useAlert();

  return (
    <div className="form" style={style}>
      {label && <label className="label-large">{label}</label>}
      <form className="form-contents" onSubmit={onSubmit}>
        {children}
        {alert && alert.silent && (
          <FinePrint text={alert.message} type={alert.type} />
        )}
      </form>
    </div>
  );
};

export default Form;
