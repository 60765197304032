import React from "react";
import { ButtonType } from "utils/types";

import 'view/style/common/buttons.css';

interface SidebarButtonProps {
  id?: string
  type?: ButtonType;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  text?: string;
  label: string;
  icon?: string;
}

const SidebarButton: React.FC<SidebarButtonProps> = ({ id, type, onClick, text, label, icon }) => {
  return (
    <button id={id} className={`sidebar-button ${type || ''}`} onClick={onClick}>
      { icon && <img src={icon} alt='' /> }
      <span className='label-medium'>{ label }</span>
    </button>
  );
}

export default SidebarButton;