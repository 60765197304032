import MissedWord from '../models/MissedWord';

export type ServerMissedWords = Record<string, Record<string, MissedWord[]>>;

export interface Subscription {
  id: string;
  code: string;
  name: string;
  duration: any;
  type?: string;
  price: number;
  max_users?: number;
  num_users: number;
  start_date: any;
}

export interface SignupFormData {
  account_type: string;
  username: string;
  password: string;
  first_name: string;
  last_name: string;
  email: string;
  subscription?: Subscription;
  organization?: string;
}

export interface SubscriptionFormData {
  subscription?: Subscription;
  organization?: string;
}

export type ButtonType =
  | 'edit'
  | 'delete'
  | 'go'
  | 'default'
  | 'transparent'
  | 'option';

export class TeacherGeneratedStudent {
  username: string = '';
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  readingLevel: number = 1;
  password: string = '';
}

export type DisplayMissedWord = {
  word: string;
  missed: number;
  unknown: number;
  isKeyWord: boolean;
};

export enum SpeechMode {
  Easy = 1,
  Close = 2,
  Strict = 3,
}

export type Language = {
  name: string;
  code: string;
};
