import jwtDecode from 'jwt-decode';

export function getTokenExpiration(token) {
  const decodedToken = jwtDecode(token);
  if (!decodedToken.exp) {
    throw new Error("Token does not have an 'exp' claim");
  }

  // Convert the expiration time from seconds to milliseconds
  const expirationTime = decodedToken.exp * 1000;
  const currentTime = Date.now();

  // Calculate how much time is left until expiration
  const timeLeft = expirationTime - currentTime;

  // Optionally, return the time in minutes, seconds, etc.
  return timeLeft;
}
