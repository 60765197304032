import React from 'react';

import 'view/style/common/buttons.css';

type IconButtonType = 'transparent';

const icons: Record<string, string> = {
  settings: require('assets/icons/settings.svg').default,
  delete: require('assets/icons/delete_forever.svg').default,
  add: require('assets/icons/person_add_green.svg').default,
  confirm: require('assets/icons/check-solid.svg').default,
  cancel: require('assets/icons/xmark-solid.svg').default,
  reverse: require('assets/icons/rotate.svg').default,
  hide: require('assets/icons/eye-slash-solid-white.svg').default,
};

interface IconButtonProps {
  icon: string;
  type?: IconButtonType;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const IconButton: React.FC<IconButtonProps> = ({ icon, type, onClick }) => {
  const svgIcon = icons[icon] ?? icon;
  return (
    <button className={`icon-button ${type || ''}`} onClick={onClick}>
      <img src={svgIcon} alt="" />
    </button>
  );
};

export default IconButton;
