import Assignment from './Assignment';
import AssignmentSubmission from './AssignmentSubmission';

export default class StudentGradebookEntry {
  public assignment: Assignment;
  public assignment_submission: AssignmentSubmission;

  constructor(
    assignment: Assignment,
    assignment_submission: AssignmentSubmission,
  ) {
    this.assignment = assignment;
    this.assignment_submission = assignment_submission;
  }

  static fromServerEntry(
    serverEntry: Record<string, any>,
  ): StudentGradebookEntry | null {
    try {
      const assignment = Assignment.fromServerAssignment(
        serverEntry.assignment,
      );
      const assignment_submission =
        AssignmentSubmission.fromServerAssignmentSubmission(
          serverEntry.assignment_submission,
        );

      return new StudentGradebookEntry(assignment, assignment_submission);
    } catch (error) {
      console.error(error);
      console.error(
        `Failed to load server entry: ${JSON.stringify(serverEntry)}`,
      );
      return null;
    }
  }
}
