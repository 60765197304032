import React, { ReactNode } from 'react';

import 'view/style/common/Modal.css';

interface ModalProps {
  isOpen: boolean;
  strictExit?: boolean; // if true, clicking outside of the modal will not exit out, only the x button will
  onClose?: () => void;
  children: ReactNode;
  style?: any;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  strictExit = false,
  onClose,
  children,
  style,
}) => {
  return (
    <>
      {isOpen && (
        <div
          className="modal-overlay"
          onClick={strictExit ? undefined : onClose}
        >
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
            style={style}
          >
            {onClose && (
              <button className="x-modal-button" onClick={onClose}>
                X
              </button>
            )}
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
