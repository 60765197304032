import { useCallback } from 'react';
import { useLoading } from 'contexts/LoadingContext';

const useApiCall = () => {
  const { setIsLoading } = useLoading();

  const makeApiCall = useCallback(async (apiFunction: Function, ...args: any[]) => {
    setIsLoading(true);
    try {
      const result = await apiFunction(...args);
      setIsLoading(false);
      return result;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  }, [setIsLoading]);

  return makeApiCall;
};

export default useApiCall;
