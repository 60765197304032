import React, { useEffect } from 'react';
import { useFormValidation } from 'contexts/FormValidationContext';
import { useFormContext } from 'contexts/FormContext';
import { SignupFormData } from 'utils/types';

import 'view/style/auth/signup/accountTypeForm.css';
import contentService from 'services/contentService';

const wormy_apple = require('assets/images/logos/wormy-apple.png');
const wormy_book = require('assets/images/logos/wormy-book.png');

interface AccountTypeFormProps {
  setAccountType: (accountType: string) => void;
}

const AccountTypeForm: React.FC<AccountTypeFormProps> = ({ setAccountType }) => {
  const { formData, setFormData } = useFormContext<SignupFormData>();
  const { setPageValidity } = useFormValidation();
  const formContent = contentService.getSignupPage().account_type_form;

  useEffect(() => {
    setPageValidity(formData.account_type !== '');
  }, [formData.account_type, setPageValidity]);

  const handleAccountTypeChange = (type: string) => {
    setFormData({ ...formData, account_type: type });
    setAccountType(type);
  };

  return (
    <div className='login-content'>
      <h3>{ formContent.title }</h3>
      <div 
        className={`account-type-select ${formData.account_type === 'Teacher' ? 'selected' : ''}`}
        onClick={() => handleAccountTypeChange('Teacher')}
      >
        <img src={wormy_apple} alt=''/>
        <h3 className='label-hint'>{ formContent.options[0] }</h3>
      </div>
      <div 
        className={`account-type-select ${formData.account_type === 'Student' ? 'selected' : ''}`}
        onClick={() => handleAccountTypeChange('Student')}
      >
        <img src={wormy_book} alt=''/>
        <h3 className='label-hint'>{ formContent.options[1] }</h3>
      </div>
    </div>
  );
};

export default AccountTypeForm;