import React from 'react';

import 'view/style/common/components/loading.css'; 

// eslint-disable-next-line
const wormy = require('assets/images/logos/wormy-1.png');

export const LoadingIndicator: React.FC = () => {
  return (
    <div className='loading-indicator'>
      {/* <svg>
        <g>
          <image 
            xlinkHref={wormy} 
            y='18%'
            style={{
              "animation": "bounceAndSquish 7s linear infinite"
            }}
          />
        </g>
      </svg> */}
      <div className='spinner'/>
    </div>
  );
};