import React, { useState } from "react";
import ActionButton from "view/components/buttons/ActionButton";
import PasswordInput from "view/components/common/PasswordInput";
import userAuthService from "services/userAuthService";
import { useUserContext } from "contexts/UserContext";
import useApiCall from "contexts/ApiCall";
import contentService from "services/contentService";


interface PasswordChangeFormProps {
  onExit: () => void;
}
  
export const PasswordChangeForm: React.FC<PasswordChangeFormProps> = ({ onExit }) => {
  const {user} = useUserContext();
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<any>();
  const errorMessages = contentService.getAuthConstants().error_messages;
  const makeApiCall = useApiCall();
  
  const handleChangePassword = () => {
    if (!user) return;
    if (oldPassword === newPassword) {
      setErrorMessage(errorMessages.password_unchanged);
    } else if (newPassword.length < 8) {
      setErrorMessage(errorMessages.password_validation);
    } else if (newPassword !== confirmPassword) {
      setErrorMessage(errorMessages.password_confirmation);
    } else {
      setErrorMessage(undefined);
      makeApiCall(userAuthService.changePassword, user.getId(), oldPassword, newPassword)
        .then(() => onExit())
        .catch(err => setErrorMessage(err.message));
    }
  }
  
  return (
    <div className='form'>
      <label className='label-large'>Change Password</label>
      <div className='form-contents'>
        <PasswordInput 
          id='old-password'
          label='Old password'
          password={oldPassword}
          setPassword={setOldPassword} 
          verify={false}
        />
        <PasswordInput 
          id='new-password'
          label='New password'
          password={newPassword}
          valid={
            newPassword.length >= 8 
            && newPassword !== oldPassword
          }
          setPassword={setNewPassword} 
        />
        <PasswordInput 
          id='confirm-password'
          label='Confirm new password'
          password={confirmPassword}
          valid={
            newPassword === confirmPassword
            && confirmPassword !== ''
          }
          setPassword={setConfirmPassword} 
        />
        { errorMessage && 
          <span className='label-normal danger'>
            { errorMessage }
          </span> 
        }
      </div>
      <ActionButton 
        type='go' 
        onClick={handleChangePassword}
        label='Confirm'
      />
    </div>
  );
}