import React, { useEffect, useState } from 'react';
import Button from 'view/components/buttons/Button';
import { UserInput } from 'view/components/common/UserInput';
import Classroom from 'models/Classroom';
import { useUserContext } from 'contexts/UserContext';
import googleApiService from 'services/googleAPIService';
import { nanoid } from 'nanoid';
import teacherService from 'services/teacherService';
import useApiCall from 'contexts/ApiCall';
import contentService from 'services/contentService';

const arrow_forward: string =
  require('assets/icons/arrow_forward_black.svg').default;

interface CreateClassroomFormProps {
  onCreateClassroom: (classroom: Classroom) => void;
}

export const CreateClassroomForm: React.FC<CreateClassroomFormProps> = ({
  onCreateClassroom,
}) => {
  const { user } = useUserContext();
  const [classroom, setClassroom] = useState<Classroom>();
  const [googleClassrooms, setGoogleClassrooms] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const formContent = contentService.getTeacherForms().create_classroom_form;
  const constants = contentService.getTeacherConstants();
  const makeApiCall = useApiCall();

  useEffect(() => {
    resetClassroom();
    if (!user?.google_access_token) return;
    makeApiCall(googleApiService.getGoogleClassrooms, user)
      .then((respClassrooms) => setGoogleClassrooms(respClassrooms))
      .catch((error) => setErrorMessage(error.message));
    // eslint-disable-next-line
  }, [user, makeApiCall]);

  const handleCreateClassroom = () => {
    if (!user || !classroom) return;
    setErrorMessage('');
    makeApiCall(teacherService.createClassroom, classroom)
      .then((respClassroom) => onCreateClassroom(respClassroom))
      .catch((error) => setErrorMessage(error.message));
  };

  const resetClassroom = () => {
    if (!user) return;
    setClassroom(
      new Classroom('', '', user.getId(), 1, 1, nanoid().slice(0, 8), 'en-US'),
    );
  };

  const updateClassroom = (event: any) => {
    var value = event.target.value;
    if (event.target.id === 'language') {
      const langCode = constants.languages.find(
        (l: any) => l.name === value,
      )?.code;
      if (langCode) {
        value = langCode;
      }
    }
    if (!classroom) return;
    setClassroom((prevClassroom: any) => {
      return Classroom.fromServerClassroom({
        ...prevClassroom,
        [event.target.id]: value,
      });
    });
  };

  const handleGoogleClassroomImport = (event: any) => {
    const googleClassroom = googleClassrooms.find(
      (gc) => gc.name === event.target.value,
    );
    if (!googleClassroom) {
      resetClassroom();
      return;
    }
    setClassroom((prevClassroom) => {
      return Classroom.fromServerClassroom({
        ...prevClassroom,
        name: googleClassroom.name,
        google_id: googleClassroom.id,
      });
    });
  };

  return (
    <form className="form" onSubmit={handleCreateClassroom}>
      <h2>{formContent.title}</h2>
      <div className="form-contents">
        {user?.google_access_token && (
          <UserInput
            id="google-classroom"
            label="Import Google Classroom"
            type="select"
            options={googleClassrooms}
            optionsField="name"
            defaultOption="None"
            onChange={handleGoogleClassroomImport}
          />
        )}
        <UserInput
          id="name"
          label="Classroom Name"
          type="text"
          value={classroom?.name}
          onChange={updateClassroom}
          minLength={1}
        />
        <UserInput
          id="access_code"
          label="Access Code"
          hint="Maximum of 8 characters"
          type="text"
          value={classroom?.access_code}
          onChange={updateClassroom}
          maxLength={8}
          minLength={8}
        />
        <UserInput
          id="reading_level"
          label="Reading Groups"
          hint="Positive integer"
          type="from-to"
          rangeValue={
            classroom && {
              min: classroom?.min_reading_level,
              max: classroom?.max_reading_level,
            }
          }
          onChange={updateClassroom}
        />
        <UserInput
          id="language"
          label="English Dialect"
          type="select"
          options={constants.languages}
          optionsField="name"
          value={
            constants.languages.find((l: any) => l.code === classroom?.language)
              ?.name
          }
          onChange={updateClassroom}
        />
      </div>
      {errorMessage && (
        <span className="label-normal danger">{errorMessage}</span>
      )}
      <Button
        type="go"
        buttonType="submit"
        label={formContent.content.confirm_button.label}
        trailIcon={arrow_forward}
      />
    </form>
  );
};
