import React from 'react';
import ActionButton from '../buttons/ActionButton';

import 'view/style/common/components/alert.css';

const logo = require('assets/images/logos/wormy-2.png');

type AlertProps = {
  type?: string;
  message: string;
};

const Alert: React.FC<AlertProps> = ({ type, message }) => {
  return (
    <div className="alert">
      {type === 'error' && <ErrorAlert message={message} />}
      {type === 'success' && <SuccessAlert message={message} />}
    </div>
  );
};

const ErrorAlert: React.FC<AlertProps> = ({ message }) => {
  return (
    <div className="alert">
      <img src={logo} alt="orator" />
      {message}
      <div className="row" style={{ alignContent: 'space-between' }}>
        <ActionButton type="edit" label="Retry" />
        <ActionButton type="default" label="Report" />
      </div>
    </div>
  );
};

const SuccessAlert: React.FC<AlertProps> = ({ message }) => {
  return (
    <div className="alert">
      <img src={logo} alt="orator" />
      {message}
    </div>
  );
};

export default Alert;
