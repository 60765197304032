import React, { useEffect, useState } from 'react';
import { useClassroomContext } from 'contexts/ClassroomContext';
import { useNavigate } from 'react-router-dom';
import TeacherGradebook from 'models/TeacherGradebook';
import teacherService from 'services/teacherService';
import { SearchBar } from 'view/components/common/SearchBar';
import { Filter, FilterOption } from 'view/components/common/Filter';
import Modal from 'view/components/common/Modal';
import { StudentDetails } from 'view/components/teacher/classroom/StudentDetails';
import TeacherGradebookEntry from 'models/TeacherGradebookEntry';
import AssignmentSubmission from 'models/AssignmentSubmission';
import { AppPage } from 'view/components/common/AppPage';
import IconButton from 'view/components/buttons/IconButton';
import { ClassroomSettings } from 'view/components/teacher/classroom/ClassroomSettings';
import StudentProfile from 'models/StudentProfile';
import SidebarButton from 'view/components/buttons/SidebarButton';
import { DeleteConfirmation } from 'view/components/common/DeleteConfirmation';
import useApiCall from 'contexts/ApiCall';
import AppSidebar from 'view/components/common/Sidebar';
import contentService from 'services/contentService';
import AddStudentForm from 'view/components/teacher/classroom/AddStudentForm';
import AssignmentTypeForm from 'view/components/teacher/assignment/AssignmentTypeForm';
import ItemPanel from 'view/components/common/ItemPanel';
import { useUserContext } from 'contexts/UserContext';
import { useGradebook } from 'contexts/TeacherGradebookContext';

const arrow_forward: string = require('assets/icons/arrow-forward.svg').default;
const add_circle_outline: string =
  require('assets/icons/add_circle_white.svg').default;
const auto_graph: string = require('assets/icons/auto_graph.svg').default;
const manage_search: string = require('assets/icons/manage_search.svg').default;
const person_add: string = require('assets/icons/person_add_alt_1.svg').default;

export const TeacherClassPage: React.FC = () => {
  const { user } = useUserContext();
  const { classroom, setClassroom } = useClassroomContext();
  const { gradebook, setGradebook } = useGradebook();
  const [displayStudents, setDisplayStudents] =
    useState<TeacherGradebookEntry[]>();
  const [selectedStudent, setSelectedStudent] =
    useState<TeacherGradebookEntry>();
  const [averagesDisplay, setAveragesDisplay] = useState<FilterOption[]>(
    contentService.getTeacherConstants().display_averages,
  );
  const [averageDisplay, setAverageDisplay] = useState<FilterOption>(
    contentService.getTeacherConstants().display_averages[0],
  );
  const [viewStudentDetails, setViewStudentDetails] = useState<boolean>(false);
  const [viewSettings, setViewSettings] = useState<boolean>(false);
  const [showDeleteStudentConfirmation, setShowDeleteStudentConfirmation] =
    useState<boolean>(false);
  const [viewAddStudent, setViewAddStudent] = useState<boolean>(false);
  const [viewAddAssignment, setViewAddAssignment] = useState<boolean>(false);
  const pageContent = contentService.getTeacherClassroomPage();
  const makeApiCall = useApiCall();
  const navigate = useNavigate();

  useEffect(() => {
    if (!classroom) return;
    makeApiCall(teacherService.getGradebook, classroom.getId())
      .then((respGradebook) => setGradebook(respGradebook))
      .catch((error) => alert(error.message));
  }, [classroom, user, setGradebook, makeApiCall]);

  useEffect(() => {
    if (!gradebook) return;
    setDisplayStudents(gradebook.students);
    if (selectedStudent) {
      setSelectedStudent(
        gradebook.students.find((s) => s.username === selectedStudent.username),
      );
    }
    // eslint-disable-next-line
  }, [gradebook]);

  const handleExitClassroom = () => {
    if (selectedStudent) {
      setSelectedStudent(undefined);
      return;
    }
    setClassroom(null);
    navigate('/teacher/home/');
  };

  const handleCreateAssignment = () => {
    setViewAddAssignment(true);
  };

  const handleViewAnalytics = () => {
    navigate('insights/');
  };

  const handleViewAssignments = () => {
    navigate('assignments/');
  };

  const handleAverageDisplayChange = (
    checked: boolean,
    displayOption: FilterOption,
  ) => {
    setAveragesDisplay((prev) => {
      const updatedDisplayOptions = prev.map((option) =>
        option === displayOption
          ? { ...option, is_active: checked }
          : { ...option, is_active: false },
      );
      return updatedDisplayOptions;
    });
    if (checked) setAverageDisplay(displayOption);
  };

  const handleUpdateAssignmentSubmission = (
    assignmentSubmission: AssignmentSubmission,
    field: string,
    value: any,
  ) => {
    const updatedAssignmentSubmission =
      AssignmentSubmission.fromServerAssignmentSubmission({
        ...assignmentSubmission,
        [field]: value,
      });
    makeApiCall(
      teacherService.updateAssignmentSubmission,
      updatedAssignmentSubmission,
    )
      .then((respAssignment) => {
        setGradebook((prevGradebook) =>
          prevGradebook?.updateAssignmentSubmission(respAssignment),
        );
      })
      .catch((error) => alert(error.message));
  };

  const handleUpdateStudent = (
    studentProfile: StudentProfile,
    readingLevel?: number,
  ) => {
    makeApiCall(
      teacherService.updateStudentProfile,
      StudentProfile.fromServerStudentProfile({
        ...studentProfile,
        reading_level: readingLevel,
      }),
    ).catch((error) => alert(error.message));
  };

  const filterStudents = (searchTerm: string) => {
    if (!gradebook) return;
    setDisplayStudents(
      gradebook.students.filter((a) =>
        a.displayName().toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    );
  };

  const handleDeleteStudentClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    event.preventDefault();

    setShowDeleteStudentConfirmation(true);
  };

  const handleDeleteStudent = () => {
    if (!selectedStudent) return;
    makeApiCall(
      teacherService.deleteStudent,
      selectedStudent.student_profile.getId(),
    )
      .then((resp) => {
        setGradebook((prevGradebook) =>
          TeacherGradebook.fromServerGradebook({
            ...prevGradebook,
            students: prevGradebook?.students.filter(
              (s) => s.username !== selectedStudent.username,
            ),
          }),
        );
        setShowDeleteStudentConfirmation(false);
        setSelectedStudent(undefined);
      })
      .catch((error) => alert(error.message));
  };

  const handleAddStudent = () => {
    setViewAddStudent(true);
  };

  const onStudentAdded = () => {
    if (!classroom) return;
    makeApiCall(teacherService.getGradebook, classroom.getId())
      .then((resp) => setGradebook(TeacherGradebook.fromServerGradebook(resp)))
      .catch((err) => console.error(err));
  };

  return (
    <AppPage>
      <div className="app-page-content">
        <AppSidebar
          onExit={handleExitClassroom}
          onViewSettings={() => setViewSettings(true)}
        >
          <>
            <SearchBar onSearch={filterStudents} />
            {selectedStudent ? (
              <>
                <div className="assignment-detail-header">
                  <label className="label-medium">
                    {selectedStudent.displayName()}
                  </label>
                </div>
                <div className="assignment-details-list">
                  <div className="assignment-detail">
                    <label className="label-small">
                      {pageContent.sidebar.selected.details[0].label}
                    </label>
                    <label className="label-small">
                      {selectedStudent.student_profile.reading_level}
                    </label>
                  </div>
                  <div className="assignment-detail">
                    <label className="label-small">
                      {pageContent.sidebar.selected.details[1].label}
                    </label>
                    <label className="label-small">
                      {selectedStudent.averages.completion_score}%
                    </label>
                  </div>
                  <div className="assignment-detail">
                    <label className="label-small">
                      {pageContent.sidebar.selected.details[2].label}
                    </label>
                    <label className="label-small">
                      {selectedStudent.averages.grade}%
                    </label>
                  </div>
                  <div className="assignment-detail">
                    <label className="label-small">
                      {pageContent.sidebar.selected.details[3].label}
                    </label>
                    <label className="label-small">
                      {selectedStudent.averages.correctness_score}%
                    </label>
                  </div>
                  <div className="assignment-detail">
                    <label className="label-small">
                      {pageContent.sidebar.selected.details[4].label}
                    </label>
                    <label className="label-small">
                      {selectedStudent.averages.key_word_accuracy_score}%
                    </label>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Filter
                  type="checkbox"
                  label="Averages"
                  options={averagesDisplay}
                  onOptionChange={handleAverageDisplayChange}
                />
                <SidebarButton
                  type="default"
                  onClick={handleAddStudent}
                  icon={person_add}
                  label={pageContent.sidebar.nav[0].label}
                />
                <SidebarButton
                  type="default"
                  onClick={handleViewAnalytics}
                  icon={auto_graph}
                  label={pageContent.sidebar.nav[1].label}
                />
                <SidebarButton
                  type="default"
                  onClick={handleViewAssignments}
                  icon={manage_search}
                  label={pageContent.sidebar.nav[2].label}
                />
                <SidebarButton
                  type="go"
                  onClick={handleCreateAssignment}
                  icon={add_circle_outline}
                  label={pageContent.sidebar.nav[3].label}
                />
              </>
            )}
          </>
        </AppSidebar>
        <div className="app-main-content">
          <h1>{classroom?.name}</h1>
          <div className="item-list">
            {displayStudents &&
              displayStudents.map((student) => (
                <ItemPanel
                  key={`student-${student.username}`}
                  id={`student-${student.username}`}
                  selected={selectedStudent === student}
                  onDoubleClick={() => setViewStudentDetails(true)}
                  onClick={() => setSelectedStudent(student)}
                >
                  <div
                    key={`student-info-${student.username}`}
                    className="student-row-info"
                  >
                    {selectedStudent === student && (
                      <IconButton
                        icon="delete"
                        type="transparent"
                        onClick={handleDeleteStudentClick}
                      />
                    )}
                    <label className="label-normal">
                      {student.displayName()}
                    </label>
                    <label className="label-small">({student.email})</label>
                  </div>
                  {selectedStudent === student ? (
                    <>
                      <label className="label-medium">
                        {pageContent.content.students.selected.text}
                      </label>
                      <img src={arrow_forward} alt="" />
                    </>
                  ) : (
                    averageDisplay &&
                    averageDisplay.alias && (
                      <span className="label-small">
                        {averageDisplay?.value}{' '}
                        {pageContent.content.students.unselected.text}:{' '}
                        {student.averages[averageDisplay?.alias]}%
                      </span>
                    )
                  )}
                </ItemPanel>
              ))}
          </div>
        </div>
        <Modal
          isOpen={viewStudentDetails}
          onClose={() => setViewStudentDetails(false)}
        >
          {selectedStudent && (
            <StudentDetails
              studentInfo={selectedStudent}
              assignments={gradebook!.assignments}
              onUpdateAssignmentSubmission={handleUpdateAssignmentSubmission}
              onUpdateStudent={handleUpdateStudent}
            />
          )}
        </Modal>
        <Modal isOpen={viewSettings} onClose={() => setViewSettings(false)}>
          {classroom && (
            <ClassroomSettings
              classroom={classroom}
              onExit={() => setViewSettings(false)}
            />
          )}
        </Modal>
        <Modal
          isOpen={showDeleteStudentConfirmation}
          onClose={() => setShowDeleteStudentConfirmation(false)}
        >
          <DeleteConfirmation
            warning={pageContent.warnings.delete_student}
            onDelete={handleDeleteStudent}
            onExit={() => setShowDeleteStudentConfirmation(false)}
          />
        </Modal>
        <Modal
          isOpen={viewAddStudent}
          onClose={() => setViewAddStudent(false)}
          style={{ minWidth: '1000px', minHeight: '50vh' }}
          strictExit={true}
        >
          <AddStudentForm
            ignoreStudents={
              gradebook?.students.reduce(
                (acc, s) => acc.add(s.username),
                new Set<string>(),
              ) ?? new Set()
            }
            onStudentAdded={onStudentAdded}
          />
        </Modal>
        <Modal
          isOpen={viewAddAssignment}
          onClose={() => setViewAddAssignment(false)}
        >
          <AssignmentTypeForm />
        </Modal>
      </div>
    </AppPage>
  );
};
