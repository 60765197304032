import React, { createContext, useState, useContext, ReactNode } from 'react';

type SidebarStateContextType = {
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
};

const SidebarStateContext = createContext<SidebarStateContextType>({
  isCollapsed: false,
  setIsCollapsed: () => {},
});

export const useSidebarState = () => useContext(SidebarStateContext);

export const SidebarStateProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <SidebarStateContext.Provider value={{ isCollapsed, setIsCollapsed }}>
      {children}
    </SidebarStateContext.Provider>
  );
};
