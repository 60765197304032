import MultipleChoiceOption from "./MultipleChoiceOption";

export default class Question {
  private readonly id;
  public assignment: string;
  public question: string;
  public index: number;
  public max_points: number;
  public choices?: MultipleChoiceOption[]

  constructor(
    id: string,
    assignment: string, 
    question: string,
    index: number,
    max_points: number,
    choices?: MultipleChoiceOption[],
  ) {
    this.id = id;
    this.assignment = assignment;
    this.question = question;
    this.index = index;
    this.max_points = max_points;
    this.choices = choices;
  }

  static fromQuestion(question: Question) {
    return new Question(
      question.getId(),
      question.assignment,
      question.question,
      question.index,
      question.max_points,
      question.choices,
    )
  }

  static fromServerQuestion(question: Record<string, any>): Question {
    return new Question(
      question['id'],
      question['assignment'],
      question['question'],
      question['index'],
      question['max_points'],
      question['choices']
    );
  }

  public getId(): string {
    return this.id;
  }

  public toJSON(): Record<string, any> {
    return { ...this }
  }
}