import React, { useEffect, useState } from 'react';
import { UserInput } from '../common/UserInput';
import ActionButton from '../buttons/ActionButton';
import apiService from 'services/apiService';
import { useAlert } from 'contexts/AlertContext';
import Form from '../common/Form';

type DemoRequestFormProps = {
  onSubmit: () => void;
};

type DemoRequestFormData = {
  name: string;
  email: string;
  phoneNumber?: string;
  school?: string;
};

const DemoRequestForm: React.FC<DemoRequestFormProps> = ({ onSubmit }) => {
  const [formData, setFormData] = useState<DemoRequestFormData>({
    name: '',
    email: '',
    phoneNumber: undefined,
    school: undefined,
  });
  const { setAlert } = useAlert();

  useEffect(() => {
    return () => {
      setAlert(undefined);
    };
  }, [setAlert]);

  const handleChange = (event: any) => {
    const { id, value } = event.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (formData.name === '' || formData.email === '') {
      setAlert({
        type: 'danger',
        silent: true,
        message: 'Name and email are required',
      });
      return;
    }
    apiService.api.post('/request_demo/', formData).then(() => {
      setAlert({
        type: 'success',
        silent: true,
        message:
          "We have received your request. Thank you for your interest, we'll get back to you as soon as possible!",
      });
    });
  };

  return (
    <Form style={{ width: '400px', maxWidth: '400px' }} onSubmit={handleSubmit}>
      <UserInput
        type="text"
        id="name"
        label="Name"
        value={formData.name}
        onChange={handleChange}
      />
      <UserInput
        type="email"
        id="email"
        label="Email"
        value={formData.email}
        onChange={handleChange}
      />
      <UserInput
        type="text"
        id="phoneNumber"
        label="Phone Number (optional)"
        value={formData.phoneNumber}
        onChange={handleChange}
      />
      <UserInput
        type="text"
        id="school"
        label="School (optional)"
        value={formData.school}
        onChange={handleChange}
      />
      <ActionButton buttonType="submit" type="go" label="Request Demo" />
    </Form>
  );
};

export default DemoRequestForm;
