import React, { MouseEventHandler } from 'react';

const arrow_forward: string = require('assets/icons/arrow-forward.svg').default;

type ItemButtonProps = {
  label: string;
  onClick: MouseEventHandler<HTMLDivElement>;
};

const ItemButton: React.FC<ItemButtonProps> = ({ label, onClick }) => {
  return (
    <div className="classroom-panel" onClick={onClick}>
      <label className="label-medium">{label}</label>
      <img src={arrow_forward} alt="" />
    </div>
  );
};

export default ItemButton;
