import React, { ReactNode, createContext, useContext, useState } from 'react';

const FormValidationContext = createContext({
  isPageValid: false,
  setPageValidity: (isValid: boolean) => {}
});

export const useFormValidation = () => useContext(FormValidationContext);

interface FormValidationProps {
  children: ReactNode;
}

export const FormValidationProvider: React.FC<FormValidationProps> = ({ children }) => {
  const [isPageValid, setPageValidity] = useState(false);

  return (
    <FormValidationContext.Provider value={{ isPageValid, setPageValidity }}>
      {children}
    </FormValidationContext.Provider>
  );
};
