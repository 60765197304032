import React from "react";
import contentService from "services/contentService";

export const VersionTag: React.FC = () => {
  const formContent = contentService.getCommonForms().version_tag;

  return (
    <div className='fine-print' style={{textAlign: 'center', alignSelf: 'center'}}>
      { formContent.text }
    </div>
  );
}