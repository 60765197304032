import React from 'react';

type FinePrintType = 'danger' | 'success';

interface FinePrintProps {
  text: string;
  type?: FinePrintType;
}

const FinePrint: React.FC<FinePrintProps> = ({ text, type }) => {
  return <div className={`fine-print ${type ?? ''}`}>{text}</div>;
};

export default FinePrint;
