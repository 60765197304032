import { AxiosError } from 'axios';

export const formatDate = (date: Date): string => {
  const hour = date.getHours();
  const minute = date.getMinutes().toString().padStart(2, '0'); // Ensure two digits

  return `${date.toDateString()} ${hour}:${minute}`;
};

export const formatComponentDate = (dateString: string): string => {
  if (dateString === '') return '';

  const date = new Date(dateString);
  const offset = date.getTimezoneOffset() * 60000; // Offset in milliseconds
  const localDate = new Date(date.getTime() - offset);

  return localDate.toISOString().slice(0, 16);
};

export const formatSeconds = (totalSeconds: number): string => {
  let roundedSeconds = Math.round(totalSeconds);
  const hours = Math.floor(roundedSeconds / 3600);
  const minutes = Math.floor((roundedSeconds % 3600) / 60);
  const seconds = roundedSeconds % 60;

  const paddedHours = hours.toString().padStart(2, '0');
  const paddedMinutes = minutes.toString().padStart(2, '0');
  const paddedSeconds = seconds.toString().padStart(2, '0');

  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
};

export const toServerDate = (localDateString: string): string => {
  // returns the date in UTC
  if (localDateString === '') return '';

  const date = new Date(localDateString);
  return date.toISOString().slice(0, 16);
};

export const fromServerDate = (utcDateString: string): string => {
  // returns the date in local timezone
  if (utcDateString === '') return '';

  const date = new Date(utcDateString);
  const offset = date.getTimezoneOffset() * 60000;
  const localDate = new Date(date.getTime() - offset);

  return localDate.toISOString().slice(0, 16);
};

export const parseError = (error: any): string => {
  const default_error_msg = 'Something went wrong on our end!';
  console.error(error);
  if (error instanceof AxiosError) {
    return error.response?.data.error ?? default_error_msg;
  } else {
    return error.message ?? default_error_msg;
  }
};

export const isValidUrl = (urlString: string): boolean => {
  try {
    new URL(urlString);
    return true;
  } catch (e) {
    return false;
  }
};

export const calculateEndDate = (
  start_date: Date,
  durationStr: string,
): Date => {
  const [daysStr, timeStr] = durationStr.split(' ');
  const days = parseInt(daysStr, 10);

  const [hours, minutes, seconds] = timeStr
    .split(':')
    .map((part) => parseInt(part, 10));

  const end_date = new Date(start_date.getTime());

  end_date.setDate(end_date.getDate() + days);
  end_date.setHours(end_date.getHours() + hours);
  end_date.setMinutes(end_date.getMinutes() + minutes);
  end_date.setSeconds(end_date.getSeconds() + seconds);

  return end_date;
};
