import React, { useState } from 'react';


type HamburgerMenuItem = {
  path: string,
  label: string,
}

interface HamburgerMenuProps {
  menuItems: HamburgerMenuItem[]
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ menuItems }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
      <div>
        <button className="hamburger-icon" onClick={() => setIsOpen(!isOpen)}>
          ☰
        </button>
        {isOpen && (
          <div className="dropdown-menu">
            <ul>
              {
                menuItems.map(item => 
                  <li key={`menu-item-${item.label}`}>
                    <a href={item.path}>{ item.label }</a>
                  </li>
                )
              }
            </ul>
          </div>
        )}
      </div>
    );
  };
  
  export default HamburgerMenu;