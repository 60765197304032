import React from 'react';
import Book from 'models/Book';
import ActionButton from 'view/components/buttons/ActionButton';
import contentService from 'services/contentService';

import 'view/style/catalog/components/bookPreview.css';
import { WordList } from 'view/components/common/WordList';

const menu_book: string = require('assets/icons/menu_book_gray.svg').default;

interface AdminBookPreviewProps {
  book: Book;
  onView: (book: Book) => void;
  onQuestions: (book: Book) => void;
  onStatusChange: (book: Book, accept: boolean) => void;
  onEdit: (book: Book) => void;
}

type BookDetail = {
  label: string;
  accessor: keyof Book;
};

export const AdminBookPreview: React.FC<AdminBookPreviewProps> = ({
  book,
  onView,
  onQuestions,
  onStatusChange,
  onEdit,
}) => {
  const formContent = contentService.getCatalogPage().book_preview;

  return (
    <div className="book-preview">
      <label className="label-large">{book.title}</label>
      <div className="book-preview-content">
        <div className="book-card-cover">
          {book.cover_image ? (
            <img src={book.cover_image} alt="" />
          ) : (
            <img className="icon" src={menu_book} alt="" />
          )}
        </div>
        <div className="book-preview-details">
          {formContent.details.map((detail: BookDetail) => (
            <div
              key={`book-detail-${detail.label}`}
              className="sidebar-content-label"
              style={{ justifyContent: 'space-between' }}
            >
              <label className="label-normal">{detail.label}:</label>
              <label className="label-normal">
                {book[detail.accessor]?.toString()}
              </label>
            </div>
          ))}
          {book.tags.length > 0 && (
            <div key={`book-detail-tags`} className="sidebar-content-label">
              <div className="col">
                <label className="label-normal">Tags:</label>
                <WordList words={book.tags} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <ActionButton type="edit" onClick={() => onView(book)} label="View" />
        <ActionButton type="edit" onClick={() => onEdit(book)} label="Edit" />
        <ActionButton
          type="go"
          onClick={() => onQuestions(book)}
          label="Questions"
        />
        {book.is_public ? (
          <ActionButton
            type="delete"
            onClick={() => onStatusChange(book, false)}
            label="Remove from catalog"
          />
        ) : (
          <div className="row">
            <ActionButton
              type="go"
              onClick={() => onStatusChange(book, true)}
              label="Approve"
            />
            <ActionButton
              type="delete"
              onClick={() => onStatusChange(book, false)}
              label="Reject"
            />
          </div>
        )}
      </div>
    </div>
  );
};
