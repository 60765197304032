import React, { ReactNode } from 'react';

import 'view/style/common/components/inlineValueEditor.css';
import IconButton from '../buttons/IconButton';

interface InlineValueEditorProps {
  label: string;
  onConfirm: () => void;
  onCancel: () => void;
  inputElement: ReactNode;
}

const InlineValueEditor: React.FC<InlineValueEditorProps> = ({
  label,
  onConfirm,
  onCancel,
  inputElement,
}) => {
  return (
    <div className="inline-value-editor">
      <div className="editor">
        <div className="label-small">{label}:</div>
        {inputElement}
      </div>
      <div className="buttons">
        <IconButton type="transparent" icon="confirm" onClick={onConfirm} />
        <IconButton type="transparent" icon="cancel" onClick={onCancel} />
      </div>
    </div>
  );
};

export default InlineValueEditor;
