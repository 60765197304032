import React from 'react'

import 'view/style/home/privacy.css';

export const TermsOfService: React.FC = () => {
  return (
    <div className='app-main-content'>
      <h1>Orator Terms of Service</h1>
      <div className='classroom-list'>
        <div className='privacy-policy'>
          <p>Welcome to Orator! These Terms of Service (these “Terms”) govern your use of Oratorlearning.us (“Website”) and the related Service (together with the Website, the “Service”) which are owned and operated by Orator Technology Inc. (“Orator,” “us,” or “we”). Certain features of the Service may be subject to additional terms which will be posted on the Website or made available in connection with such features. All such additional terms are incorporated by reference into these Terms.</p>
          <p>These Terms create a legal agreement directly between you and Orator and explain the rules governing use of the Service. By accessing or using the Service, you acknowledge and agree that you have read, understand, and agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms, please do not access or use the Service.</p>
          <p>Except as described below, you represent and warrant that you are: (i) over eighteen (18) years of age or the age of majority in your jurisdiction, whichever is greater; (ii) of legal age to form a binding contract; and (iii) not a person barred from using the Service under the laws of your country of residence or any other applicable jurisdiction.</p>
          <p>‍</p>
          <p>NOTICE: AS DETAILED BELOW, DISPUTES ABOUT THESE TERMS AND THE SERVICE PROVIDED ARE SUBJECT TO BINDING ARBITRATION AND A WAIVER OF CLASS ACTION RIGHTS.</p>
          <p>‍</p>
          <p>Acceptable Use of the Service</p>
          <p>Orator’s goal is to create a safe and enjoyable environment to assist students reading through designated content (“Reading”). Consistent with our goals, you cannot use the Service in a manner that is disruptive or harmful to others. </p>
          <p>‍</p>
          <p>Changes to the Terms of Service</p>
          <p>Orator&nbsp;reserves the right at any time to modify the Service, with or without notice, and Orator shall not be liable to you or any third party for any such modification; provided, Orator shall provide you reasonable prior notice if such modification will materially and adversely affect the functionality of the Service. If at any time you do not agree to, or cannot comply with, the modified Terms, you must stop using the Service.</p>
          <p>‍</p>
          <p>Using the Service </p>
          <p>To use the Service, a school district, school, school administrator or teacher who uses the Service (each a “Teacher”) must register and create an account (“Account”). You agree to provide accurate, current and complete information about you and your class.</p>
          <p>Teacher shall provide any data to Orator in compliance with all applicable laws, including but not limited to, the Federal Educational Rights and Privacy Act (“FERPA”), Children’s Online Privacy Protection Act (“COPPA”), and Protection of Pupil Rights Amendment (“PPRA”). Teacher represents, warrants, and covenants to Orator, that Teacher will: (i) comply with all applicable provisions of FERPA relating to disclosures to School Officials (as defined in 34 CFR 99.31 (B)) with a legitimate educational interest, including, without limitation, informing parents in their annual notification of FERPA rights, that you define “school official” to include service providers and define “legitimate educational interest” to include Service such as the Service; and (ii) provide sufficient notice and disclosure of the Terms and additional policies as required, and obtain and maintain all necessary rights and consents, including consents required by applicable state and federal law, rules, and regulations, such as COPPA, from, either each student or, where necessary, each student’s parent or legal guardian, to allow Orator to provide and improve the Service and collect and receive student data in accordance with these Terms and the Privacy Policy. Orator relies on consent obtained from Teachers, acting as an agent of the parent or legal guardian of its students, as permitted by COPPA, for using the Service in connection with students under thirteen (13) years of age. Teacher must keep all consents on file and provide them to Orator if requested. </p>
          <p>We recommend that you provide students and their respective parents or guardians with a copy of Orator’s Privacy Policy and any other parental information made available by Orator.</p>
          <p>Orator may only be used by Teachers, students, parents, or private tutors that have been permitted by the parent or guardian. Students must be invited to use Orator by a Teacher or parent/guardian and may use Orator so long as they are a students under the supervision of a Teacher. You will only use, and only permit your students to use, Orator for non-commercial, educational purposes.</p>
          <p>We may choose to suspend or terminate your Account at any time in our sole discretion for any or no reason at all, including if, in our opinion, you have violated any provision of these Terms.</p>
          <p>You are responsible for maintaining the confidentiality of your Account login information and are fully responsible for all activities that occur under your Account. You agree to immediately notify Orator of any unauthorized use, or suspected unauthorized use, of your Account or any other breach of security. Orator cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.</p>
          <p>‍</p>
          <p>Ownership and Intellectual Property</p>
          <p>Orator&nbsp;grants you a limited, non-exclusive, non-transferable, non-sublicensable, revocable license to access and view the Service. To the extent that the Service provides access to any online software, applications or other similar components, Orator grants you a limited, non-exclusive, non-transferable, non-sublicensable, revocable license to access and use such components only in the form within the Service.</p>
          <p>You acknowledge that all the intellectual property rights, including copyrights, patents, trademarks, and trade secrets, in the Service and its content are owned or licensed by Orator. All trademarks, logos, and service marks (“Marks”) displayed on the Service are our property or the property of other third parties. You are not permitted to use these Marks without our prior written consent or the consent of such third party which may own the Marks.</p>
          <p>Neither these Terms, nor your access to the Service, transfers to you or any third party any rights, title or interest in or to such intellectual property rights, except for the limited access rights expressly set forth in these Terms. Orator reserves all rights not granted in these Terms. There are no implied licenses granted under these Terms.</p>
          <p>You may, at your option, provide us with feedback, comments, and suggestions for improvements to the Service (collectively, “Suggestions”). You hereby grant to Orator a non-exclusive, worldwide, perpetual, irrevocable, fully paid-up, royalty-free, sublicensable and transferable license to any Suggestions under any and all intellectual property rights that you own or control.</p>
          <p>The Service contain materials that are proprietary and are protected by copyright laws, international treaty provisions, trademarks, service marks, and other intellectual property laws and treaties. You agree to abide by all applicable copyright and other laws, as well as any additional copyright notices or restrictions contained on the Service. You acknowledge that the Service contain original works that have been developed, compiled, prepared, revised, selected, and arranged by Orator and others, through the application of methods and standards of judgment developed and applied through the expenditure of substantial time, effort, and money and which constitute valuable intellectual property of Orator and such others. No content may be modified, copied, distributed, reproduced, republished, downloaded, scraped, displayed, posted, transmitted, or sold in any form or by any means, in whole or in part, other than as expressly permitted in these Terms.</p>
          <p>‍</p>
          <p>Creating and Sharing a Reading </p>
          <p>You can create and share a Reading with other Teachers. You represent that you own or have secured all legal rights necessary for the Reading. Orator does not claim ownership of your Reading. However, you grant Orator and its service providers a worldwide, royalty-free, fully-paid-up, non-exclusive, sublicensable, transferable license to use, reproduce, modify, adapt, create derivative works from, publicly perform, publicly display, distribute, make and have made your Reading (in any form and any medium, whether now known or later developed) as necessary to provide the Service. Additionally, you acknowledge and agree that Orator may collect and use for any lawful purpose your Reading in aggregate form, without identifying individual students or you. </p>
          <p>If you upload a Reading to Orator’s Public Catalog or “Library” you have consented other Orator users can use the Reading for themselves and Orator can use the Reading on oratorlearning.us as it pleases.</p>
          <p>You will not upload a Reading that is harmful, threatening, abusive, harassing, tortious, indecent, defamatory, sexually explicit or pornographic, discriminatory, vulgar, profane, obscene, libelous, hate speech, violent or inciting violence, inflammatory, or otherwise objectionable. You will not upload a Reading that may expose Orator or its users to any harm or liability of any kind. Orator reserves the right to remove a Reading for violating these Terms or for no reason at all.</p>
          <p>Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Service. YOU WAIVE AND HOLD HARMLESS ORATOR AND ITS AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.</p>
          <p>‍</p>
          <p>Payment Processors</p>
          <p>Unless otherwise provided for in your school’s or district’s agreement with Orator, all financial transactions made in connection with the Service will be processed by a third party in accordance with their respective terms of use, privacy policy, and/or any applicable payment terms and conditions. Orator will not be responsible for the actions or inactions of any third-party payment processor. </p>
          <p>‍</p>
          <p>Third-Party Links, Sites, and Services</p>
          <p>The Service may contain links to third-party websites and services. Such third-party links are not under the control of Orator, and Orator is not responsible for any third-party links. When you click on any of the third-party links, the applicable third party’s terms and policies apply, including the third party’s privacy and data gathering practices. You further acknowledge and agree that Orator shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any linked sites or resources.</p>
          <p>‍</p>
          <p>Warranties, Disclaimers and Limitation of Liability</p>
          <p>THE SERVICE AND THE CONTENT CONTAINED THEREIN ARE PROVIDED “AS IS” WITHOUT WARRANTY, REPRESENTATION, CONDITION, OR GUARANTEE OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES, REPRESENTATIONS, CONDITIONS OR GUARANTEES OF QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, ALL OF WHICH ARE DISCLAIMED TO THE FULLEST EXTENT PERMITTED BY LAW. SPECIFICALLY, BUT WITHOUT LIMITATION, ORATOE DOES NOT WARRANT THAT: (i) THE INFORMATION AVAILABLE ON THE SERVICE IS FREE OF ERRORS; (ii) THE FUNCTIONS OR FEATURES WILL BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS; (iii) DEFECTS WILL BE CORRECTED, OR (iv) THE SERVICE OR THE SERVER(S) THAT MAKE THE SERVICE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. IN NO EVENT SHALL ORATOR OR ITS AFFILIATES, LICENSORS, VENDORS, OR ANY OF THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, OR OTHER REPRESENTATIVES BE LIABLE TO YOU OR ANY OTHER PERSON OR ENTITY FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES (INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, LOSS OF USE, OR COSTS OF OBTAINING SUBSTITUTE GOODS OR SERVICES), ARISING OUT OF OR IN CONNECTION WITH THE SERVICE, ANY MATERIALS, INFORMATION, OR RECOMMENDATIONS APPEARING ON THE SERVICE, OR ANY LINK PROVIDED ON THE SERVICE, WHETHER OR NOT ORATOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND WHETHER BASED UPON WARRANTY, CONTRACT, TORT, STRICT LIABILITY, VIOLATION OF STATUTE, OR OTHERWISE. </p>
          <p>THIS EXCLUSION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW. IN ANY EVENT, OUR AGGREGATE LIABILITY WILL NOT EXCEED THE AMOUNT PAID FOR THE SERVICE TO WHICH THE CLAIM RELATES OR, IF THE CLAIM DOES NOT RELATE TO A PAID PRODUCT OR SERVICE, $100. </p>
          <p>If you are a California resident, you hereby waive California Civil Code §1542, which says: “A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which if known by him or her must have materially affected his or her settlement with the debtor.” This release includes the criminal acts of others.</p>
          <p>‍</p>
          <p>Indemnification</p>
          <p>You agree to defend, indemnify and hold Orator (and its officers, employees, and agents) harmless, including costs and attorneys’ fees, from any claim or demand made by any third party due to or arising out of (a) your use of the Service, (b) your violation of these Terms, or (c) your violation of applicable laws or regulations. Orator reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims. You agree not to settle any matter without the prior written consent of Orator. Orator will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it</p>
          <p>‍</p>
          <p>DMCA Policy</p>
          <p>‍</p>
          <p>Orator respects the intellectual property of others and requires that users of the Service do the same. In connection with our Service, we have adopted and implemented a policy respecting copyright law that provides for the removal of any infringing materials and for the termination, in appropriate circumstances, of users who are repeat infringers of intellectual property rights, including copyrights. If you believe that one of our users is, through the use of our Service, unlawfully infringing the copyright(s) in a work, and wish to have the allegedly infringing material removed, the following information in the form of a written notification (pursuant to the Digital Millennium Copyright Act ( “DMCA”), 17 U.S.C. § 512(c)) to support@oratorlearning.us (Subject: DMCA).</p>
          <p>The notice must contain:</p>

          <ul><li>your physical or electronic signature;</li>
          <li>identification of the copyrighted work(s) that you claim to have been infringed;</li>
          <li>identification of the material on our Service that you claim is infringing and that you request us to remove;</li>
          <li>sufficient information to permit us to locate such material; your address, telephone number, and e-mail address;</li>
          <li>a statement that you have a good faith belief that use of the objectionable material is not authorized by the copyright owner, its agent, or under the law; and a statement that the information in the notification is accurate, and under penalty of perjury, that you are either the owner of the copyright that has allegedly been infringed or that you are authorized to act on behalf of the copyright owner.</li>
          </ul>

          <p>Please note that, pursuant to 17 U.S.C. § 512(f), any misrepresentation of material fact (falsities) in a written notification automatically subjects the complaining party to liability for any damages, costs and attorney’s fees incurred by us in connection with the written notification and allegation of copyright infringement.</p>
          <p>‍</p>
          <p>Governing Law and Arbitration; No Class Action; Waiver of Jury Trial</p>
          <p>You and Orator agree that these Terms affect interstate commerce and that the Federal Arbitration Act governs the interpretation and enforcement of these arbitration provisions. This Section is intended to be interpreted broadly and governs any and all disputes between us including but not limited to claims arising out of or relating to any aspect of the relationship between us, whether based in contract, tort, statute, fraud, misrepresentation or any other legal theory; claims that arose before these Terms or any prior agreement (including, but not limited to, claims related to advertising); and claims that may arise after the termination of these Terms (together, “Claims”). The only disputes excluded from this broad prohibition are the litigation of certain intellectual property and small court claims, as provided below.</p>
          <p>Most disputes can be resolved without resort to arbitration. If you have any dispute with us, you agree that before taking any formal action, you will contact us and provide a brief, written description of the dispute and your contact information (including your username, if your dispute relates to an Account). Except for intellectual property and small claims court claims, the parties agree to use their best efforts to settle any dispute, claim, question, or disagreement directly through consultation with Orator, and good faith negotiations shall be a condition to either party initiating a lawsuit or arbitration.</p>
          <p>ANY DISPUTE OR CLAIM RELATING IN ANY WAY TO THESE TERMS AND CONDITIONS OR THE SERVICE WILL BE RESOLVED BY BINDING ARBITRATION, RATHER THAN IN COURT, EXCEPT FOR ORATOR’S RIGHT TO SEEK INJUNCTIVE RELIEF AS SET FORTH BELOW. </p>
          <p>If you do not want to arbitrate disputes with Orator, you may opt out of this arbitration agreement by sending an email to support@oratorlearning.us within 30 days of the day you first access or use the Service.</p>
          <p>If you intend to seek arbitration you must first send written notice to Orator of your intent to arbitrate (“Demand”) by email to support@oratorlearning.us. The Demand must (i) describe the dispute; (ii) set forth the specific relief sought; and (iii) set forth your name, address and contact information. If we intend to seek arbitration against you, we will send any notice of dispute to you at the contact information we have for you.</p>
          <p>The arbitration will be conducted before a neutral single arbitrator in Middlesex County in the Commonwealth of Massachusetts, whose decision will be final and binding, and the arbitral proceedings will be governed by JAMS under its Comprehensive Arbitration Rules and Procedures (the “JAMS Rules”) as modified by these Terms and Conditions. The JAMS Rules are available at www.jamsadr.com. All issues are for the arbitrator to decide, including the scope of this arbitration clause, but the arbitrator is bound by the terms of these Terms. If you initiate arbitration, your arbitration fees will be limited to the filing fee set forth in the JAMS Rules. We will reimburse all other JAMS filing, administration and arbitrator fees paid by you, unless the arbitrator determines that the arbitration was frivolous or brought for an improper purpose, in which case the payment of all such fees shall be governed by the JAMS Rules. The arbitration will be conducted in the English language. Judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. For any Claim where the potential award is reasonably likely to be $10,000 or less, either you or Orator may elect to have the dispute resolved through non-appearance-based arbitration.</p>
          <p>To the fullest extent permitted by applicable law, YOU AND ORATOR EACH AGREE THAT ANY DISPUTE RESOLUTION PROCEEDING WILL BE CONDUCTED ONLY ON AN INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED OR REPRESENTATIVE ACTION. If for any reason a Claim proceeds in court rather than in arbitration, YOU AND ORATOR EACH WAIVE ANY RIGHT TO A JURY TRIAL. If a court of competent jurisdiction finds the foregoing arbitration provisions invalid or inapplicable, you and Orator agree that all Claims arising out of or related to these Terms must be resolved exclusively by a state or federal court located in Middlesex County in the Commonwealth of Massachusetts, and you and Orator each agree to submit to the exercise of personal jurisdiction of such courts for the purpose of litigating all such claims. Notwithstanding the above, you agree that Orator shall still be allowed to apply for and obtain injunctive remedies (or an equivalent type of urgent legal relief) in any jurisdiction.</p>
          <p>‍</p>
          <p>Applicable law and export control</p>
          <p>You agree to abide by all applicable local, state, national and foreign laws, treaties and regulations, in connection with your use of the Service. Orator agrees to abide by all applicable local, state, national and foreign laws, treaties and regulations, in connection with its provision of the Service.</p>
          <p>You may not use, export, import, or transfer the Service or any part of the Service except as authorized by U.S. law, the laws of the jurisdiction in which you obtained the Service, and any other applicable laws. By using the Service, you represent and warrant that (a) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country and (b) you are not listed on any U.S. Government list of prohibited or restricted parties. You will not permit any student to access or use the Service in a U.S.-embargoed country or region or in violation of any U.S. export law or regulation.</p>
          <p>‍</p>
          <p>Miscellaneous </p>
          <p>Except for residents of New Jersey, you agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to the use of the Service and/or these Terms must be filed within one (1) year after such claim or cause of action arose or be forever barred.</p>
          <p>These Terms will be governed by and construed in accordance with the laws of the Commonwealth of Massachusetts, without regard to its conflict of laws provisions. Any legal action or proceeding arising under these Terms shall be brought exclusively in the federal or state courts located in Middlesex County, Massachusetts and the parties consent to exclusive jurisdiction of such courts. The United Nations Convention on Contracts for the International Sale of Goods is expressly excluded in its entirety from application to these Terms.</p>
          <p>We may deliver notice to you by e-mail, posting a notice on the Service or any other method we choose and such notice will be effective on dispatch. If you give notice to us, it will be effective when received at support@oratorlearning.us.</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
      </div>
    </div>
  )
};