import React from 'react';
import { ButtonType } from 'utils/types';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';

import 'view/style/common/buttons.css';

const FILL_COLORS: Record<string, string> = {
  go: '#BAFC95',
  delete: '#FC95A8',
  option: '#FCF095',
  default: '#F0EFED',
  transparent: 'rgba(0, 0, 0, 0)',
};

interface ButtonProps {
  type?: ButtonType;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  text?: string;
  label: string;
  leadIcon?: string;
  trailIcon?: string;
  buttonType?: 'button' | 'submit' | 'reset';
}

const Button: React.FC<ButtonProps> = ({
  type,
  onClick,
  text,
  label,
  leadIcon,
  trailIcon,
  buttonType,
}) => {
  const fill_color = type ? FILL_COLORS[type] : FILL_COLORS['none'];

  return (
    <div className="app-button">
      <button
        className="button-content"
        onClick={onClick}
        style={{ background: fill_color }}
        type={buttonType}
      >
        {leadIcon && <img src={leadIcon} alt="" />}
        <span className="label-large">{label}</span>
        {trailIcon && <img src={trailIcon} alt="" />}
      </button>
      {text && <AudioAssistanceButton text={text} />}
    </div>
  );
};

export default Button;
