import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import paymentService from 'services/paymentService';
import useApiCall from 'contexts/ApiCall';
import { STRIPE_PUBLIC_KEY } from 'constants/apiConstants';
// import { STRIPE_TEST_PUBLIC_KEY } from 'constants/apiConstants';
import { loadStripe } from '@stripe/stripe-js';
import InjectedCheckoutForm from './CheckoutForm';
import { useFormContext } from 'contexts/FormContext';
import { SubscriptionFormData } from 'utils/types';
import contentService from 'services/contentService';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
// const stripePromise = loadStripe(STRIPE_TEST_PUBLIC_KEY);


interface PaymentFormProps {
  onSubmit: (formData: any) => void;
}

const PaymentForm: React.FC<PaymentFormProps> = ({ onSubmit }) => {
  const [options, setOptions] = useState<Record<string, any>>();
  const { formData } = useFormContext<SubscriptionFormData>();
  const formContent = contentService.getPaymentPage();
  const makeApiCall = useApiCall();

  useEffect(() => {
    makeApiCall(paymentService.createPaymentIntent, formData.subscription)
      .then(resp => setOptions(resp))
      .catch(error => alert(error.message));
  }, [formData.subscription, makeApiCall]);

  if (!options) return null;

  const handleSubmit = () => {
    onSubmit(formData);
  }

  return (
    <div className='login-content'>
      <h3>{ formContent.title }</h3>
      <Elements stripe={stripePromise} options={options}>
        <InjectedCheckoutForm onSubmit={handleSubmit} />
      </Elements>
    </div>
  );
};

export default PaymentForm;