import { useUserPreferences } from 'contexts/UserPreferencesContext';
import React, { ReactNode } from 'react';

type ReadingFrameProps = {
  isBlurred?: boolean;
  isDyslexic?: boolean;
  isInteractive?: boolean;
  interactionCallback?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
  children: ReactNode;
};

const ReadingFrame: React.FC<ReadingFrameProps> = ({
  isBlurred = false,
  isDyslexic = false,
  isInteractive = false,
  interactionCallback,
  children,
}) => {
  const { fontScale, lineSpacing } = useUserPreferences();

  return (
    <div
      className={`reading-frame ${isBlurred ? 'blurred' : ''} ${
        isDyslexic ? 'dyslexic' : ''
      }`}
      style={
        {
          '--font-scale': fontScale,
          '--line-spacing-scale': lineSpacing,
        } as React.CSSProperties
      }
      onClick={(event) => {
        if (isInteractive && interactionCallback) {
          event.preventDefault();
          interactionCallback(event);
        } else {
          event.stopPropagation();
          event.preventDefault();
        }
      }}
    >
      {children}
    </div>
  );
};

export default ReadingFrame;
