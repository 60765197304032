import React, { useState } from 'react';
import { Slider } from '@mui/material';

import 'view/style/common/components/filter.css';

const arrow_drop_down: string = require('assets/icons/arrow_drop_down.svg').default;
const arrow_right: string = require('assets/icons/arrow_right.svg').default;

export interface FilterOption {
 is_active: boolean;
 value: string;
 alias?: string;
}

interface FilterProps {
  type: string;
  label: string;
  options?: FilterOption[];
  max?: number;
  rangeInput?: Array<number>;
  onRangeChange?: (event: Event, value: number | number[], activeThumb: number) => void;
  onOptionChange?: (checked: boolean, option: FilterOption) => void;
}

export const Filter: React.FC<FilterProps> = ({ type, label, options, max, rangeInput, onRangeChange, onOptionChange }) => {

  const [expand, setExpand] = useState<boolean>(false);

  return (
    <div className="collapsable-filter">
      <div className='sidebar-content-label' onClick={() => setExpand(prev => !prev)}>
        <img src={expand ? arrow_drop_down : arrow_right} alt='dropdown'/>
        <label className='label-small'>{label}</label>
      </div>
      {
        expand &&
        <div className='filter-content'>
          { type === 'range' && rangeInput && onRangeChange && max ?
            <>
              { rangeInput[0] }
              <Slider
                getAriaLabel={() => `${label} range`}
                max={max}
                value={rangeInput}
                onChange={onRangeChange}
                valueLabelDisplay="auto"
                getAriaValueText={(value: number, index: number) => `${value === 0 ? 'Min' : 'Max'} value set to ${index}`}
                className='slider'
              />
              { rangeInput[1] }
            </>
            : onOptionChange && options &&
            options.map(option => 
              <div key={`option-${option.value}`} className='assignment-sidebar-text'>
                <input 
                  type={type}
                  id={option.value}
                  checked={option.is_active}
                  onChange={(event) => onOptionChange(event.target.checked, option)}
                />
                <label htmlFor={option.value} className='label-small'>{option.value}</label>
              </div>
            )
          }
        </div>
      }
    </div>
  );
};