import React, { createContext, useState, useContext, ReactNode } from 'react';
import Modal from 'view/components/common/Modal';
import Alert from 'view/components/common/Alert';

type AlertConfig = {
  type: 'danger' | 'success';
  message: string;
  silent: boolean;
};

type AlertContextType = {
  alert?: AlertConfig;
  setAlert: (alert?: AlertConfig) => void;
};

const AlertContext = createContext<AlertContextType>({
  alert: undefined,
  setAlert: () => {},
});

export const useAlert = () => useContext(AlertContext);

export const AlertProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [alert, setAlert] = useState<AlertConfig>();

  return (
    <AlertContext.Provider value={{ alert, setAlert }}>
      {children}
      {!alert?.silent && (
        <Modal isOpen={alert !== undefined}>
          {alert && <Alert type={alert.type} message={alert.message} />}
        </Modal>
      )}
    </AlertContext.Provider>
  );
};
