import React, { ChangeEvent, useState } from "react";
import FinePrint from "view/components/common/FinePrint";

import 'view/style/teacher/components/assignment/fileInput.css';

const upload_file: string = require('assets/icons/upload_file.svg').default;

interface FileInputProps {
  id?: string;
  label?: string;
  accept?: string;
  onFileChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FileInput: React.FC<FileInputProps> = ({ id='file-upload', label='Choose Files', accept, onFileChange=()=>{} }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setSelectedFile(file);
    onFileChange(e);
  };

  return (
    <div className="file-input">
      <label htmlFor={id} className="file-upload-button">
        <img src={upload_file} alt=''/>
        <span className="label-small">{ label }</span>
      </label>
      <FinePrint text={ selectedFile?.name || 'None chosen' } />
      <input
        type='file'
        id={id}
        onChange={handleFileChange}
        accept={accept}
      />
    </div>
  );
}