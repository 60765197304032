import React, { useEffect, useRef } from 'react';

import 'view/style/common/components/speechBubble.css';

const dingSound = require('assets/sounds/ding.mp3');

interface SpeechBubbleProps {
  text: string;
  correct?: boolean;
}

const SpeechBubble: React.FC<SpeechBubbleProps> = ({
  text,
  correct = false,
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (correct && audioRef.current) {
      audioRef.current.play();
    }
  }, [correct]);

  return (
    <div className={`speech-bubble sb3 ${correct ? ' correct' : ''}`}>
      {text}
      <audio ref={audioRef} src={dingSound} />
    </div>
  );
};

export default SpeechBubble;
