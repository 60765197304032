import { Chart, registerables, ChartTypeRegistry } from 'chart.js';
Chart.register(...registerables);

export const generateChartImage = (
  data: any,
  options: any,
  type: keyof ChartTypeRegistry,
): Promise<string> => {
  return new Promise((resolve) => {
    // console.log(data);
    // console.log(options);
    const canvas = document.createElement('canvas');
    canvas.width = 700;
    canvas.height = 400;
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      throw new Error('Could not get canvas context');
    }

    const chart = new Chart(ctx, {
      type: type,
      data: data,
      options: {
        ...options,
        responsive: false, // Disable responsiveness to use specified size
        maintainAspectRatio: false, // Disable maintaining aspect ratio
        animation: {
          duration: 0, // Ensure no animation delay
        },
        plugins: {
          legend: {
            display: true, // Ensure legend is displayed
            position: 'top', // You can change the position if needed ('top', 'left', 'bottom', 'right')
          },
        },
      },
    });

    chart.update();

    document.body.appendChild(canvas);

    setTimeout(() => {
      const base64Image = canvas.toDataURL('image/png');
      chart.destroy();
      document.body.removeChild(canvas);
      resolve(base64Image);
    }, 2000);
  });
};
