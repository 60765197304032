import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import Button from 'view/components/buttons/Button';
import Book from 'models/Book';
import ReadingUtils from 'utils/ReadingUtils';
import AudioPlayer from 'view/components/common/AudioPlayer';
import MissedWordDisplay from '../MissedWordDisplay';
import MissedWord from 'models/MissedWord';
import ReadingFrame from 'view/components/common/ReadingFrame';

const wormy_a: string = require('assets/images/logos/wormy-1.png');
// const wormy_b: string = require('assets/images/logos/wormy-book-2.png');

interface BookListenerProps {
  book: Book;
  isReading: boolean;
  customEndMessage?: string;
  missedWords?: MissedWord[];
  startTime?: number;
  dyslexicMode?: boolean;
  setIsReading: (value: boolean) => void;
  onCompletion?: () => void;
  onStopReading?: (timeStamp: number, duration: number) => void;
  onViewQuestions?: () => void;
  onMissedWord: (word: string, index: number, known: boolean) => void;
}

interface HtmlContent {
  content: string;
  index: number;
}

type ExpandWord = {
  word: string;
  index: number;
};

export const BookListener: React.FC<BookListenerProps> = ({
  book,
  isReading,
  customEndMessage,
  missedWords,
  startTime = 0,
  dyslexicMode = false,
  setIsReading,
  onCompletion,
  onStopReading,
  onViewQuestions,
  onMissedWord,
}) => {
  const [words, setWords] = useState<string[]>([]);
  const [expandWord, setExpandWord] = useState<ExpandWord | null>();
  const [htmlContent, setHtmlContent] = useState<HtmlContent>({
    content: '',
    index: 0,
  });
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  // const [timer] = useState<Timer>(new Timer());
  // const autosaveTimerKey = 'autosave';

  // useEffect(() => {
  //   const targetElement = document.getElementById((wordIndex - 1).toString());
  //   if (targetElement) {
  //     targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  //   }
  // }, [htmlContent.content, wordIndex, isReading]);

  // useEffect(() => {
  //   return () => {
  //     timer.clear(autosaveTimerKey);
  //   };
  // }, [timer]);

  useEffect(() => {
    if (!book || !book.html_content || words.length > 0) return;
    const initialHtmlContent = book.html_content.replaceAll('&nbsp;', ' ');
    setHtmlContent({
      content: initialHtmlContent,
      index: ReadingUtils.getFirstWordIndexFromHTMLString(initialHtmlContent),
    });
    setWords(() => book.getWords());
  }, [book, words.length]);

  useEffect(() => {
    // create a span for each word that allows the user to
    // click on a word to fetch its definition
    if (!words) return;
    for (let i = 0; i < words.length; i += 1) {
      generateSpanElement(i);
    }
    // eslint-disable-next-line
  }, [words]);

  const generateSpanElement = (wordIndex: number) => {
    if (wordIndex >= words.length) return;
    const openTag = `<span data-id='${wordIndex}' key='word-${wordIndex}'>`;
    const closeTag = '</span>';

    setHtmlContent((prevContent: HtmlContent) => {
      const nextHtmlWordIdx =
        prevContent.index +
        ReadingUtils.getFirstWordIndexFromHTMLString(
          prevContent.content.slice(prevContent.index),
        );
      const nextHtmlWordEndIdx = nextHtmlWordIdx + words[wordIndex].length;

      const newHtmlIdx = nextHtmlWordEndIdx + openTag.length + closeTag.length;

      let newContent = `${prevContent.content.slice(
        0,
        nextHtmlWordIdx,
      )}${openTag}${prevContent.content.slice(
        nextHtmlWordIdx,
        nextHtmlWordEndIdx,
      )}${closeTag}`;

      newContent += `${prevContent.content.slice(nextHtmlWordEndIdx)}`;

      return {
        content: newContent,
        index: newHtmlIdx,
      };
    });
  };

  const handleSpanClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    const target = event.target as HTMLElement;
    if (target.tagName === 'SPAN' && target.dataset.id) {
      const idx = parseInt(target.dataset.id);
      setIsReading(false);
      setExpandWord({ word: words[idx], index: idx });
    }
  };

  const handleAssignmentCompleted = () => {
    setIsCompleted(true);
    if (onCompletion) onCompletion();
  };

  const handleAssignmentPaused = (timstamp: number, duration: number) => {
    setIsReading(false);
    if (onStopReading) onStopReading(timstamp, duration);
  };

  return (
    <div className="reading-container">
      {expandWord ? (
        <MissedWordDisplay
          word={expandWord.word}
          index={expandWord.index}
          onExit={() => setExpandWord(null)}
          onMissedWord={onMissedWord}
          showPrompt={
            missedWords?.findIndex((mw) => mw.word === expandWord.word) === -1
          }
          showMissedWordIndicator={false}
        />
      ) : (
        <ReadingFrame
          isDyslexic={dyslexicMode}
          isInteractive={true}
          interactionCallback={handleSpanClick}
        >
          <>
            {parse(htmlContent.content)}
            {isCompleted && (
              <div className="col" style={{ alignItems: 'center' }}>
                <img src={wormy_a} alt="" style={{ width: '150px' }} />
                <br />
                {customEndMessage && (
                  <span className="label-large">{customEndMessage}</span>
                )}
              </div>
            )}
          </>
        </ReadingFrame>
      )}
      <br />
      {!isCompleted && (
        <AudioPlayer
          audioUrl={book.audio_url}
          onAudioEnded={handleAssignmentCompleted}
          onAudioPaused={handleAssignmentPaused}
          onAudioStarted={() => setIsReading(true)}
          startTime={startTime}
          isPaused={!isReading}
        />
      )}
      {isCompleted && (
        <div className="reading-footer">
          <div className="book-completed-text">
            <h2>You're Done</h2>
          </div>
          <div className="book-completed-text">
            <AudioPlayer
              audioUrl={book.audio_url}
              onAudioEnded={() => {}}
              onAudioPaused={() => {}}
              startTime={startTime}
              isPaused={!isReading}
            />
          </div>
          <div className="book-completed-text">
            {onViewQuestions && (
              <Button
                type="option"
                onClick={onViewQuestions}
                text="Questions"
                label="Questions"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
