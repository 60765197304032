import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BookCatalog } from 'view/components/catalog/BookCatalog';
import { AppPage } from 'view/components/common/AppPage';

export const CatalogPage: React.FC = () => {
  const navigate = useNavigate();

  const handleExitCatalog = () => {
    navigate('/teacher/home/');
  }

  return (
    <AppPage>
      <div className='app-page-content'>
        <BookCatalog onExit={handleExitCatalog}/>
      </div>
    </AppPage>
  )
}