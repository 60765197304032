import React, { useEffect, useRef, useState } from 'react';

interface AudioPlayerProps {
  audioUrl: any;
  startTime?: number;
  onAudioPaused?: (timeStamp: number, duration: number) => void;
  onAudioEnded?: () => void;
  onAudioStarted?: () => void;
  isPaused?: boolean;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  audioUrl,
  startTime = 0,
  onAudioPaused = () => {},
  onAudioEnded,
  onAudioStarted,
  isPaused = true,
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.currentTime = startTime;
    }
  }, [startTime]);

  useEffect(() => {
    if (audioRef.current) {
      if (isPaused) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
        if (onAudioStarted) onAudioStarted();
      }
    }
  }, [isPaused, onAudioStarted]);

  const handlePause = () => {
    if (audioRef.current) {
      const time = audioRef.current.currentTime;
      onAudioPaused(time, duration);
    }
  };

  const handleMetadataLoaded = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  return (
    <audio
      controls
      src={audioUrl}
      onPause={handlePause}
      onEnded={onAudioEnded}
      onLoadedMetadata={handleMetadataLoaded}
      ref={audioRef}
    />
  );
};

export default AudioPlayer;
