import React from 'react';
import { Bar } from 'react-chartjs-2';
import type { ChartData, ChartOptions } from 'chart.js';
import { BarElement, LinearScale, CategoryScale, Chart } from 'chart.js';

Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(BarElement);

interface BarChartProps {
  options: ChartOptions<'bar'>;
  data: ChartData<'bar'>;
}

const BarChart: React.FC<BarChartProps> = ({ options, data }) => {
  return (
    <div className="bar-chart">
      <Bar
        options={{
          scales: {
            x: {
              type: 'category',
              labels: data.labels as string[],
            },
            y: {
              type: 'linear',
            },
          },
          plugins: {
            legend: {
              display: true,
            },
          },
        }}
        data={data}
      />
    </div>
  );
};

export default BarChart;
