import React, { createContext, useState, useContext, ReactNode } from 'react';
import Modal from 'view/components/common/Modal';
import { LoadingIndicator } from 'view/components/common/LoadingIndicator';

type LoadingContextType = {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

const LoadingContext = createContext<LoadingContextType>({
  isLoading: false,
  setIsLoading: () => {},
});

export const useLoading = () => useContext(LoadingContext);

export const LoadingProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
      <Modal isOpen={isLoading}>
        <LoadingIndicator />
      </Modal>
    </LoadingContext.Provider>
  );
};
