import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import PasswordInput from 'view/components/common/PasswordInput';
import { useUserContext } from 'contexts/UserContext';
import googleApiService from 'services/googleAPIService';
import userAuthService from 'services/userAuthService';
import { UserInput } from 'view/components/common/UserInput';
import Button from 'view/components/buttons/Button';
import useApiCall from 'contexts/ApiCall';
import { isValidUrl } from 'utils/utils';
import contentService from 'services/contentService';

import 'view/style/home/login.css';
import 'view/style/home/home.css';

const logo: string = require('assets/images/logos/official_logo.png');

export const LoginPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const location = useLocation();
  const { user, setUser } = useUserContext();
  const [errorMessage, setErrorMessage] = useState<string>();
  const loginContent = contentService.getLoginPage();
  const navigate = useNavigate();
  const makeApiCall = useApiCall();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has('accessToken') && !searchParams.has('refreshToken'))
      return;

    const accessToken = searchParams.get('accessToken');
    const refreshToken = searchParams.get('refreshToken');
    if (
      !accessToken ||
      accessToken === 'undefined' ||
      !refreshToken ||
      refreshToken === 'undefined'
    )
      return;

    userAuthService.authenticate(accessToken, refreshToken);
    makeApiCall(userAuthService.getSessionUser)
      .then((respUser) => setUser(respUser))
      .catch((error: Error) => {
        if (isValidUrl(error.message)) {
          window.location.href = error.message;
        } else {
          setErrorMessage(error.message);
        }
      });
  }, [location, setUser, makeApiCall]);

  useEffect(() => {
    if (userAuthService.isAuthenticated()) {
      if (user) {
        navigate(`/${user.account_type.toLowerCase()}/home`);
      } else {
        makeApiCall(userAuthService.getSessionUser)
          .then((respUser) => setUser(respUser))
          .catch((error: Error) => {
            if (isValidUrl(error.message)) {
              window.location.href = error.message;
            } else {
              setErrorMessage(error.message);
            }
          });
      }
    }
  }, [user, navigate, setUser, makeApiCall]);

  const loginHandler = () => {
    makeApiCall(userAuthService.loginUser, username, password)
      .then((authUser) => setUser(authUser))
      .catch((error: Error) => {
        if (isValidUrl(error.message)) {
          window.location.href = error.message;
        } else {
          setErrorMessage(error.message);
        }
      });
  };

  useEffect(() => {
    googleApiService.initializeGoogleAuth(googleLoginCallback);
    // eslint-disable-next-line
  }, []);

  const googleLoginCallback = (response: any) => {
    googleApiService.loginCallback(response);
  };

  return (
    <div className="login-page">
      <div className="login">
        <div className="login-title">
          <img src={logo} alt="" />
          <h2>{loginContent.content.title}</h2>
        </div>
        <div className="login-content">
          <UserInput
            id="login-username"
            label="Username"
            type="text"
            text="username"
            onChange={(event) => setUsername(event.target.value)}
          />
          <PasswordInput
            password={password}
            setPassword={setPassword}
            verify={false}
            label="Password"
            text="password"
          />
          {errorMessage && (
            <span className="label-normal danger">{errorMessage}</span>
          )}
          <div className="row">
            <Button
              onClick={() => navigate('/')}
              text="Back"
              label={loginContent.content.cancel_button.label}
            />
            <Button
              onClick={loginHandler}
              text="Log in"
              label={loginContent.content.confirm_button.label}
            />
          </div>

          <div id="googleSigninDiv"></div>
        </div>
        <div className="login-footer">
          {loginContent.footer.links.map((link: Record<string, string>) => (
            <Link key={`link-${link.text}`} to={link.path}>
              {link.text}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
