import localForage from 'localforage';

class CachingEngine {
  public static async setData(key: string, data: any): Promise<boolean> {
    return localForage
      .setItem(key, data)
      .then(() => {
        console.log('Data has been saved!');
        return true;
      })
      .catch((err: any) => {
        console.error('An error occurred:', err);
        return false;
      });
  }

  public static async getData(key: string): Promise<any> {
    return localForage
      .getItem(key)
      .then((value: any) => {
        // console.log('Retrieved data:', value);
        return value;
      })
      .catch((err: any) => {
        console.error('An error occurred:', err);
        return null;
      });
  }

  public static async clearCache(): Promise<boolean> {
    return localForage
      .clear()
      .then(() => {
        console.log('cache cleared');
        return true;
      })
      .catch((err: any) => {
        console.error('An error occurred:', err);
        return false;
      });
  }
}

export default CachingEngine;
