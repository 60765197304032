import React from 'react'

import 'view/style/home/privacy.css';

export const PrivacyPolicy: React.FC = () => {
  return (
    <div className='app-main-content'>
      <h1>Orator Privacy Policy</h1>
      <div className='classroom-list'>
        <div className='privacy-policy'>
        <p>Purpose<span style={{fontWeight: 700}}>: Orator Technology Inc takes the protection of our users’ data and information, particularly student data, very seriously. The purpose of this Data Handling and Privacy Statement is to inform our customers about our current data security policies and practices, which are intended to safeguard this sensitive information. Orator Technology handles customer data in a manner consistent with applicable laws and regulations, including, without limitation, the Federal Family Educational Rights and Privacy Act (FERPA), the California Student Online Personal Information Protection Act (SOPIPA), the Children’s Online Privacy Protection Act (COPPA), the California Consumer Privacy Act, and other state student data privacy protection laws. </span></p>
        <p>&nbsp;</p>
        <p>Scope<span style={{fontWeight: 400}}>: This policy covers the collection, use, and storage of data that is obtained through the use of the products and related services accessible through the use of Orator Technology Inc’s proprietary Orator web app. </span></p>
        <p>&nbsp;</p>
        <p>Student Data Obtained and Collected.<span style={{textDecoration: 'none'}}>&nbsp;Orator Technology Inc receives certain information, which we receive pursuant to the school official exception under FERPA, from its school district and teacher customers to enable students to use Orator. The following information is generally provided to Orator Technology Inc for each student user of Orator: </span></p>
        <p>• student's first and last name;</p>
        <p>• student school or class enrollment; </p>
        <p>• teacher name; </p>
        <p>• Student and teacher email; </p>
        <p>• and Google classroom access</p>
        <p>&nbsp;</p>
        <p>Note that some of these data fields (such as Google classroom access) are not required for the use of Orator. However, where districts would like to use Google Classroom integration they may choose to provide this information to Orator Technology Inc.</p>
        <p>&nbsp;</p>
        <p>Data We Do Not Collect.<span style={{fontWeight: 400}}>&nbsp;Orator Technology Inc never obtains or collects the following categories of information through the use of Orator: </span></p>
        <p>• user biometric or health data; </p>
        <p>• user geolocation data; </p>
        <p>• social media profile information; or </p>
        <p>• student mailing addresses or phone numbers, or other such “directory” information. </p>
        <p>&nbsp;</p>
        <p>Usage Data<span style={{fontWeight: 400}}>. </span></p>
        <p>When students use Orator, certain assessment results and usage metrics are also created. These results and usage metrics are used by Orator Technology Inc as described below. While teachers and school administrators are able to access student information and related Orator usage data, this information is not made available to other students or the public.</p>
        <p>&nbsp;</p>
        <p>How We Use Student Data.<span style={{fontWeight: 400}}>&nbsp;Orator Technology Inc only uses student data for education-related purposes and to improve teaching and learning, as described in more detail here. We receive this data under the “school official” exception under FERPA: </span></p>
        <p><span>• </span><span style={{fontWeight: 700}}>For Services</span>. Orator Technology Inc only uses student-identifiable data provided by schools and/or school districts to make Orator available to that particular student, and to provide related reports and services to that student’s school and school district and its educators and administrators. Orator Technology Inc uses student data collected from the use of Orator for the purpose of making Orator available to its customers and for improving its content and effectiveness. </p>
        <p><span>• </span><span style={{fontWeight: 700}}>For Reporting</span>. Orator Technology Inc provides reporting capabilities to its educator customers, and these reports are generated based on Orator insight pages. </p>
        <p><span>• </span><span style={{fontWeight: 700}}>For Account Support</span>. Customers’ usage data may also be used on an aggregated basis to allow Orator Technology Inc’s account management, customer service and tech support teams to provide services that meet the specific needs of our educator customers. </p>
        <p><span>• </span><span style={{fontWeight: 700}}>Treatment as PII</span>. Orator Technology Inc treats all student-identifiable data, and any combination of that data, as personally identifiable information, and that data is stored securely as described more fully below. </p>
        <p><span>• </span><span style={{fontWeight: 700}}>&nbsp;No Solicitation of Students</span>. Orator Technology Inc does not solicit personally identifiable information directly from students—all student information is provided by school district customers or created through the use of the Orator web app. Because Orator is only used in the context of school-directed learning, schools are not required to obtain parental consent under COPPA to provide us with this data, although many customers choose to do so to comply with state or local requirements. </p>
        <p><span>• </span><span style={{fontWeight: 700}}>No Ownership</span><span>. Orator Technology Inc does not</span>&nbsp;obtain any ownership interest in student-identifiable data. </p>
        <p>&nbsp;</p>
        <p>How We Use De-Identified Data<span style={{fontWeight: 400}}>. </span></p>
        <p>• Orator Technology Inc collects and uses “de-identified student data”, which refers to data generated from usage of Orator from which all personally identifiable information has been removed or obscured so that it does not identify individual students and there is no reasonable basis to believe that the information can be used to identify individual students. </p>
        <p>• Orator Technology Inc uses this aggregated, de-identified student data for core product functionality to make Orator a more effective, adaptive product. </p>
        <p>• Orator Technology Inc uses de-identified data to provide services to our educator customers. We sometimes use third party software tools (such as Salesforce or Domo) to enhance the level of service we provide. However, we only use de-identified data with these tools. </p>
        <p>• Orator Technology Inc also uses de-identified student and educator data for research and development purposes. This might include research analyzing the efficacy of Orator or development efforts related to our product and service offerings. We also conduct research using de-identified data for studies focused on improving educational systems and student outcomes more generally. </p>
        <p>• Orator Technology Inc does not attempt to re-identify de-identified student data and takes reasonable measures to protect against the re-identification of its de-identified student data. </p>
        <p>• Orator Technology Inc does not sell student-identifiable data or aggregated de-identified student or educator data to third parties. </p>
        <p>&nbsp;</p>
        <p>No Targeted Advertisements or Marketing.<span style={{fontWeight: 400}}>&nbsp;</span></p>
        <p>• Orator Technology Inc does not include advertisements or marketing messages within Orator nor does it use student data for targeted advertising or marketing. </p>
        <p>• No student data collected in connection with Orator usage is shared with third parties for any advertising, marketing, or tracking purposes. </p>
        <p>No User Interactions. </p>
        <p>• There are no social interactions between users in Orator, and a given user’s account is not accessible to other student users or third parties. Thus there is no opportunity for cyberbullying within Orator. </p>
        <p>• Students cannot create content within Orator </p>
        <p><span>• Orator user information does not involve the creation of a profile, and cannot be shared for social purposes. Student Privacy Pledge. To further demonstrate its commitment to protecting the privacy of student information, Orator Technology Inc is going to take the Student Privacy Pledge </span><a href="https://www.google.com/url?q=https://studentprivacypledge.org/&amp;sa=D&amp;source=editors&amp;ust=1706717851042678&amp;usg=AOvVaw0AMORKiAAHXeeoj-5WshuT">https://studentprivacypledge.org/</a>. This means that, among other things, Orator Inc will pledge &nbsp;not to sell student information, not to engage in behaviorally targeted advertising, and to use collected data for authorized purposes only. Orator Technology Inc only uses collected student data for the purposes described in the “How We Use Student Data” paragraph. </p>
        <p>&nbsp;</p>
        <p>How We Use Educator Data<span style={{fontWeight: 400}}>. Orator Technology Inc also collects the following information about educators that use the Orator web app: name, school or district affiliation, grade level teaching, IP address, and email address. Orator Technology Inc uses this information for account registration and maintenance purposes. Orator Technology Inc also records when educator account logins are created, and when educators log in and out of the Orator web app. We may also use de-identified educator data to improve our product and service offerings, as described in the “How We Use De-Identified Data” section above.</span></p>
        <p>&nbsp;</p>
        <p>&nbsp;Data Storage Location.<span style={{fontWeight: 400}}>&nbsp;</span></p>
        <p>• Orator is a cloud-based application. </p>
        <p>• Our servers are located in Tier 1 data centers located in the United States. </p>
        <p>• We do not store any student data outside of the US. </p>
        <p>&nbsp;</p>
        <p>Network-Level Security Measures.<span style={{fontWeight: 400}}>&nbsp;</span></p>
        <p>• Orator Technology Inc’s Orator web app and servers are hosted in a cloud environment. </p>
        <p>• Our hosting provider implements network-level security measures in accordance with industry standards. </p>
        <p>• Orator Technology Inc manages its own controls of the network environment. </p>
        <p>&nbsp;</p>
        <p>Server-Level Security Measures.<span style={{fontWeight: 400}}>&nbsp;</span></p>
        <p>• Access to production servers is limited to a small, identified group of founders </p>
        <p>• The servers are configured to conduct daily updates for any security patches that are released and applicable. </p>
        <p>• The servers have antivirus protection, intrusion detection, configuration control, monitoring/alerting, and automated backups. </p>
        <p>• Orator Technology Inc conducts regular vulnerability testing. </p>
        <p>&nbsp;</p>
        <p>Computer/Laptop/Device Security Measures.<span style={{fontWeight: 400}}>&nbsp;Laptops are encrypted and centrally managed with respect to configuration updates and anti-virus protection. Access to all Orator Technology Inc computers and laptops is password-controlled. Orator Technology Inc sets up teacher and administrator accounts for Orator so that they are also password-controlled. We support customers that use single sign on (SSO) technology for accessing Orator. </span></p>
        <p>&nbsp;</p>
        <p>Encryption.<span style={{fontWeight: 400}}>&nbsp;</span></p>
        <p>• Orator is only accessible via https and all public network traffic is encrypted with the latest encryption standards. </p>
        <p>• Encryption of data at rest is implemented for all data stored in the Orator system. Employee and Contractor Policies and Procedures. </p>
        <p>&nbsp;</p>
        <p>Data Retention and Destruction.<span style={{fontWeight: 400}}>&nbsp;Student and teacher personal data is used only in the production systems and only for the explicitly identified functions of the Orator web app. Student and teacher personal data is de-identified before any testing or research activities may be conducted. Orator Technology Inc reserves the right, in its sole discretion, to remove a particular customer’s student data from its production servers a reasonable period of time after its relationship with the customer has ended, as demonstrated by the end of contract term or a significant period of inactivity in all customer accounts. Student data is removed from backups in accordance with Orator Technology Inc’s data retention practices. </span></p>
        <p>&nbsp;</p>
        <p>Correction and Removal of Student Data.<span style={{fontWeight: 400}}>&nbsp;</span></p>
        <p>• Parents of students, guardians, or eligible students who use Orator may request correction or removal of the student’s personally identifiable data from Orator by contacting their student’s teacher or school administrator. The teacher or school administrator can then verify the identity of the requesting party and notify Orator Technology Inc of the request. </p>
        <p>• Orator Technology Inc will promptly comply with valid requests for correction or removal of student data; however, removal of student personally identifiable data will limit that student’s ability to use Orator. </p>
        <p>&nbsp;</p>
        <p>Breach Notification.<span style={{fontWeight: 400}}>&nbsp;In the event of a data security breach, Orator will notify impacted customers as promptly as possible that a breach has occurred, and will inform them (to the extent known) what data has been compromised. Orator Technology Inc expects customers to notify individual teachers and parents of any such breach to the extent required, but will provide customers reasonably requested assistance with such notifications </span></p>
        <p>&nbsp;</p>
        <p>Data Collection and Handling Practices for Orator’s “Public Catalog”.</p>
        <p>The Public Catalog is a collection of readings that have been previously assigned by other educators who have opted to make their assignment “publicly available”. The readings from these publicly available assignments will be visible to all educators and available for use when creating assignments. Orator Technology Inc has the right to remove or edit publicly made assignments at any time. </p>
        <p>&nbsp;</p>
        <p>Opt-In Google Classroom Assignment Feature for Orator.<span style={{fontWeight: 400}}>&nbsp;For districts that use Google Classroom, Orator Technology Inc offers educators the ability to easily assign student-facing content from the Educator Resource Materials to their students through Google Classroom. If an educator elects to utilize this feature, Google Classroom will provide Orator Technology Inc with the educator’s name and email address, as well as the roster information and coursework data for that educator’s classroom. Use of Google Classroom is subject to Google Classroom’s terms of service and privacy policy. </span></p>
        <p>&nbsp;</p>
        <p>Policy Review. Orator Technology Inc reviews this privacy policy on a monthly basis and makes updates from time to time to reflect changes in legal requirements and to provide more clarity to our customers on our practices. </p>
        <p>&nbsp;</p>
        <p><span>If you have any questions about our data-handling practices or this privacy policy, you may reach out to </span><a href="mailto:andrew@orator.us">andrew@oratorlearning.us</a>&nbsp;</p>
        </div>
      </div>
    </div>
  )
}