import Question from './Question';
import QuestionResponse from './QuestionResponse';

export default class StudentQuestion {
  public question: Question;
  public response: QuestionResponse;

  constructor(question: Question, response: QuestionResponse) {
    this.question = question;
    this.response = response;
  }

  static fromStudentQuestion(studentQuestion: StudentQuestion) {
    return new StudentQuestion(
      studentQuestion.question,
      studentQuestion.response,
    );
  }

  static fromServerStudentQuestion(
    serverStudentQuestion: Record<string, any>,
  ): StudentQuestion {
    return new StudentQuestion(
      Question.fromServerQuestion(serverStudentQuestion['question']),
      QuestionResponse.fromServerQuestionResponse(
        serverStudentQuestion['response'],
      ),
    );
  }

  public toJSON(): Record<string, any> {
    return { ...this };
  }
}
