import React, { useState } from 'react';
import User from 'models/User';
import IconButton from 'view/components/buttons/IconButton';
import Classroom from 'models/Classroom';
import NumberSelect from 'view/components/common/NumberSelect';
import InlineValueEditor from 'view/components/common/InlineValueEditor';

import 'view/style/teacher/components/classroom/userInfoDropdownItem.css';

interface UserInfoDropdownItemProps {
  user: User;
  classroom: Classroom;
  onSelect: (userId: string, classroomId: string, readingGroup: number) => void;
}

const UserInfoDropdownItem: React.FC<UserInfoDropdownItemProps> = ({
  user,
  classroom,
  onSelect,
}) => {
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [readingGroup, setReadingGroup] = useState<number>(
    classroom.min_reading_level,
  );

  return (
    <div className="user-info-dropdown-row">
      <div className="user-display-name">
        <span className="label-medium">{user.displayName()}</span>
        <span className="label-small">({user.email})</span>
      </div>
      {!isSelected ? (
        <IconButton
          type="transparent"
          icon="add"
          onClick={() => setIsSelected(true)}
        />
      ) : (
        <InlineValueEditor
          label="Reading group"
          onConfirm={() =>
            onSelect(user.getId(), classroom.getId(), readingGroup)
          }
          onCancel={() => setIsSelected(false)}
          inputElement={
            <NumberSelect
              min={classroom.min_reading_level}
              max={classroom.max_reading_level}
              onChange={setReadingGroup}
            />
          }
        />
      )}
    </div>
  );
};

export default UserInfoDropdownItem;
