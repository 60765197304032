class ScreenReader {
  public static lang: string = 'en-US';

  static setLanguage(lang: string) {
    ScreenReader.lang = lang;
  }

  static speak(word: string, lang?: string): void {
    const utterance = new SpeechSynthesisUtterance(word);

    utterance.lang = lang ?? ScreenReader.lang;

    window.speechSynthesis.speak(utterance);
  }

  static playAudio(audioLink: string): void {
    const audio = new Audio(audioLink);
    audio.play();
  }
}

export default ScreenReader;
