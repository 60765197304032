import React, { createContext, useContext, ReactNode, useState } from 'react';
import Classroom from 'models/Classroom'; 

interface ClassroomContextType {
  classroom: Classroom | null;
  setClassroom: (classroom: Classroom | null) => void;
}

const ClassroomContext = createContext<ClassroomContextType | undefined>(undefined);

export function useClassroomContext() {
  const context = useContext(ClassroomContext);
  if (context === undefined) {
    throw new Error('useClassroomContext must be used within a ClassroomProvider');
  }
  return context;
}

interface ClassroomProviderProps {
  children: ReactNode;
}

export function ClassroomProvider({ children }: ClassroomProviderProps) {
  const [classroom, setClassroom] = useState<Classroom | null>(null);

  return (
    <ClassroomContext.Provider value={{ classroom, setClassroom }}>
      {children}
    </ClassroomContext.Provider>
  );
}
