import TeacherGradebook from 'models/TeacherGradebook';
import React, { createContext, useContext, useState, ReactNode } from 'react';
import useApiCall from './ApiCall';
import teacherService from 'services/teacherService';

interface GradebookContextType {
  gradebook?: TeacherGradebook;
  setGradebook: React.Dispatch<
    React.SetStateAction<TeacherGradebook | undefined>
  >;
  refreshGradebook: (classroomId: string) => void;
}

const GradebookContext = createContext<GradebookContextType | undefined>(
  undefined,
);

export const useGradebook = () => {
  const context = useContext(GradebookContext);
  if (!context) {
    throw new Error('useGradebook must be used within a GradebookProvider');
  }
  return context;
};

export const GradebookProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [gradebook, setGradebook] = useState<TeacherGradebook>();
  const [lastFetched, setLastFetched] = useState<Date>();
  const makeApiCall = useApiCall();

  const refreshGradebook = (classroomId: string) => {
    const now = new Date();
    if (
      !lastFetched ||
      now.getTime() - lastFetched.getTime() > 10 * 60 * 1000
    ) {
      makeApiCall(teacherService.getGradebook, classroomId)
        .then((resp) => setGradebook(resp))
        .catch((err) => alert(err));
      setLastFetched(new Date());
    }
  };

  return (
    <GradebookContext.Provider
      value={{ gradebook, setGradebook, refreshGradebook }}
    >
      {children}
    </GradebookContext.Provider>
  );
};
