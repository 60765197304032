import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import userAuthService from 'services/userAuthService';
import { UserInput } from 'view/components/common/UserInput';
import Button from 'view/components/buttons/Button';
import useApiCall from 'contexts/ApiCall';
import contentService from 'services/contentService';

export const ForgotPasswordPage: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [recoveryEmailSent, setRecoveryEmailSent] = useState<boolean>(false);
  const forgotPasswordContent = contentService.getForgotPasswordPage();
  const navigate = useNavigate();
  const makeApiCall = useApiCall();

  const requestPasswordChangeLinkHandler = () => {
    makeApiCall(userAuthService.requestPasswordChangeLink, email)
      .then((resp) => setRecoveryEmailSent(true))
      .catch((error) => alert(error.message));
  };

  return (
    <div className="login-page">
      <div className="login">
        <h3>{forgotPasswordContent.content.title}</h3>
        <UserInput
          id="email-input"
          type="text"
          label="Email Address"
          onChange={(e) => setEmail(e.target.value)}
        />
        {recoveryEmailSent ? (
          <>
            <span className="label-normal">
              {forgotPasswordContent.content.success_message}
            </span>
            <Button
              text="back"
              onClick={() => navigate('/')}
              label={forgotPasswordContent.content.back_button.label}
            />
          </>
        ) : (
          <div className="row">
            <Button
              text="cancel"
              onClick={() => navigate('/login')}
              label={forgotPasswordContent.content.cancel_button.label}
            />
            <Button
              type="go"
              text="send link"
              onClick={requestPasswordChangeLinkHandler}
              label={forgotPasswordContent.content.confirm_button.label}
            />
          </div>
        )}
      </div>
    </div>
  );
};
