import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from 'contexts/UserContext';
import userAuthService from 'services/userAuthService';
import { LoadingIndicator } from 'view/components/common/LoadingIndicator';

export const LogoutPage: React.FC = () => {
  const { setUser } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    // remove user from react state, remove token
    userAuthService.logoutUser();
    setUser(null);
    navigate('/');
    localStorage.clear();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="app-page-content">
      <LoadingIndicator />
    </div>
  );
};
