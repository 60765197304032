import React, { useEffect, useState } from 'react';
import { UserInput } from 'view/components/common/UserInput';
import Question from 'models/Question';
import ActionButton from 'view/components/buttons/ActionButton';
import MultipleChoiceOption from 'models/MultipleChoiceOption';
import useApiCall from 'contexts/ApiCall';
import { adminService } from 'services/adminService';
import Book from 'models/Book';
import IconButton from 'view/components/buttons/IconButton';

import 'view/style/teacher/components/assignment/questionEditor.css';

const trash: string = require('assets/icons/delete_forever_red.svg').default;

type DefaultQuestion = {
  text: string;
  book: string;
  choices: any[];
};

interface QuestionEditorProps {
  book: Book;
  questions: Question[];
  defaultQuestions?: DefaultQuestion[];
  isAutoGenerateAllowed: boolean;
  setIsAutoGenerateAllowed: (val: boolean) => void;
  onCreateQuestion: (text: string, choices?: any[]) => void;
  onUpdateQuestion: (question: Question) => void;
  onDeleteQuestion: (question: Question) => void;
  onExit: () => void;
}

export const QuestionEditor: React.FC<QuestionEditorProps> = ({
  book,
  questions,
  defaultQuestions,
  isAutoGenerateAllowed,
  setIsAutoGenerateAllowed,
  onCreateQuestion,
  onUpdateQuestion,
  onDeleteQuestion,
  onExit,
}) => {
  const [newQuestionText, setNewQuestionText] = useState<string>('');
  const [editorQuestions, setEditorQuestions] = useState<Question[]>([]);
  const makeApiCall = useApiCall();

  useEffect(() => {
    setEditorQuestions(questions);
  }, [questions]);

  const handleQuestionTextChange = (question: Question, text: string) => {
    const newQuestion = Question.fromServerQuestion({
      ...question,
      question: text,
    });
    setEditorQuestions((prevQuestions) => [
      ...prevQuestions.filter((q) => q.getId() !== question.getId()),
      newQuestion,
    ]);
  };

  const handleCreateQuestion = () => {
    if (newQuestionText === '') return;
    onCreateQuestion(newQuestionText);
    setNewQuestionText('');
  };

  const handleAddQuestionChoice = (question: Question) => {
    const newQuestion = Question.fromServerQuestion({
      ...question,
      choices: [
        ...(question.choices ?? []),
        new MultipleChoiceOption(
          question.choices?.length.toString() ?? '0',
          question.getId(),
          '',
          false,
        ),
      ],
    });
    setEditorQuestions((prevQuestions) => [
      ...prevQuestions.filter((q) => q.getId() !== question.getId()),
      newQuestion,
    ]);
  };

  const handleModifyQuestionChoice = (
    question: Question,
    choice: MultipleChoiceOption,
    text: string,
    correct: boolean,
  ) => {
    const newQuestion = Question.fromServerQuestion({
      ...question,
      choices: [
        ...(question.choices ?? []).filter((c) => c !== choice),
        MultipleChoiceOption.fromServerOption({
          ...choice,
          text: text,
          correct: correct,
        }),
      ],
    });
    setEditorQuestions((prevQuestions) => [
      ...prevQuestions.filter((q) => q.getId() !== question.getId()),
      newQuestion,
    ]);
  };

  const handleDeleteQuestionChoice = (
    question: Question,
    choice: MultipleChoiceOption,
  ) => {
    const newQuestion = Question.fromServerQuestion({
      ...question,
      choices: [...question.choices!.filter((c) => c !== choice)],
    });
    setEditorQuestions((prevQuestions) => [
      ...prevQuestions.filter((q) => q.getId() !== question.getId()),
      newQuestion,
    ]);
  };

  const handleSelectDefaults = () => {
    defaultQuestions?.forEach((dq) => {
      onCreateQuestion(dq.text, dq.choices);
    });
  };

  const handleAutoGenerateQuestions = () => {
    console.log('auto generating');
    makeApiCall(adminService.generateDefaultQuestions, book.getText())
      .then((resp) => {
        if (!resp.questions) {
          console.log(resp);
        } else {
          resp.questions.forEach((q: Record<string, string>) => {
            onCreateQuestion(q.text);
          });
          setIsAutoGenerateAllowed(false);
        }
      })
      .catch((err) => alert(err));
  };

  const handleSave = () => {
    // just resave every question
    editorQuestions.forEach((q) => onUpdateQuestion(q));
    onExit();
  };

  return (
    <div className="question-editor">
      <label className="label-large">Edit Questions</label>
      {editorQuestions.map((_, idx) => {
        const question = editorQuestions.find((q) => q.index === idx + 1);
        if (!question) return null;
        return (
          <>
            <UserInput
              key={`question-${question.index}`}
              id={`question-${question.index}`}
              label={`Question ${question.index}`}
              type="text"
              value={question.question}
              onChange={(event) =>
                handleQuestionTextChange(question, event.target.value)
              }
            />
            {question.choices &&
              question.choices
                .sort((c1, c2) => Number(c1.id) - Number(c2.id))
                .map((choice, choiceIdx) => (
                  <div
                    key={`choice-${choiceIdx}`}
                    className="multiple-choice-option"
                  >
                    <input
                      type="checkbox"
                      checked={choice.correct}
                      onChange={(event) =>
                        handleModifyQuestionChoice(
                          question,
                          choice,
                          choice.text,
                          event.target.checked,
                        )
                      }
                    />
                    <input
                      type="text"
                      value={choice.text}
                      onChange={(event) =>
                        handleModifyQuestionChoice(
                          question,
                          choice,
                          event.target.value,
                          choice.correct,
                        )
                      }
                    />
                    <IconButton
                      icon={trash}
                      type="transparent"
                      onClick={() =>
                        handleDeleteQuestionChoice(question, choice)
                      }
                    />
                  </div>
                ))}
            <div
              key={`question-${question.index}-buttons`}
              className="action-buttons"
            >
              <ActionButton
                type="go"
                onClick={() => handleAddQuestionChoice(question)}
                label="Add choice"
              />
              <ActionButton
                type="edit"
                onClick={() => onUpdateQuestion(question)}
                label="Update"
              />
              <ActionButton
                type="delete"
                onClick={() => onDeleteQuestion(question)}
                label="Delete"
              />
            </div>
          </>
        );
      })}
      <UserInput
        id="add-question"
        label="Add Question"
        type="text"
        value={newQuestionText}
        onChange={(event) => setNewQuestionText(event.target.value)}
      />
      <div className="action-buttons">
        <ActionButton
          type="edit"
          onClick={handleCreateQuestion}
          label="Add Question"
        />
        {defaultQuestions && defaultQuestions.length ? (
          <ActionButton onClick={handleSelectDefaults} label="Show Defaults" />
        ) : null}
        {isAutoGenerateAllowed && (
          <ActionButton
            onClick={handleAutoGenerateQuestions}
            label="Auto Generate"
          />
        )}
        <ActionButton type="go" onClick={handleSave} label="Save changes" />
      </div>
    </div>
  );
};
