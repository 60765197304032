import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Book from 'models/Book';
import { AppPage } from 'view/components/common/AppPage';
import { extractTextFromFiles } from 'utils/fileUtils';
import useApiCall from 'contexts/ApiCall';
import { adminService } from 'services/adminService';
import AdminBookEditor from 'view/components/admin/catalog/BookEditor';

import 'view/style/teacher/assignment.css';

const AdminBookPage: React.FC = () => {
  const [book, setBook] = useState<Book>();
  const [coverImageFile, setCoverImageFile] = useState<File>();
  const [coverImage, setCoverImage] = useState<string>();
  const { bookId } = useParams();
  const makeApiCall = useApiCall();
  const navigate = useNavigate();

  useEffect(() => {
    if (!book) {
      setBook(Book.generateDefault());
    }
  }, [book]);

  useEffect(() => {
    if (bookId) {
      makeApiCall(adminService.getBookById, bookId)
        .then(async (respBook: Book) => {
          await respBook.getHtmlContent();
          setBook(respBook);
        })
        .catch((error) => alert(error.message));
    }
  }, [bookId, makeApiCall]);

  const handleCancel = () => {
    window.history.back();
  };

  const updateBook = (event: any) => {
    if (!book) return;
    setBook((prevBook: any) => {
      return Book.fromServerBook({
        ...prevBook,
        [event.target.id]: event.target.value,
      });
    });
  };

  const handleCreateBook = (removeCover?: boolean) => {
    if (!book) return;
    if (!bookId) {
      makeApiCall(adminService.createBook, book, coverImageFile, removeCover)
        .then((respBook) => {
          navigate(`/admin/catalog`);
        })
        .catch((error) => alert(error.message));
    } else {
      makeApiCall(adminService.updateBook, book, coverImageFile, removeCover)
        .then((respBook) => {
          navigate(`/admin/catalog`);
        })
        .catch((error) => alert(error.message));
    }
  };

  const handleUpdateText = (html_content: string) => {
    if (!book) return;
    setBook((prevBook: any) => {
      return Book.fromServerBook({
        ...prevBook,
        html_content: html_content,
      });
    });
  };

  const handleDeleteBook = () => {
    if (!book) return;
    setBook(undefined);
    makeApiCall(adminService.deleteBook, bookId)
      .then(() => navigate(`/admin/catalog`))
      .catch((error) => {
        alert(error);
        navigate(`/admin/catalog`);
      });
  };

  const handleBookCoverChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const imageAsString = e.target?.result;
        setCoverImage(imageAsString?.toString());
      };

      reader.readAsDataURL(file);
      setCoverImageFile(file);
    }
  };

  const handleInputFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event.target.files) return;
    makeApiCall(extractTextFromFiles, event.target.files)
      .then((respText) =>
        setBook((prev) =>
          Book.fromServerBook({
            ...prev,
            html_content: respText,
          }),
        ),
      )
      .catch((error) => alert(error.message));
  };

  if (!book) return <AppPage />;

  return (
    <AppPage>
      {book && (
        <AdminBookEditor
          book={book}
          coverImage={coverImage}
          updateBook={updateBook}
          setBook={setBook}
          handleInputFileChange={handleInputFileChange}
          handleBookCoverChange={handleBookCoverChange}
          handleCreateBook={handleCreateBook}
          handleDeleteBook={handleDeleteBook}
          handleUpdateText={handleUpdateText}
          onCancel={handleCancel}
        />
      )}
    </AppPage>
  );
};

export default AdminBookPage;
