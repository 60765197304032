import axios, { AxiosInstance } from 'axios';
import { BASE_API_PATH, BASE_SERVER_PATH } from 'constants/apiConstants';

class APIService {
  public api: AxiosInstance;
  public server: AxiosInstance;
  public is_authenticated: boolean;

  constructor() {
    this.server = axios.create({ baseURL: BASE_SERVER_PATH });
    this.api = axios.create({ baseURL: BASE_API_PATH });
    this.is_authenticated = false;
  }

  authenticateApi(authToken: string) {
    this.api = axios.create({
      baseURL: BASE_API_PATH,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });
    this.is_authenticated = true;
  }

  resetApi() {
    this.api = axios.create({ baseURL: BASE_API_PATH });
    this.is_authenticated = false;
  }
}

const apiService = new APIService();
export default apiService;
