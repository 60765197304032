import React from 'react';
import contentService from 'services/contentService';
import dictionaryService from 'services/dictionaryService';
import { AudioAssistanceButton } from '../buttons/AudioAssistanceButton';

const language: string = require('assets/icons/language.svg').default;

const LanguageSelector: React.FC = () => {
  const pageContent = contentService.getAssignmentSubmissionPage();

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    console.log(`changing to ${event.target.value}`);
    const lang = event.target.value;
    const langName = lang.split('~')[0];
    const langCode = lang.split('~')[1];
    // ScreenReader.setLanguage(langCode);
    dictionaryService.setLanguage({ name: langName, code: langCode });
  };

  return (
    <>
      <div className="row spaced">
        <div className="row">
          <img src={language} alt="" />
          <label className="label-small">
            {pageContent.sidebar.language.label}
          </label>
          <AudioAssistanceButton text={pageContent.sidebar.language.label} />
        </div>
      </div>
      <select
        id="dictionary-language-select"
        defaultValue={`${dictionaryService.language.name}~${dictionaryService.language.code}`}
        onChange={handleLanguageChange}
      >
        {pageContent.sidebar.language.options.map(
          (langOption: Record<string, string>) => (
            <option
              key={`lang-${langOption.name}`}
              value={`${langOption.name}~${langOption.code}`}
            >
              {langOption.name}
            </option>
          ),
        )}
      </select>
    </>
  );
};

export default LanguageSelector;
