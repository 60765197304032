import Assignment from './Assignment';
import AssignmentSubmission from './AssignmentSubmission';
import StudentProfile from './StudentProfile';

export default class TeacherGradebookEntry {
  public username: string;
  public first_name: string;
  public last_name: string;
  public email: string;
  public student_profile: StudentProfile;
  public assignment_submissions: AssignmentSubmission[];
  public averages: Record<string, number>;

  constructor(
    username: string,
    first_name: string,
    last_name: string,
    email: string,
    student_profile: StudentProfile,
    assignment_submissions: AssignmentSubmission[],
    averages: Record<string, number>,
  ) {
    this.username = username;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.student_profile = student_profile;
    this.assignment_submissions = assignment_submissions;
    this.averages = averages;
  }

  static fromServerEntry(
    serverEntry: Record<string, any>,
    assignments: Assignment[],
  ): TeacherGradebookEntry | null {
    try {
      const assignment_submissions = serverEntry.assignment_submissions
        .map((serverAssignmentSubmission: Record<string, any>) =>
          AssignmentSubmission.fromServerAssignmentSubmission(
            serverAssignmentSubmission,
          ),
        )
        .sort((a1: AssignmentSubmission, a2: AssignmentSubmission) => {
          const assignment1 = assignments.find(
            (asgn) => Number(asgn.id) === Number(a1.assignment),
          ) as Assignment;
          const assignment2 = assignments.find(
            (asgn) => Number(asgn.id) === Number(a2.assignment),
          ) as Assignment;
          const dueDate1 = new Date(assignment1.due_date);
          const dueDate2 = new Date(assignment2.due_date);

          return dueDate2.getTime() - dueDate1.getTime();
        });
      const student_profile = StudentProfile.fromServerStudentProfile(
        serverEntry.student_profile,
      );
      return new TeacherGradebookEntry(
        serverEntry.username,
        serverEntry.first_name,
        serverEntry.last_name,
        serverEntry.email,
        student_profile,
        assignment_submissions,
        serverEntry.averages,
      );
    } catch (error) {
      console.error(error);
      console.error(
        `Failed to load server entry: ${JSON.stringify(serverEntry)}`,
      );
      return null;
    }
  }

  displayName() {
    return `${this.first_name} ${this.last_name}`;
  }
}
