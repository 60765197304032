import React from 'react';

import 'view/style/common/components/wordList.css';

type WordListType = 'edit' | 'interactive';

interface WordListProps {
  type?: WordListType;
  words: string[];
  onDeleteWord?: (word: string) => void;
  onClickWord?: (word: string) => void;
}

export const WordList: React.FC<WordListProps> = ({
  type = 'edit',
  words,
  onDeleteWord,
  onClickWord,
}) => {
  return (
    <div className="word-list">
      {words.map((word) => (
        <div key={`word-list-${word}`} className="word">
          {type === 'edit' && (
            <EditableWord word={word} onDeleteWord={onDeleteWord} />
          )}
          {type === 'interactive' && (
            <ClickableWord word={word} onClickWord={onClickWord} />
          )}
        </div>
      ))}
    </div>
  );
};

interface EditableWordProps {
  word: string;
  onDeleteWord?: (word: string) => void;
}

const EditableWord: React.FC<EditableWordProps> = ({ word, onDeleteWord }) => {
  const handleDeleteWord = () => {
    if (onDeleteWord) onDeleteWord(word);
  };

  return (
    <div key={`word-list-${word}`} className="word">
      <span className="label-small">{word}</span>
      {onDeleteWord !== undefined && (
        <span className="label-normal x" onClick={handleDeleteWord}>
          x
        </span>
      )}
    </div>
  );
};

interface ClickableWordProps {
  word: string;
  onClickWord?: (word: string) => void;
}

const ClickableWord: React.FC<ClickableWordProps> = ({ word, onClickWord }) => {
  const handleClickWord = () => {
    if (onClickWord) onClickWord(word);
  };

  return (
    <div key={`word-list-${word}`} className="word clickable">
      <span className="label-small" onClick={handleClickWord}>
        {word}
      </span>
    </div>
  );
};
