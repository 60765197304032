import React, { useState } from "react";
import ActionButton from "view/components/buttons/ActionButton";
import { UserInput } from "view/components/common/UserInput";
import { useUserContext } from "contexts/UserContext";
import Modal from "view/components/common/Modal";
import { PasswordChangeForm } from "view/components/profile/PasswordChangeForm";
import userService from "services/userService";
import User from "models/User";
import { DeleteConfirmation } from "view/components/common/DeleteConfirmation";
import { useNavigate } from "react-router-dom";
import useApiCall from "contexts/ApiCall";

const lock: string = require('assets/icons/lock.svg').default;

interface ProfileFormProps {}

export const ProfileForm: React.FC<ProfileFormProps> = () => {

  const {user, setUser} = useUserContext();
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<any>(undefined);
  const navigate = useNavigate();
  const makeApiCall = useApiCall();

  if (!user) return null;

  const handleProfileValueChange = (event: any) => {
    if (user.toJSON()[event.target.id] === event.target.value) return;
    setErrorMsg('');
    makeApiCall(
      userService.updateUser, 
      User.fromServerUser({
        ...user,
        [event.target.id]: event.target.value 
      })
    )
      .then(respUser => setUser(respUser))
      .catch(error => setErrorMsg(error.message))
  }

  const handleDeleteAccount = () => {
    makeApiCall(userService.deleteUser, user?.getId())
      .then(() => navigate('/logout'))
      .catch(err => alert(err.message));
  }

  return (
    <div className='app-main-content'>
      <h1>My Information</h1>
      <div className='classroom-list'>
        <UserInput 
          id='first_name'
          label='First Name'
          type='text'
          value={user?.first_name}
          onBlur={handleProfileValueChange}
        />
        <UserInput 
          id='last_name'
          label='Last Name'
          type='text'
          value={user?.last_name}
          onBlur={handleProfileValueChange}
        />
        <UserInput 
          id='username'
          label='Username'
          type='text'
          value={user?.username}
          readonly={true}
          inlineLabel={<img src={lock} alt=''/>}
        />
        <UserInput 
          id='email'
          label='Email'
          type='text'
          value={user?.email}
          onBlur={handleProfileValueChange}
        />
        <UserInput 
          id='organization'
          label='Organization'
          type='text'
          value={user?.organization || 'None'}
          readonly={true}
          inlineLabel={<img src={lock} alt=''/>}
        />
        <UserInput 
          id='password'
          label='Password'
          type='password'
          value='abcdefgh'
          readonly={true}
          onClick={() => setShowChangePassword(true)}
        />
        { errorMsg && 
          <span className='label-normal danger'>
            { errorMsg }
          </span> 
        }
        <br/>
        { user.account_type !== 'Student' &&
          <ActionButton 
            type='delete' 
            onClick={() => setShowDeleteAccount(true)}
            label='Delete my account'
          />
        }
      </div>
      <Modal isOpen={showChangePassword} onClose={() => setShowChangePassword(false)}>
        <PasswordChangeForm onExit={() => setShowChangePassword(false)}/> 
      </Modal>
      <Modal isOpen={showDeleteAccount} onClose={() => setShowDeleteAccount(false)}>
        <DeleteConfirmation 
          warning='This action is permanent, your data cannot be recovered'
          onDelete={handleDeleteAccount}
          onExit={() => setShowDeleteAccount(false)}
        /> 
      </Modal>
    </div>
  );
}