import React, { ReactNode, useEffect, useState } from 'react';
import { VersionTag } from './VersionTag';
import User from 'models/User';
import IconButton from 'view/components/buttons/IconButton';
import Button from 'view/components/buttons/Button';
import contentService from 'services/contentService';

import 'view/style/common/components/sidebar.css';
import { useSidebarState } from 'contexts/SidebarStateContext';

const wormy_teacher: string = require('assets/images/logos/wormy-apple.png');
const wormy_student: string = require('assets/images/logos/wormy-book.png');

interface HomeSidebarProps {
  user: User;
  isCollapsed?: boolean;
}

const HomeSidebar: React.FC<HomeSidebarProps> = ({ user, isCollapsed }) => {
  if (!user) return null;
  const formData = contentService.getCommonForms().home_sidebar;
  const logo = user.account_type === 'Student' ? wormy_student : wormy_teacher;

  return (
    <div className={`app-sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="welcome-panel">
        <img src={logo} alt="" />
        <h1>
          {formData.welcome_panel.title}, {user.first_name}!
        </h1>
      </div>
      <VersionTag />
    </div>
  );
};

interface ClassroomSidebarProps {
  isCollapsed?: boolean;
  children?: ReactNode;
  onExit: () => void;
  onViewSettings?: () => void;
}

const arrow_back: string = require('assets/icons/arrow_back.svg').default;

const ClassroomSidebar: React.FC<ClassroomSidebarProps> = ({
  isCollapsed,
  children,
  onExit,
  onViewSettings,
}) => {
  return (
    <div className={`app-sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="col">
        <Button onClick={onExit} leadIcon={arrow_back} label="Back" />
        <div className="sidebar-content">{children}</div>
      </div>
      <div className="row">
        <VersionTag />
        {onViewSettings && (
          <IconButton icon="settings" onClick={onViewSettings} />
        )}
      </div>
    </div>
  );
};

interface AppSidebarProps {
  children?: ReactNode;
}

type SidebarProps = AppSidebarProps &
  (HomeSidebarProps | ClassroomSidebarProps);

const AppSidebar: React.FC<SidebarProps> = (props) => {
  const [sidebarComponent, setSidebarComponent] = useState<ReactNode | null>(
    null,
  );
  const { isCollapsed, setIsCollapsed } = useSidebarState();

  const isHomeSidebarProps = (
    props: SidebarProps,
  ): props is AppSidebarProps & HomeSidebarProps => {
    return (props as HomeSidebarProps).user !== undefined;
  };

  const isClassroomSidebarProps = (
    props: SidebarProps,
  ): props is AppSidebarProps & ClassroomSidebarProps => {
    return (props as ClassroomSidebarProps).onExit !== undefined;
  };

  useEffect(() => {
    if (isHomeSidebarProps(props)) {
      setSidebarComponent(
        <HomeSidebar user={props.user} isCollapsed={isCollapsed} />,
      );
    } else if (isClassroomSidebarProps(props)) {
      setSidebarComponent(
        <ClassroomSidebar
          children={props.children}
          onExit={props.onExit}
          onViewSettings={props.onViewSettings}
          isCollapsed={isCollapsed}
        />,
      );
    } else {
      setSidebarComponent(null);
    }
  }, [props, isCollapsed]);

  const handleExpandButtonClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="sidebar-container">
      <button className="expand-button" onClick={handleExpandButtonClick}>
        {isCollapsed ? '>' : '<'}
      </button>
      {sidebarComponent}
    </div>
  );
};

export default AppSidebar;
