export const DB_HEADERS = (token: string) => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Token ${token}`,
  };
};

export const GET = 'GET';
export const POST = 'POST';
export const DELETE = 'DELETE';
export const PUT = 'PUT';
export const PATCH = 'PATCH';
export const UPDATE = 'UPDATE';
export const NODE_ENV = process.env.NODE_ENV as string;
const BASE_SERVER_PATH_OPTIONS: Record<string, string> = {
  development: 'http://localhost:8000',
  production: 'https://server.oratorlearning.us',
};
export const BASE_SERVER_PATH =
  BASE_SERVER_PATH_OPTIONS[NODE_ENV] || BASE_SERVER_PATH_OPTIONS['development'];
export const BASE_API_PATH = `${BASE_SERVER_PATH}/api`;

// google vars
export const GOOGLE_REDIRECT_URI = `${BASE_API_PATH}/google/oauth2-callback/`;
export const GOOGLE_CLIENT_ID =
  '955184463000-2mrj5mpt1tqd9rk9ivc9l9v28mvh71sc.apps.googleusercontent.com';
export const SCOPES = [
  'https://www.googleapis.com/auth/classroom.courses.readonly',
  'https://www.googleapis.com/auth/classroom.coursework.students',
  'https://www.googleapis.com/auth/classroom.rosters.readonly',
  'https://www.googleapis.com/auth/classroom.coursework.me',
  'https://www.googleapis.com/auth/userinfo.email',
  // 'https://www.googleapis.com/auth/classroom.profile.emails',
].join(' ');
export const DISCOVERY_DOC = 'https://classroom.googleapis.com/$discovery/rest';

// stripe vars
export const STRIPE_TEST_PUBLIC_KEY =
  'pk_test_51OX8LzLQWtCV2sPlp0slKwdLIqRWyW9ddtAUzZb6YWX2kS08Th66G4y4hIrGyLv2ZVWsALf5CU1VBCjDC9zSHOKp00pR7SSlur';
export const STRIPE_PUBLIC_KEY =
  'pk_live_51OX8LzLQWtCV2sPlJgMKHViAsG7OsaQNPQRoyPX10lUhcWTFZPGKPni8YR84Qd6TMzjUQQ20jL5S8l0hhGiuIhcV007To5ASBk';
