import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from 'services/userAuthService';
import googleApiService from 'services/googleAPIService';
import useApiCall from 'contexts/ApiCall';
import MultiPageForm from 'view/components/common/MultiPageForm';
import AccountTypeForm from 'view/components/auth/signup/AccountTypeForm';
import ProfileInformationForm from 'view/components/auth/signup/ProfileInformationForm';
import { FormValidationProvider } from 'contexts/FormValidationContext';
import StudentSubscriptionForm from 'view/components/auth/signup/StudentSubscriptionForm';
import { FormProvider } from 'contexts/FormContext';
import { SignupFormData } from 'utils/types';
import contentService from 'services/contentService';

const logo: string = require('assets/images/logos/official_logo.png');

const initialFormData: SignupFormData = {
  account_type: '',
  username: '',
  password: '',
  first_name: '',
  last_name: '',
  email: '',
  subscription: undefined,
  organization: undefined,
};

export const SignupPage: React.FC = () => {
  const [userId, setUserId] = useState<string>();
  const [accountType, setAccountType] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const signupContent = contentService.getSignupPage();
  const navigate = useNavigate();
  const makeApiCall = useApiCall();

  useEffect(() => {
    googleApiService.initializeGoogleAuth(googleSignupCallback);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCurrentPage(0);
  }, [userId]);

  const googleSignupCallback = async (response: any) => {
    googleApiService.loginCallback(response);
  };

  const getPages = () => {
    let commonPages: ReactNode[] = [];

    if (!userId) {
      commonPages = [
        <AccountTypeForm setAccountType={setAccountType} />,
        <ProfileInformationForm />,
      ];
    }

    if (accountType === 'Student') {
      return [...commonPages, <StudentSubscriptionForm />];
    } else if (accountType === 'Teacher') {
      // return [...commonPages, <FreeTrialForm />];
      return commonPages;
    }

    return commonPages;
  };

  const getPageCallbacks = () => {
    let commonCallbacks: any = [];

    if (!userId) {
      commonCallbacks = [undefined, registerHandler];
    }

    if (accountType === 'Student') {
      return [...commonCallbacks, joinOrganizationHandler];
    } else if (accountType === 'Teacher') {
      return [...commonCallbacks];
    }

    return commonCallbacks;
  };

  const joinOrganizationHandler = (formData: any): Promise<void> => {
    return new Promise((resolve, reject) => {
      makeApiCall(authService.joinOrganization, formData, userId)
        .then((resp) => {
          navigate(`/login?token=${resp.token}`);
          resolve();
        })
        .catch((error) => reject(error));
    });
  };

  const registerHandler = (formData: SignupFormData) => {
    return new Promise((resolve, reject) => {
      makeApiCall(authService.registerUser, formData)
        .then((respUser) => {
          setUserId(respUser.getId());
          if (respUser.account_type !== 'Student') {
            navigate(
              `/login?accessToken=${respUser.token}&refreshToken=${respUser.refresh_token}`,
            );
          }
          resolve({});
        })
        .catch((error) => reject(error));
    });
  };

  return (
    <div className="login-page">
      <div className="login">
        <div className="login-title">
          <img src={logo} alt="" />
          <h2>{signupContent.title}</h2>
        </div>
        <FormProvider<SignupFormData> initialFormData={initialFormData}>
          <FormValidationProvider>
            <MultiPageForm
              pages={getPages()}
              pageSubmitCallbacks={getPageCallbacks()}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              onSubmit={() => {}}
              onExit={() => navigate('/')}
            />
          </FormValidationProvider>
        </FormProvider>
      </div>
    </div>
  );
};
