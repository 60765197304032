// generatePdf.ts
import AssignmentSubmission from 'models/AssignmentSubmission';
import TeacherGradebook from 'models/TeacherGradebook';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import type { ChartData, ChartOptions, ScatterDataPoint } from 'chart.js';
import { generateChartImage } from './chartUtils';
import contentService from 'services/contentService';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const generatePdf = async (
  data: TeacherGradebook,
  studentIndex: number,
  language: string,
) => {
  const content = contentService.getProgressReportContent(language);
  console.log(content);
  const student = data.students[studentIndex];
  const classAverages = data.averages;
  const studentName = `${student.first_name} ${student.last_name}`;

  // create the snapshot bar chart
  const snapshotChartData: ChartData<'bar'> = {
    labels: content.snapshotChart.labels, //['Grade', 'Completion', 'Accuracy', 'Key Word Accuracy'],
    datasets: [
      {
        label: studentName,
        data: [
          student.averages.grade,
          student.averages.completion_score,
          student.averages.correctness_score,
          student.averages.key_word_accuracy_score,
        ],
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: content.snapshotChart.datasetLabel, // 'Class averages',
        data: [
          classAverages.grade,
          classAverages.completion_score,
          classAverages.correctness_score,
          classAverages.key_word_accuracy_score,
        ],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  const snapshotChartOptions: ChartOptions<'bar'> = {
    scales: {
      x: {
        type: 'category',
        labels: snapshotChartData.labels as string[],
      },
      y: {
        type: 'linear',
        beginAtZero: true,
      },
    },
  };

  const base64SnapshotChartImage = await generateChartImage(
    snapshotChartData,
    snapshotChartOptions,
    'bar',
  );

  // create the temporal graph (how the student has improved in each metric over each month)
  // start off by going through the student gradebook, and getting an average for each metric
  // for each month
  const temporalStudentData: Record<string, Record<string, number>> = {};
  student.assignment_submissions.forEach((sa) => {
    const submittedDate = sa.date_completed;
    if (!submittedDate || submittedDate === null) return;
    const dateIdentifier = submittedDate.substring(0, 7);
    if (dateIdentifier in temporalStudentData) {
      const numEntries = temporalStudentData[dateIdentifier].numEntries;
      temporalStudentData[dateIdentifier].grade =
        (temporalStudentData[dateIdentifier].grade * numEntries + sa.grade) /
        (numEntries + 1);
      temporalStudentData[dateIdentifier].completion_score =
        (temporalStudentData[dateIdentifier].completion_score * numEntries +
          sa.completion_score) /
        (numEntries + 1);
      temporalStudentData[dateIdentifier].correctness_score =
        (temporalStudentData[dateIdentifier].correctness_score * numEntries +
          sa.correctness_score) /
        (numEntries + 1);
      temporalStudentData[dateIdentifier].key_word_accuracy_score =
        (temporalStudentData[dateIdentifier].key_word_accuracy_score *
          numEntries +
          sa.key_word_accuracy_score) /
        (numEntries + 1);
      temporalStudentData[dateIdentifier].completion_time =
        (temporalStudentData[dateIdentifier].completion_time * numEntries +
          sa.completion_time) /
        (numEntries + 1);
      temporalStudentData[dateIdentifier].numEntries += 1;
    } else {
      temporalStudentData[dateIdentifier] = {
        grade: sa.grade,
        completion_score: sa.completion_score,
        correctness_score: sa.correctness_score,
        key_word_accuracy_score: sa.key_word_accuracy_score,
        completion_time: sa.completion_time,
        numEntries: 1,
      };
    }
  });

  const temporalChartData: ChartData<'line'> = {
    labels: content.temporalChart.labels, // [Object.keys(temporalStudentData)],
    datasets: [
      {
        label: content.temporalChart.labels[0],
        data: Object.entries(temporalStudentData).map(([key, value]) => {
          return { x: new Date(key).getTime(), y: value.grade };
        }) as ScatterDataPoint[],
        fill: false,
        borderColor: 'blue',
        tension: 0.1,
      },
      {
        label: content.temporalChart.labels[1],
        data: Object.entries(temporalStudentData).map(([key, value]) => {
          return { x: new Date(key).getTime(), y: value.completion_score };
        }) as ScatterDataPoint[],
        fill: false,
        borderColor: 'orange',
        tension: 0.1,
      },
      {
        label: content.temporalChart.labels[2],
        data: Object.entries(temporalStudentData).map(([key, value]) => {
          return { x: new Date(key).getTime(), y: value.correctness_score };
        }) as ScatterDataPoint[],
        fill: false,
        borderColor: 'green',
        tension: 0.1,
      },
      {
        label: content.temporalChart.labels[3],
        data: Object.entries(temporalStudentData).map(([key, value]) => {
          return {
            x: new Date(key).getTime(),
            y: value.key_word_accuracy_score,
          };
        }) as ScatterDataPoint[],
        fill: false,
        borderColor: 'purple',
        tension: 0.1,
      },
    ],
  };

  const temporalChartOptions: ChartOptions<'line'> = {
    scales: {
      x: {
        type: 'category',
        labels: Object.keys(temporalStudentData),
      },
      y: {
        type: 'linear',
        beginAtZero: true,
      },
    },
  };

  const base64TemporalChartImage = await generateChartImage(
    temporalChartData,
    temporalChartOptions,
    'line',
  );

  // reading speed chart
  const readingSpeedChartData: ChartData<'line'> = {
    labels: [
      studentName + ' ' + content.readingSpeedChart.labels[0],
      content.readingSpeedChart.labels[1],
    ], // [`${studentName} reading speed`, 'Class average reading speed'],
    datasets: [
      {
        label: studentName + ' ' + content.readingSpeedChart.labels[0],
        data: Object.entries(temporalStudentData).map(([key, value]) => {
          return { x: new Date(key).getTime(), y: value.completion_time };
        }) as ScatterDataPoint[],
        fill: false,
        borderColor: 'blue',
        tension: 0.1,
      },
      {
        label: content.readingSpeedChart.labels[1],
        data: Object.keys(temporalStudentData).map((key) => {
          return {
            x: new Date(key).getTime(),
            y: classAverages.completion_time,
          };
        }) as ScatterDataPoint[],
        fill: false,
        borderColor: 'red',
        tension: 0.1,
      },
    ],
  };

  const base64ReadingSpeedChartImage = await generateChartImage(
    readingSpeedChartData,
    temporalChartOptions,
    'line',
  );

  // create the gradebook
  // const tableHeaders = [
  //   'Assignment',
  //   'Grade',
  //   'Average Grade',
  //   'Accuracy',
  //   'Average Accuracy',
  //   'Completion',
  //   'Average Completion',
  //   'Key Word Accuracy',
  //   'Average Key Word Accuracy',
  //   'Completion Time',
  //   'Average Completion Time',
  // ];

  const tableHeaders = content.gradebook.headers;

  const studentMetrics = Object.keys(classAverages.assignments)
    .filter(
      (assignmentId: string) =>
        student.assignment_submissions.find(
          (sa) => sa.assignment === assignmentId,
        ) !== undefined,
    )
    .map((assignmentId: string) => {
      const AssignmentSubmission = student.assignment_submissions.find(
        (sa) => sa.assignment === assignmentId,
      ) as AssignmentSubmission;
      const classAssignment = classAverages.assignments[assignmentId];
      return [
        assignmentId,
        AssignmentSubmission.grade.toFixed(2),
        classAssignment.grade.toFixed(2),
        AssignmentSubmission.correctness_score.toFixed(2),
        classAssignment.correctness_score.toFixed(2),
        AssignmentSubmission.completion_score.toFixed(2),
        classAssignment.completion_score.toFixed(2),
        AssignmentSubmission.key_word_accuracy_score.toFixed(2),
        classAssignment.key_word_accuracy_score.toFixed(2),
        AssignmentSubmission.completion_time.toFixed(2),
        classAssignment.completion_time.toFixed(2),
      ];
    });

  const tableBody = [tableHeaders];
  for (let i = 0; i < studentMetrics.length; i++) {
    tableBody.push(studentMetrics[i]);
  }

  const docDefinition: TDocumentDefinitions = {
    pageSize: 'A4',
    pageOrientation: 'landscape', // Set the default orientation for the document
    content: [
      {
        text: `Orator ${content.header} ${studentName}`,
        style: 'header',
        pageOrientation: 'landscape',
      },
      {
        text: content.snapshotChart.header,
        style: 'header2',
        pageOrientation: 'landscape',
      },
      {
        image: base64SnapshotChartImage,
        width: 700,
        pageBreak: 'after',
        pageOrientation: 'landscape',
      },
      {
        text: content.temporalChart.header,
        style: 'header2',
        pageOrientation: 'landscape',
      },
      {
        image: base64TemporalChartImage,
        width: 700,
        pageBreak: 'after',
        pageOrientation: 'landscape',
      },
      {
        text: content.readingSpeedChart.header,
        style: 'header2',
        pageOrientation: 'landscape',
      },
      {
        image: base64ReadingSpeedChartImage,
        width: 700,
        pageBreak: 'after',
        pageOrientation: 'landscape',
      },
      {
        text: content.gradebook.header,
        style: 'header2',
        pageOrientation: 'landscape',
      },
      {
        table: {
          headerRows: 1,
          widths: Array(tableHeaders.length).fill('*'),
          body: tableBody,
        },
        pageOrientation: 'landscape',
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      header2: {
        fontSize: 16,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      tableHeader: {
        bold: true,
        fontSize: 12,
        color: 'black',
      },
    },
    defaultStyle: {
      fontSize: 10,
      bold: false,
    },
    pageMargins: [40, 60, 40, 60],
  };

  pdfMake.createPdf(docDefinition).download(`Orator_Report_${studentName}.pdf`);
};

export default generatePdf;
