import React, { useEffect } from 'react';
import { ElementsConsumer, PaymentElement } from '@stripe/react-stripe-js';
import ActionButton from 'view/components/buttons/ActionButton';
import { useFormValidation } from 'contexts/FormValidationContext';
import contentService from 'services/contentService';

interface CheckoutFormProps {
  stripe: any;
  elements: any;
  onSubmit: () => void;
}

/* Test Card Numbers:

Successful Payment: 4242 4242 4242 4242 (Visa)
Payment Declined: 4000 0000 0000 9995 (Visa)
Incorrect Number: 4242 4242 4241 4241 (Visa)
Expired Card: 4000 0000 0000 0069 (Visa)
CVC Check Failure: 4000 0000 0000 0127 (Visa)

*/

const CheckoutForm: React.FC<CheckoutFormProps> = ({
  stripe,
  elements,
  onSubmit,
}) => {
  const formContent = contentService.getPaymentPage();
  const { setPageValidity } = useFormValidation();

  useEffect(() => {
    setPageValidity(false);
  }, [setPageValidity]);

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: 'https://example.com/order/123/complete',
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.error(result.error.message);
      setPageValidity(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
        onSubmit(); // Handle successful payment in-page
        setPageValidity(true);
      }
    }
  };

  return (
    <form className="login-content" onSubmit={handleSubmit}>
      <PaymentElement />
      <div className="row">
        {/* <ActionButton type='danger' onClick={() => navigate('/')}>Cancel</ActionButton> */}
        <ActionButton
          type="edit"
          disabled={!stripe}
          label={formContent.purchase_button.label}
        />
      </div>
    </form>
  );
};

interface InjectedCheckoutFormProps {
  onSubmit: () => void;
}

const InjectedCheckoutForm: React.FC<InjectedCheckoutFormProps> = ({
  onSubmit,
}) => {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} onSubmit={onSubmit} />
      )}
    </ElementsConsumer>
  );
};

export default InjectedCheckoutForm;
