import React, { useState } from 'react';
import { AppPage } from 'view/components/common/AppPage';
import SidebarButton from 'view/components/buttons/SidebarButton';
import { ProfileForm } from 'view/components/profile/ProfileForm';
import { PrivacyPolicy } from 'view/components/common/PrivacyPolicy';
import { TermsOfService } from 'view/components/common/TermsOfService';
import AppSidebar from 'view/components/common/Sidebar';
import contentService from 'services/contentService';

export const StudentProfilePage: React.FC = () => {
  const [activeContent, setActiveContent] = useState(<ProfileForm />);
  const [content] = useState<Record<string, any>>({
    profile: <ProfileForm />,
    privacy: <PrivacyPolicy />,
    terms: <TermsOfService />,
  });
  const pageContent = contentService.getStudentProfilePage();

  const handleToggleContent = (event: React.MouseEvent<HTMLButtonElement>) => {
    const button = event.currentTarget;
    setActiveContent(content[button.id]);
  };

  const handleExit = () => {
    window.history.back();
  };

  return (
    <AppPage>
      <div className="app-page-content">
        <AppSidebar onExit={handleExit}>
          <>
            {pageContent.sidebar.nav.map((navItem: Record<string, any>) => (
              <SidebarButton
                key={navItem.id}
                id={navItem.id}
                type="default"
                onClick={handleToggleContent}
                label={navItem.label}
              />
            ))}
          </>
        </AppSidebar>
        {activeContent}
      </div>
    </AppPage>
  );
};
