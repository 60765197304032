import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import userAuthService from 'services/userAuthService';
import Button from 'view/components/buttons/Button';
import PasswordInput from 'view/components/common/PasswordInput';
import useApiCall from 'contexts/ApiCall';
import contentService from 'services/contentService';

export const PasswordResetPage: React.FC = () => {
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<any>();
  const { uid, token } = useParams();
  const passwordResetContent = contentService.getPasswordResetPage();
  const errorMessages = contentService.getAuthConstants();
  const makeApiCall = useApiCall();
  const navigate = useNavigate();

  if (!uid || !token) {
    navigate('/');
    return null;
  }

  const resetPasswordHandler = () => {
    if (newPassword.length < 8) {
      setErrorMessage(errorMessages.password_validation);
    } else if (newPassword !== confirmPassword) {
      setErrorMessage(errorMessages.password_confirmation);
    } else {
      makeApiCall(userAuthService.resetPassword, uid, token, newPassword)
        .then((resp) => {
          alert(passwordResetContent.content.success_message);
          navigate('/login');
        })
        .catch((error) => setErrorMessage(error.message));
    }
  };

  return (
    <div className="login-page">
      <div className="login">
        <h3>{passwordResetContent.content.title}</h3>
        <PasswordInput
          id="new-password"
          label="New password"
          password={newPassword}
          valid={newPassword.length >= 8}
          setPassword={setNewPassword}
        />
        <PasswordInput
          id="confirm-password"
          label="Confirm new password"
          password={confirmPassword}
          valid={newPassword === confirmPassword && confirmPassword !== ''}
          setPassword={setConfirmPassword}
        />
        {errorMessage && (
          <span className="label-normal danger">{errorMessage}</span>
        )}
        <div className="row">
          <Button
            type="go"
            onClick={resetPasswordHandler}
            label={passwordResetContent.content.confirm_button.label}
          />
          <Button
            onClick={() => navigate('/')}
            label={passwordResetContent.content.cancel_button.label}
          />
        </div>
      </div>
    </div>
  );
};
