import React from 'react';
import { AppPage } from '../../../components/common/AppPage';

export const TeacherHelpPage: React.FC = () => {
  
  return (
    <AppPage>
      <h1>Help</h1>
    </AppPage>
  )
}