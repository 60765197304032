import useApiCall from 'contexts/ApiCall';
import { useUserContext } from 'contexts/UserContext';
import Book from 'models/Book';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { adminService } from 'services/adminService';
import { AdminBookPreview } from 'view/components/admin/catalog/BookPreview';
import AdminQuestionEditor from 'view/components/admin/catalog/QuestionEditor';
import ActionButton from 'view/components/buttons/ActionButton';
import { BookRow } from 'view/components/catalog/BookRow';
import { AppPage } from 'view/components/common/AppPage';
import { BookReader } from 'view/components/common/BookReader';
import Modal from 'view/components/common/Modal';
import { SearchBar } from 'view/components/common/SearchBar';
import { FileInput } from 'view/components/teacher/assignment/FileInputs';

const AdminCatalog: React.FC = () => {
  const { user } = useUserContext();
  const [publicBooks, setPublicBooks] = useState<Book[]>();
  const [reviewBooks, setReviewBooks] = useState<Book[]>();
  const [displayBooks, setDisplayBooks] = useState<Book[]>();
  const [selectedBook, setSelectedBook] = useState<Book | null>(null);
  const [viewText, setViewText] = useState<boolean>(false);
  const [viewQuestions, setViewQuestions] = useState<boolean>(false);
  const navigate = useNavigate();
  const makeApiCall = useApiCall();

  useEffect(() => {
    if (user?.account_type === 'Admin') return;
    navigate('/admin/home');
  }, [user, navigate]);

  useEffect(() => {
    if (publicBooks || reviewBooks) return;
    // get the public books, and books under review from the server
    makeApiCall(adminService.getCatalogBooks)
      .then((resp) => {
        setPublicBooks(resp.public_books);
        setDisplayBooks(resp.public_books);
        setReviewBooks(resp.review_books);
      })
      .catch((err) => alert(err));
  }, [publicBooks, reviewBooks, makeApiCall]);

  const handleSelectBook = async (book: Book) => {
    if (book && (!book.html_content || book.html_content === '')) {
      await book.getHtmlContent();
    }
    setSelectedBook(book);
  };

  const handleBookStatusChange = (book: Book, accept: boolean) => {
    if (book.is_public) {
      if (!accept) {
        setPublicBooks((prev) => [
          ...(prev as Book[]).filter((b) => b.id !== book.id),
        ]);
      }
    } else {
      if (accept) {
        setPublicBooks((prev) => [...(prev as Book[]), book]);
      }
      setReviewBooks((prev) => [
        ...(prev as Book[]).filter((b) => b.id !== book.id),
      ]);
    }

    makeApiCall(adminService.reviewCatalogBook, book, accept)
      .then((resp) => alert('Success'))
      .catch((err) => alert(err));
  };

  const handleMultiBookUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (file && file.type === 'application/json') {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        const text = e.target?.result;
        try {
          const json = JSON.parse(text as string);
          json.books.map((book: Book) =>
            makeApiCall(adminService.createBook, book, null, false, false),
          );
        } catch (error) {
          console.error('Error parsing JSON!', error);
        }
      };

      reader.onerror = () => {
        console.error('Error reading the file');
      };

      reader.readAsText(file);
    } else {
      console.error('Please upload a valid JSON file.');
    }
  };

  const handleSearch = (searchTerm: string) => {
    // Do all filtering of displayed books here
    if (searchTerm === undefined) return;
    const lowerSearchTerm = searchTerm.toLowerCase();
    setDisplayBooks(
      publicBooks?.filter((book) => {
        const titleContainsSearchTerm = book.title
          .toLowerCase()
          .includes(lowerSearchTerm);
        const tagsContainsSearchTerm = book.tags.some((tag) =>
          tag.toLowerCase().includes(lowerSearchTerm),
        );
        return titleContainsSearchTerm || tagsContainsSearchTerm;
      }),
    );
  };

  return (
    <AppPage>
      <div className="app-main-content">
        <div className="row">
          <h2>Admin Catalog</h2>
          <ActionButton
            type="go"
            label="Add Book"
            onClick={() => navigate('/admin/book')}
          />
          <FileInput
            label="Multi-book Upload"
            accept=".json"
            onFileChange={handleMultiBookUpload}
          />
        </div>
        <SearchBar onSearch={handleSearch} />
        <div className="book-catalog-content">
          {displayBooks && (
            <BookRow
              label="Public"
              books={displayBooks}
              onSelect={(book) => handleSelectBook(book)}
            />
          )}
          {reviewBooks && (
            <BookRow
              label="Under Review"
              books={reviewBooks}
              onSelect={(book) => handleSelectBook(book)}
            />
          )}
        </div>
      </div>
      <Modal
        isOpen={selectedBook !== null}
        onClose={() => setSelectedBook(null)}
      >
        <AdminBookPreview
          book={selectedBook as Book}
          onView={() => setViewText(true)}
          onQuestions={() => setViewQuestions(true)}
          onStatusChange={handleBookStatusChange}
          onEdit={(book) => navigate(`/admin/book/${book.id}`)}
        />
      </Modal>
      <Modal
        isOpen={selectedBook !== null && viewText}
        onClose={() => setViewText(false)}
      >
        <BookReader
          book={selectedBook as Book}
          startIndex={0}
          isReading={false}
          showMissedWordPopup={false}
          setIsReading={() => {}}
          onMissedWord={() => {}}
        />
      </Modal>
      <Modal
        isOpen={selectedBook !== null && viewQuestions}
        onClose={() => setViewQuestions(false)}
      >
        <AdminQuestionEditor
          book={selectedBook as Book}
          onExit={() => setViewQuestions(false)}
        />
      </Modal>
    </AppPage>
  );
};

export default AdminCatalog;
