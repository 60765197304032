class Timer {
  private timerId: Record<string, number | undefined>;

  constructor() {
    this.timerId = {};
  }

  timerExists(timerKey: string): boolean {
    return this.timerId[timerKey] !== undefined;
  }

  start(timerKey: string, callback: () => void, duration: number): void {
    this.timerId[timerKey] = window.setTimeout(callback, duration);
  }

  clear(timerKey: string): void {
    if (this.timerId[timerKey]) {
      window.clearTimeout(this.timerId[timerKey]);
      this.timerId[timerKey] = undefined;
    }
  }
}

export default Timer;
