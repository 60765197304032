import React from 'react';

interface VideoPlayerProps {
    src: string;
    altText?: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ src, altText = 'Video playback is not supported' }) => {
  return (
    <video controls>
      <source src={src} type="video/mp4" />
      {altText}
    </video>
  );
};

export default VideoPlayer;