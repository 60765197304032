import React, { ReactNode, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserContext } from 'contexts/UserContext';
import { useClassroomContext } from 'contexts/ClassroomContext';
import userService from 'services/userService';
import userAuthService from 'services/userAuthService';
import useApiCall from 'contexts/ApiCall';
import ScreenReader from 'utils/ScreenReader';
import { SpeechRecognitionService } from 'services/speechRecognitionService';
import { getTokenExpiration } from 'utils/authUtils';
import User from 'models/User';

interface AppPageProps {
  children?: ReactNode;
}

export const AppPage: React.FC<AppPageProps> = ({ children }) => {
  const { user, setUser } = useUserContext();
  const { classroom, setClassroom } = useClassroomContext();
  const { classroomId } = useParams();
  const navigate = useNavigate();
  const makeApiCall = useApiCall();

  useEffect(() => {
    if (user) {
      // We have refresh and access tokens, so check if the access token is expired
      // if it is then log out the user
      // if it is not but it is close (under a certain threshold) get a new one at auth/refresh/
      // Threshold in milliseconds (e.g., 5 minutes = 5 * 60 * 1000 milliseconds)
      const threshold = 5 * 60 * 1000;
      const tokenExpiration = getTokenExpiration(user.token);
      // first check if the time left is within the threshold
      if (tokenExpiration < 0) {
        // logout the user
        window.location.reload();
        navigate('/logout');
      } else if (tokenExpiration <= threshold) {
        // get a new access token with the refresh token
        userAuthService
          .refreshAccessToken(user.refresh_token)
          .then((respToken) =>
            setUser(User.fromServerUser({ ...user, token: respToken })),
          )
          .catch((error) => navigate('/logout'));
      }
      return;
    }
    if (userAuthService.isAuthenticated()) {
      makeApiCall(userAuthService.getSessionUser)
        .then((respUser) => setUser(respUser))
        .catch((error) => navigate('/logout'));
    } else {
      setClassroom(null);
      navigate('/logout');
    }
  }, [user, setUser, navigate, setClassroom, makeApiCall]);

  useEffect(() => {
    if (classroom) {
      ScreenReader.setLanguage(classroom.language);
      SpeechRecognitionService.setLanguage(classroom.language);
      return;
    }
    if (!user || !classroomId) return;
    makeApiCall(userService.getClassroomById, classroomId)
      .then((respClassroom) => setClassroom(respClassroom))
      .catch((error) => navigate(`/${user.account_type.toLowerCase()}/home`));
  }, [user, classroom, classroomId, setClassroom, navigate, makeApiCall]);

  return <div className="app-page">{children}</div>;
};
