import React from 'react';
import { ButtonType } from 'utils/types';

import 'view/style/common/buttons.css';

interface ActionButtonProps {
  type?: ButtonType;
  disabled?: boolean;
  text?: string;
  icon?: string;
  label: string;
  buttonType?: 'button' | 'submit' | 'reset' | undefined;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  type,
  disabled = false,
  text,
  icon,
  label,
  buttonType,
  onClick,
}) => {
  return (
    <button
      className={`action-button ${type || ''}`}
      onClick={onClick}
      disabled={disabled}
      type={buttonType}
    >
      {icon && <img src={icon} alt="" />}
      <span className="label-normal">{label}</span>
    </button>
  );
};

export default ActionButton;
