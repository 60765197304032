import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';

const TranscriptionApp = () => {
  const [isRecording, setIsRecording] = useState(false);
  const isRecordingRef = useRef(isRecording); // Ref to track recording state
  const [transcription, setTranscription] = useState('');
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null,
  );
  const [socket, setSocket] = useState<any>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    isRecordingRef.current = isRecording; // Sync ref with state
  }, [isRecording]);

  useEffect(() => {
    const ws = io('http://localhost:5001', {
      transports: ['websocket'], // forces the transport to use websockets only
    });

    setSocket(ws);

    ws.on('connect', () => {
      console.log('Connected to server');
    });

    ws.on('transcript', (data: any) => {
      console.log(`Transcription received from server: ${data.transcript}`);
      if (data.transcript) {
        setTranscription((prev) => prev + data.transcript + ' ');
      }
    });

    ws.on('error', (error: any) => {
      console.log(`Error from server: ${error.message}`);
    });

    return () => {
      ws.disconnect();
    };
  }, []);

  const handleStartRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const options = { mimeType: 'audio/webm;codecs=opus' };
        const newMediaRecorder = new MediaRecorder(stream, options);

        newMediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0 && socket) {
            const reader = new FileReader();
            reader.onload = () => {
              const buffer = reader.result as ArrayBuffer;
              console.log('Sending audio chunk to server');
              socket.emit('audio', buffer);
            };
            reader.readAsArrayBuffer(event.data);
          }
        };

        newMediaRecorder.onstop = () => {
          console.log('audio stopped');
          if (isRecordingRef.current) {
            console.log('audio starting again');
            handleStartRecording(); // Restart recording immediately
          }
        };

        setIsRecording(true);
        setMediaRecorder(newMediaRecorder);
        newMediaRecorder.start(); // Start recording without an interval
        recordInIntervals(newMediaRecorder);
      })
      .catch((error) => {
        console.error('Error accessing media devices.', error);
      });
  };

  const handleStopRecording = () => {
    if (mediaRecorder) {
      setIsRecording(false);
      mediaRecorder.stop();
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
  };

  const recordInIntervals = (recorder: MediaRecorder) => {
    intervalRef.current = setInterval(() => {
      if (recorder.state === 'recording') {
        recorder.stop(); // This will trigger the onstop event, which restarts recording
      }
    }, 2000); // Adjust interval as needed
  };

  return (
    <div>
      <h1>Live Transcription</h1>
      <button onClick={handleStartRecording} disabled={isRecording}>
        Start Recording
      </button>
      <button onClick={handleStopRecording} disabled={!isRecording}>
        Stop Recording
      </button>
      <p>Transcription: {transcription}</p>
    </div>
  );
};

export default TranscriptionApp;
