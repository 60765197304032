import React from 'react';
import contentService from 'services/contentService';

import 'view/style/home/contact.css';

// const twitter_icon: string =  require('assets/icons/x-twitter.svg').default;
const instagram_icon: string = require('assets/icons/instagram.svg').default;
const facebook_icon: string = require('assets/icons/facebook.svg').default;
const linkedin_icon: string = require('assets/icons/linkedin.svg').default;

export const SOCIAL_LINKS: Record<string, string> = {
  facebook: 'https://www.facebook.com/profile.php?id=100091584858172',
  linkedin: 'https://www.linkedin.com/company/orator-reading/',
  instagram: 'https://www.instagram.com/oratorlearn/',
  // 'twitter': ,
};

export const ContactPage: React.FC = () => {
  const contactContent = contentService.getContactPage();

  const handleSocialClick = (social: string) => {
    window.open(SOCIAL_LINKS[social], '_blank');
  };

  return (
    <div className="app-page" style={{ paddingTop: '70px' }}>
      <div className="contact-page">
        <h1>{contactContent.content.title}</h1>
        <span className="label-medium">{contactContent.content.text}</span>
        {contactContent.content.sections.map((section: Record<string, any>) => (
          <>
            <h2>{section.title}</h2>
            <span className="label-medium">{section.text}</span>
            {section.contacts && (
              <ul>
                {section.contacts.map((contact: string) => (
                  <li key={`contact-${contact}`}>
                    <span className="label-normal">{contact}</span>
                  </li>
                ))}
              </ul>
            )}
          </>
        ))}
        <div className="row">
          <img
            className="social-media-icon"
            src={facebook_icon}
            alt="facebook"
            onClick={() => handleSocialClick('facebook')}
          />
          <img
            className="social-media-icon"
            src={linkedin_icon}
            alt="linkedin"
            onClick={() => handleSocialClick('linkedin')}
          />
          <img
            className="social-media-icon"
            src={instagram_icon}
            alt="instagram"
            onClick={() => handleSocialClick('instagram')}
          />
        </div>
      </div>
    </div>
  );
};
