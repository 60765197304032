import { Subscription } from '../utils/types';

export default class User {
  private readonly id;
  public username: string;
  public first_name: string;
  public last_name: string;
  public email: string;
  public account_type: string;
  public google_access_token: string;
  public token: string;
  public refresh_token: string;
  public last_login: Date;
  public subscription?: Subscription;
  public organization?: string;

  constructor(
    id: string,
    username: string,
    first_name: string,
    last_name: string,
    email: string,
    account_type: string,
    google_access_token: string,
    token: string,
    refresh_token: string,
    last_login: Date,
    subscription?: Subscription,
    organization?: string,
  ) {
    this.id = id;
    this.username = username;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.account_type = account_type;
    this.google_access_token = google_access_token;
    this.token = token;
    this.refresh_token = refresh_token;
    this.last_login = last_login;
    this.subscription = subscription;
    this.organization = organization;
  }

  static fromUser(user: User) {
    return new User(
      user.getId(),
      user.username,
      user.first_name,
      user.last_name,
      user.email,
      user.account_type,
      user.google_access_token,
      user.token,
      user.refresh_token,
      user.last_login,
      user.subscription,
      user.organization,
    );
  }

  static fromServerUser(serverUser: Record<string, any>): User {
    return new User(
      serverUser['id'],
      serverUser['username'],
      serverUser['first_name'],
      serverUser['last_name'],
      serverUser['email'],
      serverUser['account_type'],
      serverUser['google_access_token'],
      serverUser['token'],
      serverUser['refresh_token'],
      serverUser['last_login'],
      serverUser['subscription'],
      serverUser['organization'],
    );
  }

  public getId(): string {
    return this.id;
  }

  public toJSON(): Record<string, any> {
    return {
      id: this.id,
      username: this.username,
      first_name: this.first_name,
      last_name: this.last_name,
      email: this.email,
      account_type: this.account_type,
      google_access_token: this.google_access_token,
      token: this.token,
      refresh_token: this.refresh_token,
      last_login: this.last_login,
      subscription: this.subscription?.id,
      organization: this.organization,
    };
  }

  public displayName(): string {
    return `${this.first_name} ${this.last_name}`;
  }
}
