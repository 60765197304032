import React, { useState } from 'react';
import Book from 'models/Book';
import BookCard from 'view/components/catalog/BookCard';

import 'view/style/catalog/components/bookRow.css';

const arrow_drop_down: string =
  require('assets/icons/arrow_drop_down.svg').default;
const arrow_right: string = require('assets/icons/arrow_right.svg').default;

interface BookRowProps {
  label: string;
  books: Book[];
  onSelect: (book: Book) => void;
}

export const BookRow: React.FC<BookRowProps> = ({ label, books, onSelect }) => {
  const [expand, setExpand] = useState<boolean>(false);

  return (
    <div className="book-catalog-row-container">
      <div className="row" onClick={() => setExpand((prev) => !prev)}>
        <label className="label-large">{label}</label>
        <img src={expand ? arrow_drop_down : arrow_right} alt="dropdown" />
      </div>
      <div className={`book-catalog-row${expand ? ' expand' : ''}`}>
        {books?.map((book) => (
          <BookCard
            key={`book-card-${book.id}`}
            book={book}
            onSelect={onSelect}
          />
        ))}
      </div>
    </div>
  );
};
