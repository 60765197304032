import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useClassroomContext } from 'contexts/ClassroomContext';
import Assignment from 'models/Assignment';
import TeacherGradebook from 'models/TeacherGradebook';
import teacherService from 'services/teacherService';
import BarChart from 'view/components/teacher/analytics/BarChart';
import TeacherGradebookEntry from 'models/TeacherGradebookEntry';
import MissedWordTable from 'view/components/teacher/analytics/MissedWordTable';
import { DisplayMissedWord, ServerMissedWords } from 'utils/types';
import { AppPage } from 'view/components/common/AppPage';
import { formatSeconds } from 'utils/utils';
import useApiCall from 'contexts/ApiCall';
import AppSidebar from 'view/components/common/Sidebar';
import contentService from 'services/contentService';
import ActionButton from 'view/components/buttons/ActionButton';
import Modal from 'view/components/common/Modal';
import GeneratePdfPopup from 'view/components/teacher/analytics/GeneratePdfPopup';

import 'view/style/teacher/analytics.css';

const feed: string = require('assets/icons/feed.svg').default;
const school: string = require('assets/icons/school.svg').default;

const initialChartData = [
  {
    label: 'Known',
    data: [],
    backgroundColor: 'rgba(68, 125, 0, 1)',
  },
  {
    label: 'Unknown',
    data: [],
    backgroundColor: 'rgba(177, 36, 63, 1)',
  },
];

type ChartData = {
  label: string;
  data: number[];
  backgroundColor: string;
};

// function sliceChartData(
//   chartData: ChartData,
//   start: number,
//   end: number,
// ): ChartData {
//   return {
//     label: chartData.label,
//     data: chartData.data.slice(start, end),
//     backgroundColor: chartData.backgroundColor,
//   };
// }

// class ChartData {
//   label: string;
//   data: number[];
//   backgroundColor: string;

//   constructor(label: string, data: number[], backgroundColor: string) {
//     this.label = label;
//     this.data = data;
//     this.backgroundColor = backgroundColor;
//   }

//   // Method to slice the data array and return a new ChartData instance
//   slice(start: number, end: number): ChartData {
//     return new ChartData(
//       this.label,
//       this.data.slice(start, end),
//       this.backgroundColor,
//     );
//   }
// }

function countMissedWords(
  data: ServerMissedWords,
  studentName?: string,
  assignmentName?: string,
) {
  const result: DisplayMissedWord[] = [];

  Object.entries(data).forEach(([student, assignments]) => {
    if (!studentName || student === studentName) {
      Object.entries(assignments).forEach(([assignment, words]) => {
        if (!assignmentName || assignment === assignmentName) {
          words.forEach((wordObj) => {
            const { word, known, isKeyWord } = wordObj;
            const wordKey = word.toLowerCase();
            // see if a DisplayMissedWord with this word already exists in the result
            let wordIdx = result.findIndex((dmw) => dmw.word === wordKey);
            if (wordIdx === -1) {
              result.push({
                word: wordKey,
                missed: 1,
                unknown: Number(!known),
                isKeyWord: isKeyWord,
              });
            } else {
              result[wordIdx].missed++;
              result[wordIdx].unknown += Number(!known);
              result[wordIdx].isKeyWord =
                result[wordIdx].isKeyWord || isKeyWord;
            }
          });
        }
      });
    }
  });

  // sort in descending order based on missed attribute
  const resultSorted = result.sort((dmw1, dmw2) => dmw2.missed - dmw1.missed);
  return resultSorted;
}

export const AnalyticsPage: React.FC = () => {
  const { classroom } = useClassroomContext();
  const [chartLabels, setChartLabels] = useState<string[]>([]);
  const [selectedAssignment, setSelectedAssignment] = useState<Assignment>();
  const [selectedStudent, setSelectedStudent] =
    useState<TeacherGradebookEntry>();
  const [gradebook, setGradebook] = useState<TeacherGradebook>();
  const [chartData, setChartData] = useState<ChartData[]>(initialChartData);
  const [missedWords, setMissedWords] = useState<ServerMissedWords>();
  const [displayMissedWords, setDisplayMissedWords] =
    useState<DisplayMissedWord[]>();
  const [numDisplayWords, setNumDisplayWords] = useState<number>(10);
  const [averages, setAverages] = useState<Record<string, any>>();
  const [showPdfPopup, setShowPdfPopup] = useState<boolean>(false);
  const pageContent = contentService.getTeacherAnalyticsPage();
  const makeApiCall = useApiCall();
  const navigate = useNavigate();

  useEffect(() => {
    if (gradebook || !classroom) return;
    const cachedGradebook = teacherService.getCachedGradebook();
    if (cachedGradebook) setGradebook(cachedGradebook);
    else {
      makeApiCall(teacherService.getGradebook, classroom.getId())
        .then((respGradebook) => setGradebook(respGradebook))
        .catch((error) => alert(error.message));
    }
  }, [gradebook, classroom, makeApiCall]);

  useEffect(() => {
    if (missedWords || !classroom) return;
    makeApiCall(teacherService.getClassroomMissedWords, classroom)
      .then((respWords) => {
        setMissedWords(respWords);
        setDisplayMissedWords(countMissedWords(respWords));
      })
      .catch((error) => alert(error.message));
  }, [missedWords, classroom, makeApiCall]);

  useEffect(() => {
    if (!missedWords) return;
    setDisplayMissedWords(
      countMissedWords(
        missedWords,
        selectedStudent?.username,
        selectedAssignment?.title,
      ),
    );
    if (!selectedAssignment && !selectedStudent) {
      setAverages({
        grade: gradebook?.averages.grade,
        completion_score: gradebook?.averages.completion_score,
        correctness_score: gradebook?.averages.correctness_score,
        key_word_accuracy_score: gradebook?.averages.key_word_accuracy_score,
        completion_time: gradebook?.averages.completion_time,
        missed_words: 0,
      });
    } else if (selectedAssignment && !selectedStudent) {
      setAverages({
        grade:
          gradebook?.averages.assignments[selectedAssignment.getId()].grade,
        completion_score:
          gradebook?.averages.assignments[selectedAssignment.getId()]
            .completion_score,
        correctness_score:
          gradebook?.averages.assignments[selectedAssignment.getId()]
            .correctness_score,
        key_word_accuracy_score:
          gradebook?.averages.assignments[selectedAssignment.getId()]
            .key_word_accuracy_score,
        completion_time:
          gradebook?.averages.assignments[selectedAssignment.getId()]
            .completion_time,
        missed_words: 0,
      });
    } else if (!selectedAssignment && selectedStudent) {
      setAverages({
        grade: selectedStudent.averages.grade,
        completion_score: selectedStudent.averages.completion_score,
        correctness_score: selectedStudent.averages.correctness_score,
        key_word_accuracy_score:
          selectedStudent.averages.key_word_accuracy_score,
        completion_time: selectedStudent.averages.completion_time,
        missed_words: 0,
      });
    } else {
      const idx = selectedStudent?.assignment_submissions.findIndex(
        (sa) => sa.assignment === selectedAssignment?.getId(),
      );
      if (idx === -1 || !idx) return;
      setAverages({
        grade: selectedStudent?.assignment_submissions[idx].grade,
        completion_score:
          selectedStudent?.assignment_submissions[idx].completion_score,
        correctness_score:
          selectedStudent?.assignment_submissions[idx].correctness_score,
        key_word_accuracy_score:
          selectedStudent?.assignment_submissions[idx].key_word_accuracy_score,
        completion_time:
          selectedStudent?.assignment_submissions[idx].completion_time,
        missed_words: 0,
      });
    }
  }, [selectedAssignment, selectedStudent, missedWords, gradebook]);

  useEffect(() => {
    if (!displayMissedWords) return;
    setChartData((prevChartData) => [
      {
        ...prevChartData[0],
        data: displayMissedWords.map((dmw) => dmw.missed),
      },
      {
        ...prevChartData[1],
        data: displayMissedWords.map((dmw) => dmw.unknown),
      },
    ]);
  }, [displayMissedWords]);

  useEffect(() => {
    if (!displayMissedWords) return;
    setChartLabels(
      displayMissedWords.slice(0, numDisplayWords).map((dmw) => dmw.word),
    );
    // eslint-disable-next-line
  }, [numDisplayWords, displayMissedWords]);

  const handleDisplayWordsUpdate = (numWords: number) => {
    if (!displayMissedWords) return;
    setNumDisplayWords(
      Math.max(Math.min(numWords, displayMissedWords.length), 1),
    );
  };

  const handleKeyWordToggle = (checked: boolean) => {
    if (!displayMissedWords) return;
    // get the words we will be displaying
    const displayMissedKeyWords = displayMissedWords
      .filter((dmw) => dmw.isKeyWord || !checked)
      .slice(0, numDisplayWords);
    setChartLabels(displayMissedKeyWords.map((mw) => mw.word));
    setChartData((prevChartData) => [
      {
        ...prevChartData[0],
        data: displayMissedKeyWords.map((dmw) => dmw.missed),
      },
      {
        ...prevChartData[1],
        data: displayMissedKeyWords.map((dmw) => dmw.unknown),
      },
    ]);
  };

  const handleAssignmentSelectChange = (e: any) => {
    if (!gradebook) return;
    const selectedValue = e.target.value;

    if (selectedValue === 'all') {
      setSelectedAssignment(undefined);
    } else {
      const selectedAssignment = gradebook.assignments?.find(
        (assignment) => assignment.title === selectedValue,
      );
      setSelectedAssignment(selectedAssignment);
    }
  };

  const handleStudentSelectChange = (e: any) => {
    const selectedValue = e.target.value;

    if (selectedValue === 'all') {
      setSelectedStudent(undefined);
    } else {
      const selectedStudent = gradebook?.students.find(
        (student) => student.username === selectedValue,
      );
      setSelectedStudent(selectedStudent);
    }
  };

  const handleExitAnalytics = () => {
    navigate(`/teacher/classroom/${classroom?.getId()}`);
  };

  if (!gradebook) return <AppPage></AppPage>;

  return (
    <AppPage>
      <div className="app-page-content">
        <AppSidebar onExit={handleExitAnalytics}>
          <>
            <div className="sidebar-text">
              <img src={feed} alt="feed" />
              <label className="label-normal">
                {pageContent.sidebar.assignments.label}
              </label>
            </div>
            <select onChange={handleAssignmentSelectChange}>
              <option value="all" id="analytics-assignment-all">
                {pageContent.sidebar.assignments.dropdown.default}
              </option>
              {gradebook.assignments?.map((assignment) => (
                <option
                  value={assignment.title}
                  id={`analytics-assignment-${assignment.getId()}`}
                  key={`analytics-assignment-${assignment.getId()}`}
                >
                  {assignment.title}
                </option>
              ))}
            </select>
            <div className="sidebar-text">
              <img src={school} alt="" />
              <label className="label-normal">
                {pageContent.sidebar.students.label}
              </label>
            </div>
            <select onChange={handleStudentSelectChange}>
              <option value="all" id="analytics-student-all">
                {pageContent.sidebar.students.dropdown.default}
              </option>
              {gradebook?.students.map((student) => (
                <option
                  value={student.username}
                  id={`analytics-student-${student.username}`}
                  key={`analytics-student-${student.username}`}
                >
                  {student.displayName()}
                </option>
              ))}
            </select>
            <div className="assignment-detail-header">
              <label className="label-medium">
                {pageContent.sidebar.details.completion.title}
              </label>
            </div>
            <div className="assignment-details-list">
              <div className="assignment-detail">
                <label className="label-small">
                  {pageContent.sidebar.details.completion.attributes[0].label}
                </label>
                <label className="label-small">
                  {averages?.completion_score}%
                </label>
              </div>
              <div className="assignment-detail">
                <label className="label-small">
                  {pageContent.sidebar.details.completion.attributes[1].label}
                </label>
                <label className="label-small">{averages?.grade}%</label>
              </div>
              <div className="assignment-detail">
                <label className="label-small">
                  {pageContent.sidebar.details.completion.attributes[2].label}
                </label>
                <label className="label-small">
                  {formatSeconds(averages?.completion_time)}
                </label>
              </div>
            </div>
            <div className="assignment-detail-header">
              <label className="label-medium">
                {pageContent.sidebar.details.accuracy.title}
              </label>
            </div>
            <div className="assignment-details-list">
              <div className="assignment-detail">
                <label className="label-small">
                  {pageContent.sidebar.details.accuracy.attributes[0].label}
                </label>
                <label className="label-small">
                  {averages?.correctness_score}%
                </label>
              </div>
              <div className="assignment-detail">
                <label className="label-small">
                  {pageContent.sidebar.details.accuracy.attributes[1].label}
                </label>
                <label className="label-small">
                  {averages?.key_word_accuracy_score}%
                </label>
              </div>
              <div className="assignment-detail">
                <label className="label-small">
                  {pageContent.sidebar.details.accuracy.attributes[2].label}
                </label>
                <label className="label-small">
                  {displayMissedWords && Object.keys(displayMissedWords).length}
                </label>
              </div>
            </div>
            <ActionButton
              label="Download PDF Report"
              onClick={() => setShowPdfPopup(true)}
            />
          </>
        </AppSidebar>
        <div className="app-main-content">
          <h1>
            {classroom?.name} | {pageContent.content.title}
          </h1>
          {!selectedStudent ? (
            <div className="graph-container">
              <BarChart
                options={{}}
                data={{
                  labels: chartLabels ?? [],
                  datasets: chartData,
                }}
              />
              <div className="row">
                <div className="labeled-input label-left">
                  <label># Words</label>
                  <input
                    type="number"
                    value={numDisplayWords}
                    onChange={(event) =>
                      handleDisplayWordsUpdate(Number(event.target.value))
                    }
                  />
                </div>
                <label>Key words only</label>
                <input
                  type="checkbox"
                  onChange={(event) =>
                    handleKeyWordToggle(event.target.checked)
                  }
                />
              </div>
            </div>
          ) : (
            <div className="graph-container">
              {displayMissedWords && (
                <MissedWordTable missedWords={displayMissedWords} />
              )}
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={showPdfPopup}
        onClose={() => setShowPdfPopup((prev) => !prev)}
      >
        <GeneratePdfPopup gradebook={gradebook} />
      </Modal>
    </AppPage>
  );
};
