import React, { createContext, useContext, ReactNode, useState } from 'react';
import GradebookEntry from 'models/StudentGradebookEntry'; 

interface AssignmentContextType {
  assignment: GradebookEntry | null;
  setAssignment: (assignment: GradebookEntry | null) => void;
}

const AssignmentContext = createContext<AssignmentContextType | undefined>(undefined);

export function useAssignmentContext() {
  const context = useContext(AssignmentContext);
  if (context === undefined) {
    throw new Error('useAssignmentContext must be used within a AssignmentProvider');
  }
  return context;
}

interface AssignmentProviderProps {
  children: ReactNode;
}

export function AssignmentProvider({ children }: AssignmentProviderProps) {
  const [assignment, setAssignment] = useState<GradebookEntry | null>(null);

  return (
    <AssignmentContext.Provider value={{ assignment, setAssignment }}>
      {children}
    </AssignmentContext.Provider>
  );
}
