import React from "react";
import contentService from "services/contentService";
import ActionButton from "view/components/buttons/ActionButton";

interface DeleteConfirmationProps {
  warning: string;
  onDelete: () => void;
  onExit: () => void;
}
  
export const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({ warning, onDelete, onExit }) => {
  const formContent = contentService.getCommonForms().delete_confirmation_form;

  return (
    <div className='form'>
      <label className='label-large'>{ formContent.title }</label>
      <div className='form-contents'>
        <span className='label-medium' style={{color: '#B1243F'}}>
          {formContent.warning_intro}: {warning}. {formContent.warning_confirmation}
        </span>
        <div className='row'>
          <ActionButton 
            type='delete' 
            onClick={onDelete}
            label={formContent.confirm_button.label}
          />
          <ActionButton 
            type='go' 
            onClick={onExit}
            label={formContent.cancel_button.label}
          />
        </div>
      </div>
    </div>
  );
}