import React from 'react';
import Button from 'view/components/buttons/Button';

import 'view/style/student/components/assignment/bookCompletedFooter.css'
;
interface BookCompletedFooterProps {
  onViewQuestions?: () => void;
}

const BookCompletedFooter: React.FC<BookCompletedFooterProps> = ({onViewQuestions}) => {
  return (
    <div className='reading-footer'>
      <div className='book-completed-text'></div>
      <div className='book-completed-text'><h2>You're Done</h2></div>
      <div className='book-completed-text'>
        { onViewQuestions &&
          <Button 
            type='option' 
            onClick={onViewQuestions} 
            text='Questions'
            label='Questions'
          />
        }
      </div>
    </div>
  );
}

export default BookCompletedFooter;