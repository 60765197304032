import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useCallback,
} from 'react';
import User from 'models/User';

interface UserContextType {
  user: User | null;
  setUser: (user: User | null) => void;
  updateUser: (updateFn: (currentUser: User | null) => User | null) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export function useUserContext() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
}

interface UserProviderProps {
  children: ReactNode;
}

export function UserProvider({ children }: UserProviderProps) {
  const [user, setUser] = useState<User | null>(null);

  const updateUser = useCallback(
    (updateFn: (currentUser: User | null) => User | null) => {
      setUser((currentUser) => updateFn(currentUser));
    },
    [],
  );

  return (
    <UserContext.Provider value={{ user, setUser, updateUser }}>
      {children}
    </UserContext.Provider>
  );
}
