import React, { useEffect, useState } from 'react';
import teacherService from 'services/teacherService';
import useApiCall from 'contexts/ApiCall';
// import contentService from "services/contentService";
import { SearchBar } from 'view/components/common/SearchBar';
import User from 'models/User';
import { useClassroomContext } from 'contexts/ClassroomContext';
import UserInfoDropdownItem from 'view/components/teacher/classroom/UserInfoDropdownItem';
import MultiTabForm from 'view/components/common/MultiTabForm';
import { useUserContext } from 'contexts/UserContext';
import { Subscription, TeacherGeneratedStudent } from 'utils/types';
import InputTable from 'view/components/common/InputTable';

interface AddStudentFormProps {
  ignoreStudents: Set<string>;
  onStudentAdded: () => void;
}

const AddStudentForm: React.FC<AddStudentFormProps> = ({
  ignoreStudents,
  onStudentAdded,
}) => {
  const { user, updateUser } = useUserContext();
  const { classroom } = useClassroomContext();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [students, setStudents] = useState<User[]>();
  // const formContent = contentService.getTeacherForms().create_classroom_form;
  const makeApiCall = useApiCall();

  useEffect(() => {
    handleSearchStudents('');
    // eslint-disable-next-line
  }, []);

  const handleAddStudent = (
    userId: string,
    classroomId: string,
    readingGroup: number,
  ) => {
    makeApiCall(teacherService.addStudent, userId, classroomId, readingGroup)
      .then(() => {
        setErrorMessage('');
        onStudentAdded();
      })
      .catch((error) => setErrorMessage(error.message));
  };

  const handleSearchStudents = (searchTerm: string) => {
    makeApiCall(teacherService.searchStudents, searchTerm)
      .then((resp) => {
        setStudents(resp);
        setErrorMessage('');
      })
      .catch((error) => setErrorMessage(error.message));
  };

  if (!classroom || !user || !user.subscription) return null;

  const createStudents = (studentData: TeacherGeneratedStudent[]) => {
    Promise.all(studentData.map((student) => createStudent(student))).then(() =>
      onStudentAdded(),
    );
  };

  const createStudent = (student: TeacherGeneratedStudent) => {
    makeApiCall(
      teacherService.createStudent,
      { ...student, readingLevel: classroom.min_reading_level },
      classroom?.getId(),
      user?.subscription,
    )
      .then((resp) => {
        setErrorMessage('');
        updateUser((currentUser) =>
          User.fromServerUser({
            ...currentUser,
            subscription: {
              ...(currentUser?.subscription as Subscription),
              num_users:
                (currentUser?.subscription as Subscription).num_users + 1,
            },
          }),
        );
      })
      .catch((error) => alert(error.message));
  };

  return (
    <>
      <MultiTabForm
        labels={['Add students', 'Create students']}
        formContent={[
          <SearchBar showDropdown={true} onSearch={handleSearchStudents}>
            {students?.map((student) => {
              if (ignoreStudents.has(student.username)) return null;
              return (
                <UserInfoDropdownItem
                  key={`user-info-${student.username}`}
                  user={student}
                  classroom={classroom}
                  onSelect={handleAddStudent}
                />
              );
            })}
          </SearchBar>,
          <div className="form-contents add-student">
            {user.subscription.max_users && (
              <span className="label-normal">
                ({user.subscription.max_users - user.subscription.num_users}{' '}
                students remaining on your subscription)
              </span>
            )}
            <InputTable
              headers={[
                { key: 'firstName', label: 'First Name', type: 'text' },
                { key: 'lastName', label: 'Last Name', type: 'text' },
                { key: 'email', label: 'Email', type: 'text' },
                { key: 'username', label: 'Username', type: 'text' },
                { key: 'password', label: 'Password', type: 'text' },
              ]}
              maxRows={
                user.subscription.max_users &&
                user.subscription.max_users - user.subscription.num_users
              }
              onSubmit={createStudents}
            />
          </div>,
        ]}
      />
      {errorMessage && (
        <span className="label-normal danger">{errorMessage}</span>
      )}
    </>
  );
};

export default AddStudentForm;
