import React from 'react';
import { DisplayMissedWord } from 'utils/types';

interface MissedWordTableProps {
  missedWords: DisplayMissedWord[];
}

const MissedWordTable: React.FC<MissedWordTableProps> = ({ missedWords }) => {
  return (
    <table className="missed-words-table">
      <thead>
        <tr>
          <th>
            <span className="label-medium">Word</span>
          </th>
          <th>
            <span className="label-medium">Times Missed</span>
          </th>
          <th>
            <span className="label-medium label-hint">Times Known</span>
          </th>
          <th>
            <span className="label-medium danger">Times Unknown</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {missedWords.map((dmw) => (
          <tr key={`tr-${dmw.word}`}>
            <td>
              <span className="label-medium">{`${dmw.word}${
                dmw.isKeyWord ? '*' : ''
              }`}</span>
            </td>
            <td>
              <span className="label-medium">{dmw.missed}</span>
            </td>
            <td>
              <span className="label-medium label-hint">
                {dmw.missed - dmw.unknown}
              </span>
            </td>
            <td>
              <span className="label-medium danger">{dmw.unknown}</span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default MissedWordTable;
