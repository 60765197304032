export default class MultipleChoiceOption {
  public id;
  public question: string;
  public text: string;
  public correct: boolean;

  constructor(id: string, question: string, text: string, correct: boolean) {
    this.id = id;
    this.question = question;
    this.text = text;
    this.correct = correct;
  }

  static fromMultipleChoiceOption(question: MultipleChoiceOption) {
    return new MultipleChoiceOption(
      question.id,
      question.question,
      question.text,
      question.correct,
    );
  }

  static fromServerOption(option: Record<string, any>): MultipleChoiceOption {
    return new MultipleChoiceOption(
      option['id'],
      option['question'],
      option['text'],
      option['correct'],
    );
  }

  public toJSON(): Record<string, any> {
    return {
      id: this.id,
      question: this.question,
      text: this.text,
      correct: this.correct,
    };
  }
}
