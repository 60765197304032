import React from 'react'
import { PrivacyPolicy } from 'view/components/common/PrivacyPolicy'

export const PrivacyPolicyPage: React.FC = () => {

  return (
    <div className='app-page'>
      <PrivacyPolicy />
    </div>
  )
}