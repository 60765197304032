import { Slider } from '@mui/material';
import useApiCall from 'contexts/ApiCall';
import Classroom from 'models/Classroom';
import React, { useEffect, useState } from 'react';
import teacherService from 'services/teacherService';
import ActionButton from 'view/components/buttons/ActionButton';
import FinePrint from 'view/components/common/FinePrint';
import { FromToInput } from 'view/components/common/UserInput';
import { PracticeType } from 'view/pages/student/practice/practice';

type PracticeAssignmentFormProps = {
  classroom: Classroom;
  onExit: () => void;
};

type PracticeAssignmentFormData = {
  title: string;
  length: number;
  assignment_type: PracticeType;
  start_date: string;
  due_date: string;
  min_reading_level: number;
  max_reading_level: number;
  classroom: string;
};

const initialFormData = {
  title: 'Practice',
  length: 10,
  assignment_type: PracticeType.FLASHCARD,
  start_date: '',
  due_date: '',
  min_reading_level: 0,
  max_reading_level: 0,
  classroom: '',
};

const PracticeAssignmentForm: React.FC<PracticeAssignmentFormProps> = ({
  classroom,
  onExit,
}) => {
  const [formData, setFormData] =
    useState<PracticeAssignmentFormData>(initialFormData);
  const makeApiCall = useApiCall();

  useEffect(() => {
    if (!classroom) return;
    setFormData((prev: PracticeAssignmentFormData) => {
      return {
        ...prev,
        min_reading_level: classroom.min_reading_level,
        max_reading_level: classroom.max_reading_level,
        classroom: classroom.getId(),
      };
    });
  }, [classroom]);

  const handlePracticeLengthChange = (
    event: Event,
    value: number | number[],
  ) => {
    setFormData((prev) => {
      return { ...prev, length: value as number };
    });
  };

  const handleAssignPractice = () => {
    makeApiCall(teacherService.createPracticeAssignment, formData)
      .then((resp) => {
        onExit();
      })
      .catch((err) => alert(err));
  };

  const updatePractice = (event: any) => {
    if (!formData || !classroom) return;
    var value = event.target.value;
    if (event.target.id === 'min_reading_level') {
      value = Math.min(
        Math.max(classroom.min_reading_level, value),
        formData.max_reading_level,
      );
    } else if (event.target.id === 'max_reading_level') {
      value = Math.max(
        Math.min(classroom.max_reading_level, value),
        formData.min_reading_level,
      );
    }
    setFormData((prev: any) => {
      return {
        ...prev,
        [event.target.id]: value,
      };
    });
  };

  return (
    <div className="form">
      <label className="label-large">Practice details</label>
      <div className="assignment-attribute-container">
        <label className="label-normal">Title</label>
        <input
          type="text"
          id="title"
          value={formData.title}
          onChange={updatePractice}
        />
      </div>
      <div className="assignment-attribute-container">
        <div className="row">
          <input
            id="start_date"
            type="datetime-local"
            value={formData.start_date}
            onChange={updatePractice}
          />
          <input
            id="due_date"
            type="datetime-local"
            value={formData.due_date}
            onChange={updatePractice}
          />
        </div>
      </div>
      <div className="assignment-attribute-container">
        <label className="label-normal">Practice Type</label>
        <select>
          <option value="Flashcards">Flashcards</option>
          <option value="Pronunce">Pronunciation</option>
        </select>
      </div>
      <div className="assignment-attribute-container">
        <label className="label-normal">Practice Length</label>
        <div className="row stretch">
          <span className="label-small">{formData.length} Words</span>
          <Slider
            min={5}
            max={25}
            value={formData.length}
            onChange={handlePracticeLengthChange}
          />
        </div>
        <FinePrint
          text={`Note: Include the top ${formData.length} most frequently missed words in the practice session`}
          type="danger"
        />
      </div>
      {classroom &&
        classroom.min_reading_level < classroom.max_reading_level && (
          <div className="assignment-attribute-container">
            <label className="label-normal">Reading Groups</label>
            <FromToInput
              id="reading_level"
              value={{
                min: formData.min_reading_level,
                max: formData.max_reading_level,
              }}
              onChange={updatePractice}
            />
            <FinePrint
              text="Note: Only students within this range will be assigned this practice"
              type="danger"
            />
          </div>
        )}
      <ActionButton type="go" label="Assign" onClick={handleAssignPractice} />
    </div>
  );
};

export default PracticeAssignmentForm;
