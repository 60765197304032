import React, { useState } from 'react';
import Button from 'view/components/buttons/Button';
import { FileInput } from './FileInputs';
import { FromToInput } from 'view/components/common/UserInput';
import FinePrint from 'view/components/common/FinePrint';
import { WordList } from '../../common/WordList';
import { VersionTag } from 'view/components/common/VersionTag';
import Assignment from 'models/Assignment';
import Book from 'models/Book';
import AudioPlayer from 'view/components/common/AudioPlayer';
import { useClassroomContext } from 'contexts/ClassroomContext';

const arrow_back: string = require('assets/icons/arrow_back.svg').default;
const add_circle_outline: string =
  require('assets/icons/add_circle_outline.svg').default;
const bar_chart: string = require('assets/icons/bar_chart.svg').default;
const record_voice_over_gray: string =
  require('assets/icons/record_voice_over_gray.svg').default;
const speaker: string =
  require('assets/icons/record_voice_over_gray.svg').default;

interface AssignmentSidebarProps {
  assignment: Assignment;
  book: Book;
  options: string[];
  coverImage?: string;
  handleBookCoverChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleInputFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCancel: () => void;
  setAssignment: React.Dispatch<React.SetStateAction<Assignment | undefined>>;
  updateAssignment: (event: any) => void;
  updateBook: (event: any) => void;
}

const AssignmentSidebar: React.FC<AssignmentSidebarProps> = ({
  assignment,
  book,
  options,
  coverImage,
  handleBookCoverChange,
  handleInputFileChange,
  onCancel,
  setAssignment,
  updateAssignment,
  updateBook,
}) => {
  const [keyWord, setKeyWord] = useState<string>('');
  const { classroom } = useClassroomContext();

  const handleAddKeyWord = () => {
    setKeyWord('');
    setAssignment((prev) =>
      Assignment.fromServerAssignment({
        ...prev,
        key_words: Array.from(
          new Set([...(prev as Assignment).key_words, keyWord.trim()]),
        ),
      }),
    );
  };

  const handleDeleteKeyWord = (word: string) => {
    setAssignment((prev) =>
      Assignment.fromServerAssignment({
        ...(prev as Assignment),
        key_words: prev?.key_words.filter((w) => w !== word),
      }),
    );
  };

  return (
    <div className="app-sidebar">
      <div className="col">
        <Button onClick={onCancel} leadIcon={arrow_back} label="Back" />
        <div className="assignment-sidebar-content">
          <input
            id="start_date"
            type="datetime-local"
            value={assignment.start_date}
            onChange={updateAssignment}
          />
          <input
            id="due_date"
            type="datetime-local"
            value={assignment.due_date}
            onChange={updateAssignment}
          />
          <FileInput
            accept=".pdf, .txt, .jpeg, .jpg, .png, .heic"
            onFileChange={handleInputFileChange}
          />
          {classroom &&
            classroom.min_reading_level < classroom.max_reading_level && (
              <div className="assignment-attribute-container">
                <div className="sidebar-text">
                  <img src={bar_chart} alt="" />
                  <label className="label-normal">Reading Groups</label>
                </div>
                <FromToInput
                  id="reading_level"
                  value={{
                    min: assignment.min_reading_level,
                    max: assignment.max_reading_level,
                  }}
                  onChange={updateAssignment}
                />
                <FinePrint
                  text="Note: Only students within this range will be assigned to this assignment"
                  type="danger"
                />
              </div>
            )}
          {options.includes('speech-accuracy') && (
            <div className="assignment-attribute-container">
              <div className="sidebar-text">
                <img src={record_voice_over_gray} alt="" />
                <label className="label-normal">Speech Accuracy</label>
              </div>
              <select
                id="correctness_level"
                value={assignment.correctness_level}
                onChange={updateAssignment}
              >
                <option value="Easy">Generous</option>
                <option value="Close">Default</option>
                <option value="Exact">Strict</option>
              </select>
            </div>
          )}
          {options.includes('missed-word-interval') && (
            <div className="assignment-attribute-container">
              <div className="sidebar-text">
                <label className="label-normal">Missed Word Interval</label>
                <input
                  type="number"
                  id="missed_word_interval"
                  value={assignment?.missed_word_interval}
                  onChange={updateAssignment}
                />
              </div>
              <FinePrint text="Amount of time the reader has to correctly pronounce a word" />
            </div>
          )}
          {options.includes('key-words') && (
            <div className="assignment-attribute-container">
              <div className="sidebar-text">
                <label className="label-normal">Key Words</label>
              </div>
              <div className="row stretch">
                <input
                  type="text"
                  placeholder="enter a word"
                  value={keyWord}
                  onChange={(event) => setKeyWord(event.target.value)}
                />
                <img
                  src={add_circle_outline}
                  alt="plus"
                  onClick={handleAddKeyWord}
                />
              </div>
              <WordList
                words={Array.from(assignment.key_words)}
                onDeleteWord={handleDeleteKeyWord}
              />
            </div>
          )}
          {options.includes('book-cover') && (
            <div className="assignment-attribute-container">
              <FileInput
                id={'book-cover-upload'}
                label="Upload book cover"
                accept=".jpeg, .jpg, .png"
                onFileChange={handleBookCoverChange}
              />
              <div className="book-card-cover">
                <img src={coverImage ?? book.cover_image} alt="" />
              </div>
            </div>
          )}
          {options.includes('audio') && book.audio_url && (
            <div className="assignment-attribute-container">
              <div className="sidebar-text">
                <img src={speaker} alt="" />
                <label className="label-normal">Audio</label>
              </div>
              <AudioPlayer audioUrl={book.audio_url} />
            </div>
          )}
          {options.includes('show-popup') && (
            <div className="assignment-attribute-container">
              <div className="sidebar-text">
                <label className="label-normal">Show Missed Word Popup</label>
                <input
                  type="checkbox"
                  id="show_missed_word_popup"
                  checked={assignment?.show_missed_word_popup}
                  onChange={(event) =>
                    setAssignment((prev) =>
                      Assignment.fromServerAssignment({
                        ...prev,
                        show_missed_word_popup: event.target.checked,
                      }),
                    )
                  }
                />
              </div>
              <FinePrint text="Toggle whether a popup with word defintiion and pronunciation is displayed after a student misses a word" />
            </div>
          )}
          <div className="assignment-attribute-container">
            <div className="sidebar-text">
              <label className="label-normal">Make Publicly Available</label>
              <input
                type="checkbox"
                id="is_public"
                checked={book?.is_public || book?.is_under_review}
                onChange={(event) => {
                  if (book?.is_public) {
                    updateBook({
                      target: { id: 'is_public', value: !book?.is_public },
                    });
                  } else {
                    updateBook({
                      target: {
                        id: 'is_under_review',
                        value: event.target.checked,
                      },
                    });
                  }
                }}
              />
            </div>
            <FinePrint text="This enables your submission to be viewable in our public catalog" />
          </div>
        </div>
      </div>
      <VersionTag />
    </div>
  );
};

export default AssignmentSidebar;
