import { Subscription } from 'utils/types';
import apiService from 'services/apiService';


export class PaymentService {
  private static instance: PaymentService;

  public static get(): PaymentService {
    if (!PaymentService.instance) {
        PaymentService.instance = new PaymentService();
    }

    return PaymentService.instance;
  }

  createPaymentIntent = async (subscription: Subscription): Promise<Record<string, any> | null> => {
    try {
      const response = await apiService.api.post(`/create-payment-intent`, subscription)
      return response.data;
    } catch (error) {
      console.error('Error with the payment api')
      console.error(error);
      return null;
    }
  }
}

const paymentService = PaymentService.get();
export default paymentService;