import React from 'react';
import { useNavigate } from 'react-router-dom';
import ActionButton from 'view/components/buttons/ActionButton';
import { AppPage } from 'view/components/common/AppPage';

const AdminHome: React.FC = () => {
  const navigate = useNavigate();

  return (
    <AppPage>
      <div className="app-main-content">
        <h1>Admin Home</h1>
        <ActionButton
          type="go"
          label="Catalog"
          onClick={() => navigate('/admin/catalog')}
        />
      </div>
    </AppPage>
  );
};

export default AdminHome;
