import Book from 'models/Book';
import User from 'models/User';
import { parseError } from 'utils/utils';
import apiService from 'services/apiService';
import CachingEngine from 'utils/CachingEngine';

interface CatalogBooks {
  books: Book[];
  liked_books: Book[];
}

export class CatalogService {
  private static instance: CatalogService;

  public static get(): CatalogService {
    if (!CatalogService.instance) {
      CatalogService.instance = new CatalogService();
    }

    return CatalogService.instance;
  }

  getCatalogBooks = async (): Promise<CatalogBooks> => {
    try {
      const cachedData = await CachingEngine.getData('catalog');
      if (cachedData) {
        return {
          books: cachedData.books.map((b: Book) => Book.fromServerBook(b)),
          liked_books: cachedData.liked_books.map((b: Book) =>
            Book.fromServerBook(b),
          ),
        };
      }
      const response = await apiService.api.get('/books/');
      const catalog = {
        books: response.data.books.map((respBook: Record<string, any>) =>
          Book.fromServerBook(respBook),
        ),
        liked_books: response.data.likes.map((like: Record<string, any>) =>
          Book.fromServerBook(
            response.data.books.find(
              (respBook: Record<string, any>) => respBook.id === like.book,
            ),
          ),
        ),
      };

      // store the catalog
      await CachingEngine.setData('catalog', catalog);

      return catalog;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  addLikedBook = async (
    user: User,
    book: Book,
    liked: boolean,
  ): Promise<Record<string, any>> => {
    try {
      const response = await apiService.api.post(
        `/liked_books/${liked ? '' : 'remove/'}`,
        {
          user: user.getId(),
          book: book.id,
        },
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };
}

export const catalogService = CatalogService.get();
