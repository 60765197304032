import React, { createContext, useContext, useState, useEffect } from 'react';

// Define the shape of your UserPreferences state
interface UserPreferencesContextProps {
  fontScale: number;
  setFontScale: (scale: number) => void;
  lineSpacing: number;
  setLineSpacing: (spacing: number) => void;
  // Add more styling related states as needed
}

const UserPreferencesContext = createContext<
  UserPreferencesContextProps | undefined
>(undefined);

// Custom hook for using UserPreferencesContext
export const useUserPreferences = () => {
  const context = useContext(UserPreferencesContext);
  if (!context) {
    throw new Error(
      'useUserPreferences must be used within a UserPreferencesProvider',
    );
  }
  return context;
};

export const UserPreferencesProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [fontScale, setFontScale] = useState<number>(() => {
    const savedScale = localStorage.getItem('fontScale');
    return savedScale ? parseFloat(savedScale) : 1;
  });

  const [lineSpacing, setLineSpacing] = useState<number>(() => {
    const savedSpacing = localStorage.getItem('lineSpacing');
    return savedSpacing ? parseFloat(savedSpacing) : 1;
  });

  // Persist changes to localStorage
  useEffect(() => {
    localStorage.setItem('fontScale', fontScale.toString());
  }, [fontScale]);

  useEffect(() => {
    localStorage.setItem('lineSpacing', lineSpacing.toString());
  }, [lineSpacing]);

  return (
    <UserPreferencesContext.Provider
      value={{ fontScale, setFontScale, lineSpacing, setLineSpacing }}
    >
      {children}
    </UserPreferencesContext.Provider>
  );
};
