import React, { useState, ChangeEvent } from 'react';
import { UserInput } from 'view/components/common/UserInput';

import 'view/style/home/login.css';

const visible = require('assets/icons/eye-solid.svg').default;
const hidden = require('assets/icons/eye-slash-solid.svg').default;

interface PasswordInputProps {
  id?: string;
  label?: string;
  password: string;
  verify?: boolean;
  valid?: boolean;
  text?: string;
  setPassword: (password: string) => void;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  id,
  label,
  password,
  verify = true,
  valid,
  text,
  setPassword,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <UserInput
      id={id ?? 'password'}
      type={showPassword ? 'text' : 'password'}
      label={label ?? 'Password'}
      hint={verify ? '8+ characters' : ''}
      valid={valid}
      text={text}
      inlineLabel={
        <img
          src={showPassword ? visible : hidden}
          alt=""
          onClick={() => setShowPassword((prev) => !prev)}
        />
      }
      onChange={handlePasswordChange}
      minLength={8}
    />
  );
};

export default PasswordInput;
