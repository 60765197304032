import StudentGradebookEntry from './StudentGradebookEntry';

export default class StudentGradebook {
  public entries: Array<StudentGradebookEntry>;
  public averages: Record<string, number>;

  constructor(
    entries: Array<StudentGradebookEntry>,
    averages: Record<string, number>,
  ) {
    this.entries = entries;
    this.averages = averages;
  }

  static fromStudentGradebook(studentGradebook: StudentGradebook) {
    return new StudentGradebook(
      studentGradebook.entries,
      studentGradebook.averages,
    );
  }

  static fromServerStudentGradebook(
    serverClassroom: Record<string, any>,
  ): StudentGradebook {
    return new StudentGradebook(
      serverClassroom['entries']
        .map((serverEntry: Record<string, any>) =>
          StudentGradebookEntry.fromServerEntry(serverEntry),
        )
        .sort((e1: StudentGradebookEntry, e2: StudentGradebookEntry) => {
          const date1Completed = e1.assignment_submission.date_completed
            ? new Date(e1.assignment_submission.date_completed).getTime()
            : 0;
          const date2Completed = e2.assignment_submission.date_completed
            ? new Date(e2.assignment_submission.date_completed).getTime()
            : 0;

          // Check if both have a completion date, neither have, or only one has it
          if (date1Completed && date2Completed) {
            // Both are completed, sort by due date
            return (
              new Date(e2.assignment.due_date).getTime() -
              new Date(e1.assignment.due_date).getTime()
            );
          } else if (!date1Completed && !date2Completed) {
            // Neither is completed, sort by due date
            return (
              new Date(e2.assignment.due_date).getTime() -
              new Date(e1.assignment.due_date).getTime()
            );
          } else {
            // One is completed, one isn't. The one that is completed goes first
            return date1Completed - date2Completed;
          }
        }),
      serverClassroom['averages'],
    );
  }

  public updateEntry(entry: StudentGradebookEntry): StudentGradebook {
    // first just replace the existing entry with whichever values
    // were updated, then update the averages
    var newGradebook = StudentGradebook.fromServerStudentGradebook({
      entries: [
        ...this.entries.filter(
          (e) => e.assignment.getId() !== entry.assignment.getId(),
        ),
        entry,
      ],
      averages: {
        ...this.averages,
        grade: Math.round(
          (this.averages.grade * (this.entries.length - 1) +
            entry.assignment_submission.grade) /
            this.entries.length,
        ),
      },
    });
    return newGradebook;
  }

  public toJSON(): Record<string, any> {
    return { ...this };
  }
}
