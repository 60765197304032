import React, { ReactNode } from "react";

import 'view/style/common/buttons.css';

interface LandingButtonProps {
  type?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children: ReactNode;
}

export const LandingButton: React.FC<LandingButtonProps> = ({ type, onClick, children }) => {
  return (
    <button className={`landing-button ${type || ''}`} onClick={onClick}>
        <span className='label-normal'>
          { children }
        </span>
    </button>
  );
}