import React from 'react'
import { TermsOfService } from 'view/components/common/TermsOfService'


export const TermsOfServicePage: React.FC = () => {
  return (
    <div className='app-page'>
      <TermsOfService/>
    </div>
  )
}