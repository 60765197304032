import React, { useState } from 'react';

interface NumberSelectProps {
  initialValue?: number;
  min: number;
  max: number;
  onChange: (value: number) => void;
}

const NumberSelect: React.FC<NumberSelectProps> = ({ initialValue, min, max, onChange }) => {
  const [selectedNumber, setSelectedNumber] = useState<number>(initialValue ?? min);

  const numbers = Array.from({ length: max - min + 1 }, (_, i) => i + min);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.target.value, 10);
    setSelectedNumber(value);
    onChange(value);
  };

  return (
    <select value={selectedNumber} onChange={handleChange}>
      {numbers.map((number) => (
        <option key={number} value={number}>
          {number}
        </option>
      ))}
    </select>
  );
};

export default NumberSelect;
