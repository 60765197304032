import React from "react";
import Classroom from "models/Classroom";

import 'view/style/common/components/classroomList.css';

const add_circle_outline: string = require('assets/icons/add_circle_outline.svg').default;
const arrow_back_white: string = require('assets/icons/arrow_back_white.svg').default;

interface ClassroomListProps {
  classrooms: Classroom[];
  onEnterClassroom: (classroom: Classroom) => void;
  openAddClassroomForm: (open: boolean) => void;
}

export const ClassroomList: React.FC<ClassroomListProps> = ({ classrooms, onEnterClassroom, openAddClassroomForm }) => {
  return (
    <div className='classroom-list'>
      { classrooms.map(classroom => 
          <div 
            key={`classroom-${classroom.getId()}`}
            id={`classroom-${classroom.getId()}`}
            className='classroom-panel' 
            onClick={() => onEnterClassroom(classroom)}
          >
            <h3>{classroom.name}</h3>
            <img className='arrow-forward' src={arrow_back_white} alt=''/>
          </div>
        ) 
      }
      <div className='join-classroom-panel' onClick={() => openAddClassroomForm(true)}>
        <img src={add_circle_outline} alt='plus'/>
        <span className='label-normal'>Add New Classroom</span>
      </div>
    </div>
  );
}