import Assignment from './Assignment';
import AssignmentSubmission from './AssignmentSubmission';
import TeacherGradebookEntry from './TeacherGradebookEntry';

export default class TeacherGradebook {
  public students: TeacherGradebookEntry[];
  public assignments: Assignment[];
  public averages: ClassAverages;

  constructor(
    students: TeacherGradebookEntry[],
    assignments: Assignment[],
    averages: ClassAverages,
  ) {
    this.students = students;
    this.assignments = assignments;
    this.averages = averages;
  }

  static fromTeacherGradebook(teacherGradebook: TeacherGradebook) {
    return new TeacherGradebook(
      teacherGradebook.students.slice(),
      teacherGradebook.assignments.slice(),
      teacherGradebook.averages,
    );
  }

  static fromServerGradebook(
    serverGradebook: Record<string, any>,
  ): TeacherGradebook {
    return new TeacherGradebook(
      serverGradebook.students
        .sort((s1: Record<string, any>, s2: Record<string, any>) => {
          // Compare last names
          if (s1.last_name < s2.last_name) return -1;
          if (s1.last_name > s2.last_name) return 1;

          // If last names are equal, compare first names
          if (s1.first_name < s2.first_name) return -1;
          if (s1.first_name > s2.first_name) return 1;

          // Names are equal
          return 0;
        })
        .map((student: Record<string, any>) =>
          TeacherGradebookEntry.fromServerEntry(
            student,
            serverGradebook['assignments'],
          ),
        ),
      serverGradebook['assignments']
        .map((asgn: Assignment) => Assignment.fromServerAssignment(asgn))
        .sort(
          (a1: Assignment, a2: Assignment) =>
            new Date(a2.due_date).getTime() - new Date(a1.due_date).getTime(),
        ),
      serverGradebook['averages'],
    );
  }

  public updateAssignmentSubmission(
    updatedSubmission: AssignmentSubmission,
  ): TeacherGradebook {
    const studentEntry = this.students.find(
      (s) => s.student_profile.getId() === updatedSubmission.student_profile,
    );
    if (!studentEntry) return this;
    const newStudentEntry = TeacherGradebookEntry.fromServerEntry(
      {
        ...studentEntry,
        assignment_submissions: [
          ...studentEntry.assignment_submissions.filter(
            (sa) => sa.getId() !== updatedSubmission.getId(),
          ),
          updatedSubmission,
        ],
      },
      this.assignments,
    ) as TeacherGradebookEntry;
    return TeacherGradebook.fromServerGradebook({
      ...this,
      students: [
        ...this.students.filter(
          (s) =>
            s.student_profile.getId() !== updatedSubmission.student_profile,
        ),
        newStudentEntry,
      ],
    });
  }

  public addAssignment(assignment: Assignment): TeacherGradebook {
    return TeacherGradebook.fromServerGradebook({
      ...this,
      assignments: [...this.assignments, assignment],
    });
  }

  public updateAssignment(assignment: Assignment): TeacherGradebook {
    return TeacherGradebook.fromServerGradebook({
      ...this,
      assignments: [
        ...this.assignments.filter((asgn) => asgn.id !== assignment.id),
        assignment,
      ],
    });
  }

  public removeAssignment(assignment: Assignment): TeacherGradebook {
    return TeacherGradebook.fromServerGradebook({
      ...this,
      assignments: this.assignments.filter((asgn) => asgn.id !== assignment.id),
    });
  }

  public toJSON(): Record<string, any> {
    return { ...this };
  }
}

interface ClassAverages {
  grade: number;
  completion_score: number;
  correctness_score: number;
  key_word_accuracy_score: number;
  completion_time: number;
  assignments: {
    [key: string]: {
      grade: number;
      completion_score: number;
      correctness_score: number;
      key_word_accuracy_score: number;
      completion_time: number;
    };
  };
}
