import React from 'react';
import { AppPage } from 'view/components/common/AppPage';

export const StudentHelpPage: React.FC = () => {
  return (
    <AppPage>
      <h1>Help</h1>
    </AppPage>
  );
};
