import { useLoading } from 'contexts/LoadingContext';
import TeacherGradebook from 'models/TeacherGradebook';
import React, { useEffect, useState, ChangeEvent } from 'react';
import contentService from 'services/contentService';
import generatePdf from 'utils/generatePdf';
import ActionButton from 'view/components/buttons/ActionButton';

import 'view/style/teacher/components/analytics/generatePdfPopup.css';

type GeneratePdfPopupProps = {
  gradebook: TeacherGradebook;
};

type StudentPdfData = {
  selected: boolean;
  index: number;
  language: string;
};

const GeneratePdfPopup: React.FC<GeneratePdfPopupProps> = ({ gradebook }) => {
  const [toGenerateList, setToGenerateList] = useState<StudentPdfData[]>([]);
  const pageContent = contentService.getAssignmentSubmissionPage();
  const { setIsLoading } = useLoading();

  useEffect(() => {
    if (!gradebook) return;
    setToGenerateList(
      gradebook.students.map((_, idx) => {
        return {
          selected: true,
          index: idx,
          language: 'English',
        };
      }),
    );
  }, [gradebook]);

  async function handleGenerateReportPdf() {
    if (!gradebook) return;
    try {
      setIsLoading(true);

      // Create an array of promises
      const promises = toGenerateList
        .filter((entry) => entry.selected)
        .map((entry) => generatePdf(gradebook, entry.index, entry.language));

      // Wait for all promises to complete
      await Promise.all(promises);

      setIsLoading(false);
    } catch (error) {
      alert(
        'Oops, something went wrong generating the progress report! Please try again or report the issue to support@oratorlearning.us',
      );
      setIsLoading(false);
    }
  }

  const handleActiveChange = (event: ChangeEvent<HTMLInputElement>) => {
    const idx = Number(event.target.id.split('-')[0]);
    const entry = toGenerateList[idx];
    setToGenerateList([
      ...toGenerateList.slice(0, idx),
      { ...entry, selected: event.target.checked },
      ...toGenerateList.slice(idx + 1),
    ]);
  };

  const handleLanguageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const idx = Number(event.target.id.split('-')[0]);
    const entry = toGenerateList[idx];
    setToGenerateList([
      ...toGenerateList.slice(0, idx),
      { ...entry, language: event.target.value },
      ...toGenerateList.slice(idx + 1),
    ]);
  };

  return (
    <div className="form">
      <h2>Progress report settings</h2>
      <table className="progress-report-table">
        <thead>
          <tr>
            <th>Generate</th>
            <th>Student</th>
            <th>Language</th>
          </tr>
        </thead>
        <tbody>
          {toGenerateList.map((entry, idx) => {
            const student = gradebook.students[entry.index];
            return (
              <tr key={entry.index}>
                <td>
                  <input
                    id={`${idx}-selected`}
                    type="checkbox"
                    checked={entry.selected}
                    onChange={handleActiveChange}
                  />
                </td>
                <td>{student.first_name + ' ' + student.last_name}</td>
                <td>
                  <select
                    id={`${idx}-language`}
                    value={entry.language}
                    onChange={handleLanguageChange}
                  >
                    {pageContent.sidebar.language.options.map(
                      (langOption: Record<string, string>) => (
                        <option
                          key={`lang-${langOption.name}`}
                          value={langOption.name}
                        >
                          {langOption.name}
                        </option>
                      ),
                    )}
                  </select>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ActionButton
        type="go"
        label="Generate reports"
        onClick={handleGenerateReportPdf}
      />
    </div>
  );
};

export default GeneratePdfPopup;
