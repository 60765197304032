import React, { useEffect } from 'react';
import { UserInput } from 'view/components/common/UserInput';
import PasswordInput from 'view/components/common/PasswordInput';
import { useFormValidation } from 'contexts/FormValidationContext';
import { useFormContext } from 'contexts/FormContext';
import { SignupFormData } from 'utils/types';

const ProfileInformationForm: React.FC = () => {
  const { formData, setFormData } = useFormContext<SignupFormData>();
  const { setPageValidity } = useFormValidation();

  useEffect(() => {
    setPageValidity(
      formData.first_name !== '' &&
        formData.last_name !== '' &&
        formData.email !== '' &&
        formData.username !== '' &&
        formData.password.length >= 8,
    );
  }, [formData, setPageValidity]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  return (
    <div className="login-content">
      <div className="row">
        <UserInput
          id="first_name"
          label="First Name"
          type="text"
          text={formData.account_type === 'Student' ? 'first name' : undefined}
          value={formData.first_name}
          onChange={handleChange}
        />
        <UserInput
          id="last_name"
          label="Last Name"
          type="text"
          text={formData.account_type === 'Student' ? 'last name' : undefined}
          value={formData.last_name}
          onChange={handleChange}
        />
      </div>
      <UserInput
        id="email"
        label="Email Address"
        type="text"
        text={formData.account_type === 'Student' ? 'email address' : undefined}
        value={formData.email}
        onChange={handleChange}
      />
      <UserInput
        id="username"
        label="Username"
        type="text"
        text={formData.account_type === 'Student' ? 'username' : undefined}
        value={formData.username}
        onChange={handleChange}
      />
      <PasswordInput
        password={formData.password}
        setPassword={(password) =>
          setFormData({ ...formData, password: password })
        }
        label="Password"
        text={formData.account_type === 'Student' ? 'password' : undefined}
        valid={formData.password.length >= 8}
      />
    </div>
  );
};

export default ProfileInformationForm;
