import { Slider } from '@mui/material';
import { useUserPreferences } from 'contexts/UserPreferencesContext';
import React from 'react';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';
import Button from 'view/components/buttons/Button';
import ReadingFrame from 'view/components/common/ReadingFrame';

import 'view/style/student/components/assignment/assignmentSettings.css';

const restart: string = require('assets/icons/restart_alt.svg').default;

const AssignmentSettings: React.FC = () => {
  const { fontScale, setFontScale, lineSpacing, setLineSpacing } =
    useUserPreferences();

  const handleFontScaleChange = (event: Event, value: number | number[]) => {
    setFontScale((value as number) / 100);
  };

  const handleLineSpacingChange = (event: Event, value: number | number[]) => {
    setLineSpacing((value as number) / 100);
  };

  const handleRevertUserPreferences = () => {
    setFontScale(1);
    setLineSpacing(1);
  };

  return (
    <div className="assignment-settings">
      <div className="assignment-settings-tools">
        <h3>Assignment Settings</h3>
        <div className="assignment-settings-item">
          <div className="row">
            <img src="" alt="" />
            <label>Font Size</label>
            <AudioAssistanceButton text="font size" />
          </div>
          <div className="row">
            <span className="label-normal">{fontScale * 100}%</span>
            <Slider
              name="fontScale"
              getAriaLabel={() => 'font size'}
              min={50}
              max={250}
              value={fontScale * 100}
              onChange={handleFontScaleChange}
              valueLabelDisplay="auto"
              className="slider"
            />
          </div>
        </div>
        <div className="assignment-settings-item">
          <div className="row">
            <img src="" alt="" />
            <label>Line Spacing</label>
            <AudioAssistanceButton text="line spacing" />
          </div>
          <div className="row">
            <span className="label-normal">{lineSpacing * 100}%</span>
            <Slider
              name="lineSpacing"
              getAriaLabel={() => 'line spacing'}
              min={50}
              max={150}
              value={lineSpacing * 100}
              onChange={handleLineSpacingChange}
              valueLabelDisplay="auto"
              className="slider"
            />
          </div>
        </div>
        <div className="assignment-settings-tools-footer">
          <Button
            leadIcon={restart}
            label="Revert to defaults"
            text="revert to defaults"
            onClick={handleRevertUserPreferences}
          />
        </div>
      </div>
      <div className="assignment-settings-preview">
        <div className="assignment-settings-preview-header">Preview Text</div>
        <div className="assignment-settings-preview-content">
          <ReadingFrame>
            <p>
              Ex Lorem quis commodo cupidatat ut. Nulla aliqua fugiat enim
              eiusmod id ad laboris commodo aliqua ipsum magna enim do.
            </p>
          </ReadingFrame>
        </div>
      </div>
    </div>
  );
};

export default AssignmentSettings;
