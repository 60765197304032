export default class QuestionResponse {
  private readonly id;
  public assignment_submission: string;
  public question: string;
  public response: string;
  public grade: number;
  public completed: boolean;
  public choice: string;

  constructor(
    id: string,
    assignment_submission: string,
    question: string,
    response: string,
    grade: number,
    completed: boolean,
    choice: string,
  ) {
    this.id = id;
    this.assignment_submission = assignment_submission;
    this.question = question;
    this.response = response;
    this.grade = grade;
    this.completed = completed;
    this.choice = choice;
  }

  static fromQuestionResponse(questionResponse: QuestionResponse) {
    return new QuestionResponse(
      questionResponse.getId(),
      questionResponse.assignment_submission,
      questionResponse.question,
      questionResponse.response,
      questionResponse.grade,
      questionResponse.completed,
      questionResponse.choice,
    );
  }

  static fromServerQuestionResponse(
    questionResponse: Record<string, any>,
  ): QuestionResponse {
    return new QuestionResponse(
      questionResponse['id'],
      questionResponse['assignment_submission'],
      questionResponse['question'],
      questionResponse['response'],
      questionResponse['grade'],
      questionResponse['completed'],
      questionResponse['choice'],
    );
  }

  public getId(): string {
    return this.id;
  }

  public toJSON(): Record<string, any> {
    return { ...this };
  }
}
